import React from 'react';
import { SortItem } from 'src/middleware/eye-exams/types';
import { SortOptions } from '../sort-options';
import { SortMenuContainer } from '../styles';
import { FiltersSection } from '../../appointmets-filter/filter-modal/styles';

interface SortModalProps {
  setExpandedSortMenu: (value: boolean) => void;
  filtersSectionRef: React.RefObject<HTMLDivElement>;
  onSortChange: (item: SortItem) => void;
  sortItem: SortItem;
}

const SortModal: React.FC<SortModalProps> = ({
  sortItem,
  setExpandedSortMenu,
  filtersSectionRef,
  onSortChange,
}) => (
  <FiltersSection>
    <SortMenuContainer ref={filtersSectionRef}>
      <SortOptions
        onSortChange={onSortChange}
        sortItem={sortItem}
        setExpandedSortMenu={setExpandedSortMenu}
      />
    </SortMenuContainer>
  </FiltersSection>
);

export default SortModal;
