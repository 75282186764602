import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Paper } from '@warbyparker/retail-design-system';
import { colors } from '../../styles/colors';
// @ts-ignore
import exclamationIcon from '../icon-exclamation.svg';
import { resolutions } from '../../styles/resolutions';
import { defaultFrameImg, facetGroups } from './constants';
import { Frame, WidthName } from '../../service-clients/warby-api-types';

const CardWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  
  width: 336px;
  height: 358px;
  margin-bottom: 22px;
  
  @media (max-width: ${resolutions.tabletMax}) {
    width: 288px;
    height: 307px;
    margin-bottom: 16px;
  }
  @media (max-width: ${resolutions.tabletSMax}) {
    width: 240px;
    height: 256px;
    margin-bottom: 10px;
  }
`;

const FrameImage = styled.img`
  width: 276px;
  margin: 0 30px;
  
  @media (max-width: ${resolutions.tabletMax}) {
     width: 240px;
     margin: 0 24px;
  }
  
  @media (max-width: ${resolutions.tabletSMax}) {
    width: 192px;
    margin: 0 24px;
  }
`;

const FrameFamilyName = styled.h2`
  color: ${colors.tundora};
  font-family: utopia-std;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  
  font-size: 28px;
  line-height: 30px;
  margin: 6px 0 0 0;

  @media (max-width: ${resolutions.tabletMax}) {
    font-size: 24px;
    line-height: 30px;
    margin: 4px 0 0 0;
  }
  
  @media (max-width: ${resolutions.tabletSMax}) {
    font-size: 20px;
    line-height: 24px;
    margin: 2px 0 0 0;
  }
`;

const FrameBridge = styled.div<{ visible: boolean }>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  color: ${colors.gray};
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.155em;
  text-transform: uppercase;
  
  font-size: 16px;
  line-height: 24px;
  margin: 6px 0 0 0;

  @media (max-width: ${resolutions.tabletMax}) {
    font-size: 12px;
    line-height: 24px;
    margin: 4px 0 0 0;
  }
  
  @media (max-width: ${resolutions.tabletSMax}) {
    font-size: 8px;
    line-height: 24px;
    margin: 2px 0 0 0;
  }
`;

const FrameColorTitle = styled.div`
  color: ${colors.gray};
  font-family: utopia-std;
  font-style: italic;
  font-weight: normal;  
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  
  padding: 0 10px;
  
  font-size: 18px;
  line-height: 24px;

  @media (max-width: ${resolutions.tabletMax}) {
    font-size: 16px;
    line-height: 24px;
  }
  
  @media (max-width: ${resolutions.tabletSMax}) {
    font-size: 12px;
    line-height: 24px;
  }
`;

const FrameColorName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
`;

const FrameColorCode = styled.div`
  margin-right: 10px;
`;

const FrameColorTitleDash = styled.div`
  padding: 0px 4px;
  @media (max-width: ${resolutions.tabletSMax}) {
    padding: 0px 3px;
  }
`;

const FrameColorsFlexContainer = styled.div`
  margin: 17px 0 24px 0;
  display: flex;
  
  @media (max-width: ${resolutions.tabletSMax}) {
    margin: 12px 0 20px 0;
  }
  
  @media (max-width: ${resolutions.tabletSMax}) {
    margin: 12px 0 10px 0;
  }
`;

const FrameColorsScrollContainer = styled.div`
  max-width: 90%;
  overflow: auto;
`;

const ColorCircle = styled.img<{ src: string, selected: boolean }>`
  cursor: pointer;
  transition: border-color .1s;
  outline: none;
  border-radius: 50%;
  
  width: 30px;
  height: 30px;
  margin: 10px;
  box-shadow: ${props => (props.selected ? (`0 0 0 3px ${colors.white}, 0 0 0 5px ${colors.charcoal}`) : 'none')};
  transition: box-shadow .2s ease, -webkit-box-shadow .2s ease;
  
  ${props => (!props.selected
    && `&:hover {
      box-shadow: 0 0 0 3px ${colors.white}, 0 0 0 5px ${colors.grayChateau};
      transition: box-shadow .2s ease, -webkit-box-shadow .2s ease;
    }`
  )}

  @media (max-width: ${resolutions.tabletMax}) {
    width: 24px;
    height: 24px;
    margin: 8px;
  }
  
  @media (max-width: ${resolutions.tabletSMax}) {
    width: 18px;
    height: 18px;
    margin: 5px;
    box-shadow: ${props => (props.selected ? (`0 0 0 2px ${colors.white}, 0 0 0 3px ${colors.charcoal}`) : 'none')};
    
  ${props => (!props.selected
    && `&:hover {
      box-shadow: 0 0 0 2px ${colors.white}, 0 0 0 3px ${colors.grayChateau};
      transition: box-shadow .2s ease, -webkit-box-shadow .2s ease;
    }`
  )}
  }
`;

const FrameWidth = styled.div`
  display: flex;
  align-items: center;
  
  margin: 0 0 2px 0;
      
  @media (max-width: ${resolutions.tabletMax}) {
    margin: 0 0 2px 0;
  }
  
  @media (max-width: ${resolutions.tabletSMax}) {
    margin: 0 0 2px 0;
  }
`;

const WidthVariant = styled.div<{ available: boolean }>`
  background-color: ${props => (props.available ? colors.allports : 'none')};
  color: ${props => (props.available ? colors.white : colors.silverChalice)};
  
  margin-right: 1px;
  text-align: center;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 600;
    
  font-size: 18px;
  line-height: 24px;
  width: 66px;
  height: 28px;
  padding: 5px 0 0;
    
  @media (max-width: ${resolutions.tabletMax}) {
    font-size: 12px;
    line-height: 24px;
    width: 56px;
    height: 24px;
    padding: 4px 0 3px;
  }
  
  @media (max-width: ${resolutions.tabletSMax}) {
    font-size: 10px;
    line-height: 24px;
    width: 47px;
    height: 23px;
    padding: 3px 0 5px;
  }
`;

interface FrameItemProps {
  frameFamily: Frame[];
}

export const FrameItem: React.FC<FrameItemProps> = ({ frameFamily }) => {
  const { search } = useLocation();
  const [selectedFrame, setSelectedFrame] = useState(frameFamily[0]);

  const frameImageSrc = Object.prototype.hasOwnProperty.call(selectedFrame.images, 'front')
    ? selectedFrame.images.front
    : defaultFrameImg;
  const isLowBridge = selectedFrame.bridgeFit === 'lowBridge';
  const availableWidths = selectedFrame.widthVariants.map(widthVariant => widthVariant.width);
  const notAvailable = !selectedFrame.widthVariants.length;

  return (
    <CardWrapper>
      {notAvailable
        ? <FrameImage src={`${frameImageSrc}?quality=80&width=276`} alt="Frame" />
        : (
          <Link to={`/frame-config/${(selectedFrame.storeFrameId || '')}${search}`}>
            {/* use 'quality' and 'width' query string parameters to fetch lighter images */}
            {/* 276px is the width of the biggest image wrapper for wide screen */}
            <FrameImage src={`${frameImageSrc}?quality=80&width=276`} alt="Frame" />
          </Link>
        )}
      <FrameBridge visible={isLowBridge}>Low bridge fit</FrameBridge>
      <FrameFamilyName>
        {selectedFrame.name}
      </FrameFamilyName>
      <FrameColorTitle>
        <FrameColorName>{selectedFrame.color}</FrameColorName>
        <FrameColorTitleDash>-</FrameColorTitleDash>
        <FrameColorCode>{selectedFrame.colorCode}</FrameColorCode>
      </FrameColorTitle>
      <FrameColorsScrollContainer>
        <FrameColorsFlexContainer>
          {
            frameFamily.map((frameItem) => (
              // for 'selected' prop compare by 'id' to fix the bug
              // missing initial ColorCircle selection styles
              // when switching to photobooth and back
              <ColorCircle
                key={frameItem.id}
                src={frameItem.images.swatch || exclamationIcon}
                onClick={() => setSelectedFrame(frameItem)}
                selected={selectedFrame.id === frameItem.id}
              />
            ))
          }
          {/* add gap not to cut the last circle when it is selected in case of scrolling */}
          <div>&nbsp;</div>
        </FrameColorsFlexContainer>
      </FrameColorsScrollContainer>
      <FrameWidth>
        {
          notAvailable
            ? <FrameBridge visible>Not available</FrameBridge>
            : facetGroups.availableWidths.facets.map((widthVariant) => (
              <WidthVariant
                key={widthVariant.name}
                available={availableWidths.indexOf(widthVariant.name as WidthName) !== -1}
              >
                {widthVariant.abbreviation}
              </WidthVariant>
            ))
        }
      </FrameWidth>
    </CardWrapper>
  );
};
