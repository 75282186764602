import { useContext } from 'react';
import { SnowplowContext } from '../providers/snowplow';

const useSnowplowContext = () => {
  const { isReady, eventTracker } = useContext(SnowplowContext);

  return { isReady, eventTracker };
};

export default useSnowplowContext;
