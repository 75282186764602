import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../styles/colors';

const StyledHeader = styled('div')`
  align-items: center;
  background: white;
  display: flex;
  height: 78px;
  justify-content: space-between;
  left: 0;
  padding: 0 30px;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 3;
`;

const WidthSetter = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 768px;
  width: 768px;
`;

export const Heading = styled.h1`
  font-family: utopia-std;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.charcoal};
  text-align: center;
`;

export const Empty = styled.div`
  height: 26px;
  width: 30px;
`;

const Actions = styled.div`
  width: 140px;
  display: flex;
  justify-content: center;
  gap: 12px;
`;

const Title = styled.div`
  flex-grow: 1;
`;

interface HeaderProps {
  children: React.ReactNode;
  actionsLeft?: React.ReactNode[];
  actionsRight?: React.ReactNode[];
}

export const HeaderIcon = styled.button<{ active?: boolean, to?: string }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  color: ${colors.blue};
  background: none;
  font-size: 24px;
  border: none;
  padding: 6px;
`;

export const HeaderIconLink = HeaderIcon.withComponent('a');

export const Header = ({ children, actionsLeft = [], actionsRight = [] }: HeaderProps) => (
  <StyledHeader>
    <WidthSetter>
      <Actions>
        {actionsLeft}
      </Actions>
      <Title>
        {children}
      </Title>
      <Actions>
        {actionsRight}
      </Actions>
    </WidthSetter>
  </StyledHeader>
);
