import styled from '@emotion/styled';

export const FiltersSection = styled.div`
  font-family: proxima-nova, sans-serif;
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  position: fixed;
  margin-top: 124px;
  left: 0;
  right: 0;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  z-index: 3;
`;
