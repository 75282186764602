import React from 'react';
import StyledBadge, { BadgeProps } from './styles';

const Badge: React.FC<BadgeProps> = (props) => {
  const { variant, children } = props;
  return (
    <StyledBadge variant={variant}>{children}</StyledBadge>
  );
};

export default Badge;
