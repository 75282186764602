import React from 'react';
import styled from '@emotion/styled';
import { CameraIcon, FilterIcon, FavoriteIcon, ArrowLeft24Icon } from '@warbyparker/retail-design-system';

import queryString from 'query-string';
import { Header, HeaderIcon } from '../../shared-components/headers';
import { colors } from '../../styles/colors';
import { View } from './constants';

const Heading = styled.h1`
  font-family: utopia-std;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.charcoal};
  text-align: center;
  text-transform: capitalize;
`;

interface FrameStyleAssistantHeaderProps {
  view: View;
  toggleView: Function;
  kind: string;
}

const Icon = styled(HeaderIcon)<{ active: boolean }>`
  border: none;
  border-bottom: ${props => (props.active ? '2px solid' : '2px solid transparent')};
`;

export const FrameStyleAssistantHeader : React.FC<FrameStyleAssistantHeaderProps> = ({
  toggleView,
  view,
  kind,
}) => {
  const parsedQuery = queryString.parse(window.location.search);
  const visitId = parsedQuery.visit;
  const estimateId = parsedQuery.estimate;

  // redirect back to POE:
  // - checkout page if there are visit and estimate ids in query params
  // - main page if there are no visit and estimate ids in query params
  const backUrl = visitId && estimateId
    ? String(`https://${process.env.POE_DOMAIN}/new-visit/${visitId}/estimate/${estimateId}`)
    : `https://${process.env.POE_DOMAIN}`;

  return (
    <Header
      actionsLeft={[
        <HeaderIcon key="back" as="a" href={backUrl}><ArrowLeft24Icon /></HeaderIcon>,
      ]}
      actionsRight={[
        <Icon
          key="filter"
          aria-label="View style assistant"
          active={view === View.StyleAssistant}
          onClick={() => toggleView(View.StyleAssistant)}
        >
          <FilterIcon />
        </Icon>,
        <Icon
          key="photobooth"
          aria-label="View photobooth"
          active={view === View.Photobooth}
          onClick={() => toggleView(View.Photobooth)}
        >
          <CameraIcon />
        </Icon>,
        <Icon
          key="favorites"
          aria-label="View favorites"
          active={view === View.Favorites}
          onClick={() => toggleView(View.Favorites)}
        >
          <FavoriteIcon />
        </Icon>,
      ]}
    >
      <Heading>
        {(view === View.StyleAssistant) ? kind : view}
      </Heading>
    </Header>
  );
};
