import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { FETCH_WAITLIST_CUSTOMER_ACCOUNT } from 'src/middleware/waitlist/index';

import { Button } from '@warbyparker/retail-design-system';
import CustomerSearch from 'src/shared-components/customer-search';
import AnimatedLoader from './animated-loader';

const CustomerContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const CustomerEmail = styled('p')`
  font-size: 18px;
  font-weight: 600;
`;

const customerAccountSelector = ({
  customerAccount,
  customerAccountError,
  customerId,
  searchCustomer,
  selectCustomer,
  removeCustomer,
  dispatch,
  disabled,
}) => {
  useEffect(() => {
    if (customerId && (customerId !== customerAccount.id)) {
      dispatch({
        type: FETCH_WAITLIST_CUSTOMER_ACCOUNT,
        customer_id: customerId,
      });
    }
  }, [customerId]);

  // If there is an error fetching an account, display it
  if (customerAccountError) {
    return (
      <CustomerContainer>
        <CustomerEmail>
          {customerAccountError}
        </CustomerEmail>
      </CustomerContainer>
    );
  }

  // If customerId is not provided, show customer search
  if (!customerId) {
    return (
      <>
        {
          !disabled && (
            <CustomerSearch
              onAdd={selectCustomer}
              onQuery={searchCustomer}
              allowAddCustomer={false}
              disabled={disabled}
            />
          )
        }
      </>
    );
  }

  // If the loaded account info does not match the provided
  // customerId, we are waiting for the correct account to load
  if (customerId !== customerAccount.id) {
    return (
      <CustomerContainer>
        <CustomerEmail>
          <AnimatedLoader />
        </CustomerEmail>
      </CustomerContainer>
    );
  }

  // We have a customerId and have loaded the matching
  // customer account -- show its email address
  return (
    <CustomerContainer>
      <CustomerEmail>
        {customerAccount.email}
      </CustomerEmail>
      {
        !disabled && (
          <Button onClick={removeCustomer} color="secondary">
            Remove
          </Button>
        )
      }
    </CustomerContainer>
  );
};

const select = (state) => ({
  customerAccount: state.waitlist.customerAccount,
  customerAccountError: state.waitlist.error.customer_account,
});

export default connect(select)(customerAccountSelector);
