/* eslint-disable no-undef */
import { takeLatest, put, select, delay } from 'redux-saga/effects';

import RetailService from 'src/service-clients/helios-retail';

export const CUSTOMER_SEARCH_REQUEST = 'CUSTOMER_SEARCH_REQUEST';
const CUSTOMER_SEARCH_RESULTS = 'CUSTOMER_SEARCH_RESULTS';

export const dispatchSearchCustomer = query => ({
  type: CUSTOMER_SEARCH_REQUEST,
  payload: query,
});

function* searchCustomers({ payload: query }) {
  if (!query || query.length < 3) {
    yield put({ type: CUSTOMER_SEARCH_RESULTS, payload: null });
    return;
  }
  yield delay(500); // debounce by 500ms
  const token = yield select(state => state.auth.token);
  let customers;

  try {
    customers = yield RetailService.getCustomers({ token, query });
  } catch (err) {
    logger.error({ err }, 'Customer search error');
    // TODO: pipe the error back out to the UI
    return;
  }
  yield put({ type: CUSTOMER_SEARCH_RESULTS, payload: customers });
}

const init = {
  results: null,
  query: null,
  loading: false,
};

const reducer = (state = init, { type, payload }) => {
  switch (type) {
    case CUSTOMER_SEARCH_REQUEST:
      return { ...state, loading: true, query: payload };
    case CUSTOMER_SEARCH_RESULTS:
      return { ...state, loading: false, results: payload };
    default:
      return state;
  }
};

function* saga() {
  yield takeLatest(CUSTOMER_SEARCH_REQUEST, searchCustomers);
}

export default { reducer, saga };
