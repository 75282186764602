// Keep it as a multiple of 3 for iPad screen
export const PAGE_SIZE = 12;

export const RECOMMENDATIONS_LIMIT = 6;

export const facetGroups = {
  kind: {
    displayName: 'Type',
    facets: [{
      displayName: 'Eyeglasses',
      name: 'eyeGlasses',
    }, {
      displayName: 'Sunglasses',
      name: 'sunGlasses',
    }],
  },
  bridgeFit: {
    displayName: 'Nose bridge',
    facets: [{
      displayName: 'Standard',
      name: 'standardBridge',
    }, {
      displayName: 'Low bridge fit',
      name: 'lowBridge',
    }],
  },
  availableWidths: {
    displayName: 'Frame width',
    allowMultiple: true,
    facets: [{
      displayName: 'Extra narrow',
      name: 'extra-narrow',
      abbreviation: 'XN',
    }, {
      displayName: 'Narrow',
      name: 'narrow',
      abbreviation: 'N',
    }, {
      displayName: 'Medium',
      name: 'medium',
      abbreviation: 'M',
    }, {
      displayName: 'Wide',
      name: 'wide',
      abbreviation: 'W',
    }, {
      displayName: 'Extra wide',
      name: 'extra-wide',
      abbreviation: 'XW',
    }],
  },
  shapes: {
    displayName: 'Shape',
    allowMultiple: true,
    facets: [{
      displayName: 'Rectangle',
      name: 'Rectangle',
    }, {
      displayName: 'Square',
      name: 'Square',
    }, {
      displayName: 'Round',
      name: 'Round',
    }, {
      displayName: 'Cat-eye',
      name: 'Cat-eye',
    }, {
      displayName: 'Aviator',
      name: 'Aviator',
    }],
  },
  colors: {
    displayName: 'Color',
    allowMultiple: true,
    facets: [{
      displayName: 'Amber',
      name: 'amber',
      swatch: 'https://i.warbycdn.com/s/f/456183af477f913bc0bbba6f678c7f2472251efd',
    }, {
      displayName: 'Black',
      name: 'black',
      swatch: 'https://i.warbycdn.com/s/f/3d0b43f1ce6a8a1379ad329ad6d7a94d88678b83',
    }, {
      displayName: 'Blue',
      name: 'blue',
      swatch: 'https://i.warbycdn.com/s/f/264376add8b4c9aecfebc21ff1196b3886f0e025',
    }, {
      displayName: 'Brown',
      name: 'brown',
      swatch: 'https://i.warbycdn.com/-/f/color-sugar-maple-3ce5e1e6',
    }, {
      displayName: 'Crystal',
      name: 'crystal',
      swatch: 'https://i.warbycdn.com/s/f/0ac9121ee9584067f02788f1ffc8f097f0429ec9',
    }, {
      displayName: 'Gold',
      name: 'gold',
      swatch: 'https://i.warbycdn.com/s/f/9b1515a9ecbc187e987cbe0920502db4b23a22b1',
    }, {
      displayName: 'Green',
      name: 'green',
      swatch: 'https://i.warbycdn.com/s/f/c844aecf17b18772a96638f2ffae062b1c08ce93',
    }, {
      displayName: 'Grey',
      name: 'grey',
      swatch: 'https://i.warbycdn.com/s/f/40e399473910489d90f23a833fbe9a04e6e8702d',
    }, {
      displayName: 'Pink',
      name: 'pink',
      swatch: 'https://i.warbycdn.com/s/f/ae2316b15589fa00c5555623243914e96ea50044',
    }, {
      displayName: 'Purple',
      name: 'purple',
      swatch: 'https://i.warbycdn.com/s/f/274b124ee7f99731c1ebd37aaba6662af190cf6d',
    }, {
      displayName: 'Red',
      name: 'red',
      swatch: 'https://i.warbycdn.com/s/f/ae03404635ff6f6b3720ebd8754940f6983f495e',
    }, {
      displayName: 'Silver',
      name: 'silver',
      swatch: 'https://i.warbycdn.com/s/f/7f91bd475f94b20bac5bdc9c41499dfb415a8c3f',
    }, {
      displayName: 'Light Tortoise',
      name: 'light-tortoise',
      swatch: 'https://i.warbycdn.com/s/f/5dee88af4dde74d82e6c592eeca77a15e39b6183',
    }, {
      displayName: 'Tortoise',
      name: 'tortoise',
      swatch: 'https://i.warbycdn.com/s/f/61a5c6fe394b0537933ec7cc59573aac2cfe6a7f',
    }, {
      displayName: 'White',
      name: 'white',
      swatch: 'https://i.warbycdn.com/s/f/67911f113a4e361d936338720446db42f3be51ef',
    }, {
      displayName: 'Bright',
      name: 'bright',
      swatch: 'https://i.warbycdn.com/s/f/36c1bcc915dc3350d75657c332ae30396f65e4a9',
    }, {
      displayName: 'Neutral',
      name: 'neutral',
      swatch: 'https://i.warbycdn.com/s/f/da1920ac1b96a369c394da8c9c14635e7f5c0deb',
    }, {
      displayName: 'Two-tone',
      name: 'two-tone',
      swatch: 'https://i.warbycdn.com/s/f/15f06a738682b45620930b1104816d6aacae368c',
    }],
  },
  materials: {
    displayName: 'Material',
    allowMultiple: true,
    facets: [{
      displayName: 'Acetate',
      name: 'Cellulose Acetate',
    }, { // this don't match WWW
      displayName: 'Stainless Steel',
      name: 'Stainless Steel',
    }, { // this doesn't match WWW
      displayName: 'Titanium',
      name: 'Titanium',
    }],
  },
};

export const defaultFrameImg = 'https://i.warbycdn.com/v/c/assets/poe-product-search/image/placeholder-optical/1/0a2edd241f.png';

export enum View {
  StyleAssistant = 'style assistant',
  Favorites = 'favorites',
  Photobooth = 'photobooth',
}

export enum FacetTypes {
  kind = 'kind',
  bridgeFit = 'bridgeFit',
  availableWidths = 'availableWidths',
}

export type Kind = 'eyeGlasses' | 'sunGlasses';

export type BridgeFit = 'standardBridge' | 'lowBridge';
