import { EyeExamState } from './types';
import { SortColumn, SortOption, SortOrder } from '../../constants';

export const initialState: EyeExamState = {
  appointments: {
    data: [],
    count: 0,
    total: 0,
    page: 1,
    pageCount: 1,
    sortItem: {
      column: SortColumn.StartAt,
      order: SortOrder.Asc,
      option: SortOption.Descending,
    },
  },
  facility: null,
  nextAvailables: [],
  loading: {
    appointments: false,
    isAppointmentUpdating: false,
    facility: false,
    noShowIds: [],
  },
  errors: {
    appointments: '',
    appointmentUpdateMessage: '',
    facility: '',
  },
};
