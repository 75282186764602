import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ArrowLeft16Icon,
  ArrowRight16Icon,
  CaretDown12Icon,
  FilterIcon,
  MenuIcon,
} from '@warbyparker/retail-design-system';
import { SortItem } from 'src/middleware/eye-exams/types';
import {
  ActiveFiltersBubble,
  ButtonGroup,
  Container,
  DateDisplay,
  FilterButton,
  IconButton,
  IconGroup,
  RoundIconButton,
  SortButton,
  TodayButton,
  TopRow,
} from './styles';
import DatePicker from './components/date-picker';
import { formatDateToDayAndMonth } from '../../utils/formatDate';
import useDateToolbarState from '../../hooks/useDateToolbarState';
import FilterModal, { TimeFilterDefaultOption } from '../appointments/appointmets-filter/filter-modal';
import { activeFiltersCount } from '../../utils/customerList';
import SortModal from '../appointments/appointments-sort-menu/sort-modal';

interface DataViewToolbarProps {
  onFilterClick?: () => void;
  onSortClick?: () => void;
  onSortChange: (item: SortItem) => void;
  sortItem: SortItem;
}

const DataViewToolbar: React.FC<DataViewToolbarProps> = ({
  onFilterClick = () => { },
  onSortClick = () => { },
  onSortChange,
  sortItem,
}) => {
  const [expandedFilters, setExpandedFilters] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [expandedSortMenu, setExpandedSortMenu] = useState(false);

  const {
    currentDate,
    onDateChanged,
    onToday,
    onPreviousDay,
    onNextDay,
    onApplyDateFilter,
  } = useDateToolbarState();

  const filtersSectionRef = useRef<HTMLDivElement>(null);
  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const sortButtonRef = useRef<HTMLButtonElement>(null);

  const handleOutsideClick = (event) => {
    const isClickOutsideFilters = filtersSectionRef.current
      && !filtersSectionRef.current.contains(event.target);
    const isClickOutsideFiltersButton = filtersButtonRef.current
      && !filtersButtonRef.current.contains(event.target);
    const isClickOutsideSortButton = sortButtonRef.current
      && !sortButtonRef.current.contains(event.target);

    if (isClickOutsideFilters && isClickOutsideFiltersButton) {
      setExpandedFilters(false);
    }
    if (isClickOutsideFilters && isClickOutsideSortButton) {
      setExpandedSortMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const activeFiltersNumber = useMemo(() =>
    activeFiltersCount(selectedOptions, [TimeFilterDefaultOption]), [selectedOptions]);

  const handleFilterButtonClick = () => {
    setExpandedFilters((prevSelected) => !prevSelected);
    onFilterClick?.();
  };

  const handleSelectedOptions = (title: string, options: string[]) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [title]: options,
    }));
  };

  const handleSortButtonClick = () => {
    setExpandedSortMenu((prevSelected) => !prevSelected);
    onSortClick?.();
  };

  return (
    <Container>
      <TopRow>
        <TodayButton onClick={onToday}>Today</TodayButton>
        <DatePicker
          currentDate={currentDate}
          onDateChanged={onDateChanged}
          applyDateFilter={onApplyDateFilter}
        >
          <DateDisplay>
            {formatDateToDayAndMonth(currentDate)}
            <RoundIconButton>
              <CaretDown12Icon />
            </RoundIconButton>
          </DateDisplay>
        </DatePicker>
        <IconGroup>
          <IconButton onClick={onPreviousDay}>
            <ArrowLeft16Icon />
          </IconButton>
          <IconButton
            data-testid="data-view-toolbar-next-day-button"
            onClick={onNextDay}
          >
            <ArrowRight16Icon />
          </IconButton>
        </IconGroup>
      </TopRow>
      <ButtonGroup>
        <FilterButton
          onClick={handleFilterButtonClick}
          expandedFilters={expandedFilters}
          ref={filtersButtonRef}
        >
          <FilterIcon />
          Filter
          {activeFiltersNumber > 0
            && (
              <ActiveFiltersBubble>
                {activeFiltersNumber}
              </ActiveFiltersBubble>
            )}
        </FilterButton>
        <SortButton
          onClick={handleSortButtonClick}
          expandedSortMenu={expandedSortMenu}
          ref={sortButtonRef}
        >
          <MenuIcon />
          Sort
        </SortButton>
      </ButtonGroup>
      {
        expandedFilters && (
          <FilterModal
            selectedOptions={selectedOptions}
            handleSelectedOptions={handleSelectedOptions}
            filtersSectionRef={filtersSectionRef}
          />
        )
      }
      {
        expandedSortMenu && (
          <SortModal
            filtersSectionRef={filtersSectionRef}
            onSortChange={onSortChange}
            sortItem={sortItem}
            setExpandedSortMenu={setExpandedSortMenu}
          />
        )
      }
    </Container>
  );
};

export default DataViewToolbar;
