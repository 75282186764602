import React from 'react';
import { ErrorContainer, ErrorIcon, ErrorText } from './styles';

interface ErrorBlockProps {
  error: string;
}

const ErrorBlock = ({ error }: ErrorBlockProps) => (
  <ErrorContainer>
    <ErrorIcon />
    <ErrorText>{error}</ErrorText>
  </ErrorContainer>
);

export default ErrorBlock;
