import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

export const FiltersContainer = styled('div')`
  display: flex;
  position: fixed;
  flex-direction: column;
  row-gap: 20px;
  max-width: 308px;
  padding: 16px;
  border-radius: 12px;
  border: solid 1px ${colors.silver};
  box-shadow: 0px 8px 12px 0px ${colors.charcoalShadow}, 0px 1px 4px 0px ${colors.charcoalShadow};
  background-color: ${colors.white};
  z-index: 1000;
  margin-right: 51%;
`;
