import React, { createContext, useCallback, useContext, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { ToastContainer } from 'src/components/toast-container';

export interface ToastMessage {
  id: string
  type?: 'success' | 'error' | 'info'
  title: string
  description?: string
}

interface AddToastCallback {
  (toast: Omit<ToastMessage, 'id'>): void
}

interface RemoveToastCallback {
  (id: string): void
}

interface ToastContextData {
  addToast: AddToastCallback
  removeToast: RemoveToastCallback
}

export const TOAST_TIME_IN_MS = 5000;

const ToastContext = createContext({} as ToastContextData);

export const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback<AddToastCallback>(
    ({ title, description, type }) => {
      const id = Math.ceil(Math.random() * (10e10)).toString();

      const toast = {
        id,
        type,
        title,
        description,
      };

      setMessages(state => [...state, toast]);
    },
    [],
  );

  const removeToast = useCallback<RemoveToastCallback>(id => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextData => {
  const context = useContext(ToastContext);

  if (!context) throw new Error('useToast must be used within a ToastProvider');

  return context;
};
