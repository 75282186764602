import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import styled from '@emotion/styled';

import Dropdown from './dropdown';
import Result from './result';
import Search from './search';
import Searching from './components/searching';
import bluePlus from './bluePlus.svg';

const Container = styled('div')`
  position: relative;
  width: 100%;
`;

const Plus = styled('div')`
  display: block;
  background-size: 100%;
  width: 12px;
  height: 11px;

  &:hover {
    cursor: pointer;
  }
`;

const BluePlus = styled(Plus)`
  background-image: url(${bluePlus});
`;

const SuggestionsContainer = styled('div')`
  display: flex;
`;

const Suggestion = styled(Link)`
  padding: 20px 24px;
  margin-right: 10px;
  color: #414b56;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid #e4e6e8;
`;

const AddCustomerButton = styled(Link)`
  flex: 1;
  background: #00a2e1;
  font-size: 18px;
  font-weight: 600;
  color: white;
  padding: 25px;
  text-decoration: none;
  text-align: center;
  border-radius: 8px;
`;

class CustomerSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { query: null };
  }

  onClearQuery = () => {
    const { onQuery } = this.props;
    this.setState(() => ({ query: null }), () => onQuery());
  };

  onChange = (event) => {
    const { onQuery } = this.props;
    const query = event.target.value;

    if (!query) {
      return this.onClearQuery();
    }

    return this.setState(() => ({ query }), () => onQuery(query));
  };

  onCancel = () => {
    this.onClearQuery();
  };

  onKeyDown = (event) => {
    const {
      history,
      customerSearch: { results },
      match: { url },
    } = this.props;
    const { query } = this.state;
    const noResults = results && results.length < 1;
    const isNewEmail = noResults && query && query.includes('@');

    if (event.key === 'Enter' && isNewEmail) {
      history.push(`${url}/customer/${query}`);
    }

    if (event.key === 'Escape') {
      this.onCancel();
    }
  };

  getResults = () => {
    const {
      customerSearch: { results },
      onAdd,
    } = this.props;

    return results.map((result) => {
      const text = `${result.first_name || ''} ${result.last_name || ''}`;
      const info = result.email;

      return (
        <Result
          content={text}
          info={info}
          key={result.email}
          cta={<BluePlus />}
          onClick={() => onAdd(result)}
        />
      );
    });
  };

  getSuggestions = () => {
    const { query } = this.state;
    const {
      match: { url },
    } = this.props;
    const options = ['gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'me.com'];

    const suggestions = options.map(option => (
      <Suggestion
        key={option}
        to={`${url}/customer/${query}${option}`}
      >
        {`@${option}`}
      </Suggestion>
    ));

    return <SuggestionsContainer>{suggestions}</SuggestionsContainer>;
  };

  render() {
    const { query } = this.state;
    const {
      customerSearch: { loading, results },
      match: { url },
      allowAddCustomer = true,
      dispatch,
    } = this.props;
    const hasResults = results && results.length > 0;
    const regexEmail = new RegExp(/@[a-zA-Z0-9]{1,}\.[a-zA-Z0-9]{2,}/g);
    const isEmail = query && (regexEmail.test(query) === true);
    const isNewEmail = !hasResults && isEmail;
    const showNoResultsDropdown = query && !hasResults && !isEmail && !loading;
    const showResultsDropdown = query && hasResults && !loading;
    const showSuggestionsDropdown = query && !hasResults && !loading
      && query.endsWith('@');
    const showAddCustomerDropdown = allowAddCustomer && isNewEmail && !loading && isEmail;
    const hasDropdown = showNoResultsDropdown
      || showResultsDropdown
      || showSuggestionsDropdown
      || showAddCustomerDropdown;

    return (
      <Container>
        <Search
          hasDropdown={hasDropdown}
          labelText={`Find ${allowAddCustomer ? 'or add ' : ''}customer by email or phone`}
          onClear={this.onClearQuery}
          onChange={this.onChange}
          onExit={this.onCancel}
          onKeyDown={this.onKeyDown}
          query={query}
          dispatch={dispatch}
        />
        <Searching loading={loading} />
        {hasDropdown && (
          <Dropdown>
            {showNoResultsDropdown && <Result content="No results." />}
            {showResultsDropdown && this.getResults()}
            {showSuggestionsDropdown && <Result content={this.getSuggestions()} />}
            {showAddCustomerDropdown && (
              <Result
                content={(
                  <AddCustomerButton
                    to={`${url}/customer/${query}`}
                    data-browser-test-new-customer-link
                  >
                    Add new customer
                  </AddCustomerButton>
                )}
              />
            )}
          </Dropdown>
        )}
      </Container>
    );
  }
}

const select = state => ({
  customerSearch: state.customerSearch,
});

export default withRouter(connect(select)(CustomerSearch));
