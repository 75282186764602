import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EventTracker from '../service-clients/snowplow';
import { EYE_EXAM_TOOLING } from '../../feature-flags';

type SnowplowContextValue = {
  eventTracker: EventTracker | null;
  isReady: boolean;
};
export const SnowplowContext = createContext<SnowplowContextValue>({
  eventTracker: null,
  isReady: false,
});

interface SnowplowProviderProps {
  children: ReactNode;
  isEnabled: boolean;
}

const SnowplowProvider = ({ children, isEnabled }: SnowplowProviderProps) => {
  const [eventTracker, setEventTracker] = useState<EventTracker | null>(null);
  const [hasInitializedTracker, setHasInitializedTracker] = useState(false);

  useEffect(() => {
    if (!isEnabled || hasInitializedTracker) return;

    try {
      const tracker = new EventTracker();
      setEventTracker(tracker);
      setHasInitializedTracker(true);
    } catch (error) {
      const errorInfo = error instanceof Error
        ? {
          contexts: {
            error: {
              message: error.message,
              stack: error.stack,
            },
          },
        }
        : undefined;
      console.error(errorInfo);
    }
  }, [hasInitializedTracker, isEnabled]);

  const isReady: boolean = hasInitializedTracker;

  return (
    <SnowplowContext.Provider value={{ isReady, eventTracker }}>
      {children}
    </SnowplowContext.Provider>
  );
};

const mapToState = (state: { [key: string]: any }) => ({
  isEnabled: state.auth
      && state.auth.me
      && state.auth.me.features
      && state.auth.me.features.includes(EYE_EXAM_TOOLING),
});

export const ConnectedSnowplowProvider = connect(mapToState)(SnowplowProvider);
