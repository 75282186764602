import React from 'react';
import styled from '@emotion/styled';

// eslint-disable-next-line import/no-cycle
import { ToastMessage } from 'src/contexts/toast';

// eslint-disable-next-line import/no-cycle
import { Toast } from './toast';

interface ToastContainerProps {
  messages: ToastMessage[]
}

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding: 30px;
  overflow: hidden;
  z-index: 99999;
`;

export const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => (
  <Container>
    {messages.map(message => <Toast key={message.id} message={message} />)}
  </Container>
);
