import React from 'react';
import { Button } from '@warbyparker/retail-design-system';
import { QRCodeScanner } from '../../../scanner';
import { FooterActionsContainer } from './styles';

type FooterActionsSectionProps = {
  enableQRCodeScan: boolean;
  onAddCustomer: () => void;
  onBookEyeExam: () => void;
  onScanQRCode: () => void;
};

const FooterActionsSection: React.FC<FooterActionsSectionProps> = ({
  enableQRCodeScan,
  onAddCustomer,
  onBookEyeExam,
  onScanQRCode,
}) => (
  <FooterActionsContainer>
    <Button
      onClick={onAddCustomer}
      size="large"
    >
      Add visitor
    </Button>
    <Button
      onClick={onBookEyeExam}
      size="large"
    >
      Book eye exam
    </Button>
    {enableQRCodeScan ? (
      <QRCodeScanner
        onClick={onScanQRCode}
      />
    ) : null}
  </FooterActionsContainer>
);

export default FooterActionsSection;
