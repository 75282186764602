import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

export const StyledBackdrop = styled('div')`
  width: 100%;
  height: 100%;

  background: ${colors.charcoal}B1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledContainer = styled.span`
  * {
    border: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  position: absolute;
  top: 0;
  left: 0;
  z-index: 9000;

  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;
`;
