import { useState } from 'react';

interface UseDateToolbarStateReturnValue {
  currentDate: Date,
  onDateChanged: (newDate: Date) => void,
  onToday: () => void,
  onNextDay: () => void,
  onPreviousDay: () => void,
  onApplyDateFilter: (filter: Date) => Date;
}

export default function useDateToolbarState(): UseDateToolbarStateReturnValue {
  const [date, setDate] = useState<Date>(new Date());

  const onDateChanged = (newDate: Date) => setDate(newDate);

  const onApplyDateFilter = (startFrom: Date) => {
    const startThru = new Date(startFrom);
    startThru.setDate(startFrom.getDate() + 1);

    return startFrom;
  };

  const onToday = () => onDateChanged(onApplyDateFilter(new Date()));

  const onPreviousDay = () => {
    const previousDay = new Date(date);
    previousDay.setDate(previousDay.getDate() - 1);

    onDateChanged(onApplyDateFilter(previousDay));
  };

  const onNextDay = () => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);

    onDateChanged(onApplyDateFilter(nextDay));
  };

  return { currentDate: date, onDateChanged, onToday, onNextDay, onPreviousDay, onApplyDateFilter };
}
