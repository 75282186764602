export function splitAndCapitalize(str: string): string {
  const words: string[] = str.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
  const formattedWords: string[] = words.map((word, index) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word.toLowerCase();
  });
  return formattedWords.join(' ');
}
