/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TitleContainer } from './styles';

interface MotalTitleProps extends React.ComponentProps<'h2'> {}

export const MotalTitle: React.FC<MotalTitleProps> = ({
  children,
  ...props
}) => (
  <TitleContainer {...props}>{children}</TitleContainer>
);
