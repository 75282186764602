import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

export const OptionContainer = styled.div<{ isSelected: boolean }>`
  border: solid 1px ${(props) => (props.isSelected ? 'transparent' : colors.silver)};
  border-radius: 8px ;
  display: inline-block;
  padding: 8px 12px 8px 12px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => (props.isSelected ? colors.dustyBlue : colors.white)};
  color:  ${(props) => (props.isSelected ? colors.allports : colors.charcoal)};
  cursor: pointer;
  line-height: 20px;
`;

export const RadioButtonGroup = styled.fieldset`
  border: none;
  padding: 0;
  column-gap: 20px;
  display: flex;
`;

export const RadioButtonLabel = styled.label`
  font-sixe: 16px;
  font-weight: 600;
  color: ${colors.charcoal};
  display: flex;
  align-items: center;
`;

export const RadioButton = styled.input`
  width: 24px;
  height: 24px;
  margin-right: 10px;

  &:checked {
    accent-color: ${colors.allports};
  }
`;
