// used naming from design and https://chir.ag/projects/name-that-color/
export const colors = {
  allports: '#006EA5',
  allportsHover: '#00588D',
  alto: '#D2D2D2',
  blue: '#00A2E1',
  lightBlue: '#D8EFF6',
  blueHover: '#3DBDEE',
  charcoal: '#414B56',
  charcoalShadow: '#414B561F', // 12% Opacity
  darkBlue: '#0089BF',
  dustyBlue: '#DFE9F0',
  gray: '#929292',
  grayChateau: '#A1A4A6',
  grayBorder: '#e4e6e8',
  red: '#D6003C',
  redHover: '#E4405D',
  lightRed: '#FFE5EC',
  redPressed: '#BE0035',
  silk: '#F8F8F8',
  silver: '#E1E5E6',
  silverChalice: '#A3A3A3',
  smoke: '#A1A4A6',
  stone: '#676F78',
  tundora: '#474747',
  white: '#FFFFFF',
  lightGreen: '#E0F8D6',
  green: '#008025',
  darkOrange: '#E47C4C',
};
