import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';

import {
  CLEAR_POSITION_LOGS_WAITLIST_ERROR,
  CLEAR_POSITIONS_WAITLIST_ERROR,
  FETCH_WAITLIST,
  FETCH_WAITLIST_LOG_ITEMS,
} from 'src/middleware/waitlist/index';

import { CustomerFormContainer, formatTelephoneInput } from './customer-form';
import { applyDefaultCategories } from './edit-customer-page';

const ViewCustomerPage = ({ dispatch, error, positions }) => {
  const history = useHistory();
  const { pathname } = window.location;
  const { positionId } = matchPath(pathname, '*view-customer/:positionId').params;

  // Look for the position to be edited in the waitlist.
  // If it isn't there, fetch the waitlist
  const position = positions.find(p => p.id === positionId);
  useEffect(() => {
    dispatch({ type: FETCH_WAITLIST_LOG_ITEMS, positionId });
    if (!position) {
      dispatch({ type: FETCH_WAITLIST });
    }
  }, []);

  const [formData, updateFormData] = useState({
    name: '',
    notes: '',
    telephone: '',
    partySize: 1,
    customerId: null,
    categories: applyDefaultCategories(),
  });

  // Update the form data no more than once when the position is found
  const [positionFound, updatePositionFound] = useState(false);
  if (position && !positionFound) {
    updateFormData({
      customerId: position.customer_id || null,
      name: position.name || '',
      notes: position.notes || '',
      partySize: position.party_size || '',
      telephone: formatTelephoneInput(position.telephone) || '',
      categories: applyDefaultCategories(position.categories),
    });
    updatePositionFound(true);
  }

  // If there are positions in the waitlist, but the particular position id
  // is not in the waitlist, display an error.
  // (The most possible case of this is if the edit page is refreshed after
  // another user deleted the particular position.)
  let positionError = null;
  if (positions.length && !position) {
    positionError = 'Position not found';
  }

  const handleBack = async () => {
    if (error) {
      // clear out any stale errors
      await (dispatch({ type: CLEAR_POSITIONS_WAITLIST_ERROR }));
      await (dispatch({ type: CLEAR_POSITION_LOGS_WAITLIST_ERROR }));
    }
    history.push('/');
  };

  return (
    <CustomerFormContainer
      formHeader="View visitor"
      formData={formData}
      dispatch={dispatch}
      updateFormData={updateFormData}
      error={error || positionError}
      disableInput
      handleCancel={handleBack}
      cancelButtonText="Back"
      positions={positions}
      isEditPage={false}
      isReadOnlyPage
      sectionHeader="Details"
      positionId={positionId}
    />
  );
};

const select = (state) => {
  const errors = state.waitlist.error;
  const error = errors.positionLogs || errors.positions;

  return {
    error,
    positions: state.waitlist.positions,
  };
};

export default connect(select)(ViewCustomerPage);
