import { OpticianCategoryKeys, ShoppingCategoryKeys } from '../constants';

export const getDefaultCategories = () => ({
  optician: [
    {
      id: 1, key: OpticianCategoryKeys.Adjustment, displayName: 'Adjustment', active: false,
    },
    {
      id: 2, key: OpticianCategoryKeys.RxTroubleshoot, displayName: 'Rx', subtext: 'Troubleshoot', active: false,
    },
    {
      id: 3, key: OpticianCategoryKeys.PdMeasurement, displayName: 'PD', subtext: 'Measurement', active: false,
    },
    {
      id: 4, key: OpticianCategoryKeys.OcMeasurement, displayName: 'OC', subtext: 'Measurement', active: false,
    },
    {
      id: 5, key: OpticianCategoryKeys.CpuAdjustment, displayName: 'CPU', subtext: 'Adjustment', active: false,
    },
    {
      id: 6, key: OpticianCategoryKeys.CpuNoAdjustment, displayName: 'CPU', subtext: 'No Adjustment', active: false,
    },
    {
      id: 7, key: OpticianCategoryKeys.SegMeasurement, displayName: 'Seg', subtext: 'Measurement', active: false,
    },
    {
      id: 8, key: OpticianCategoryKeys.Neutralization, displayName: 'Neutralization', active: false,
    },
    {
      id: 9, key: OpticianCategoryKeys.LensReplacement, displayName: 'Lens Replacement', active: false,
    },
  ],
  shopping: [
    {
      id: 1, key: ShoppingCategoryKeys.NonRx, displayName: 'Non-Rx', active: false,
    },
    {
      id: 2, key: ShoppingCategoryKeys.Optical, displayName: 'Optical', active: false,
    },
    {
      id: 3, key: ShoppingCategoryKeys.RxOnHand, displayName: 'Rx on hand', active: false,
    },
    {
      id: 4, key: ShoppingCategoryKeys.Insurance, displayName: 'Insurance', active: false,
    },
    {
      id: 5, key: ShoppingCategoryKeys.SingleVision, displayName: 'Single vision', active: false,
    },
    {
      id: 6, key: ShoppingCategoryKeys.Sun, displayName: 'Sun', active: false,
    },
    {
      id: 7, key: ShoppingCategoryKeys.NeedRx, displayName: 'Needs Rx', active: false,
    },
    {
      id: 8, key: ShoppingCategoryKeys.Fsa, displayName: 'FSA', active: false,
    },
    {
      id: 9, key: ShoppingCategoryKeys.Progressive, displayName: 'Progressive', active: false,
    },
    {
      id: 10, key: ShoppingCategoryKeys.Contacts, displayName: 'Contacts', active: false,
    },
    {
      id: 11, key: ShoppingCategoryKeys.EyeExam, displayName: 'Eye exam', active: false,
    },
    {
      id: 12, key: ShoppingCategoryKeys.ReturnOrExchange, displayName: 'Return/exchange', active: false,
    },
  ],
});
