import { call, put, takeLatest } from 'redux-saga/effects';
import WarbyApi from '../../service-clients/warby-api';
import { PAGE_SIZE, View } from '../../components/frame-style-assistant/constants';
import { AvailableFacets, Facet, Facets, Frame, FrameSearchResults } from '../../service-clients/warby-api-types';
import { filterRecommendations } from '../../helpers/frame-style-assistant/filter-recommendations';

const FRAMES_SEARCH_REQUEST = 'FRAMES_SEARCH_REQUEST';
const FRAMES_SEARCH_SUCCESS = 'FRAMES_SEARCH_SUCCESS';
const FRAMES_SEARCH_SUCCESS_WITH_CONCATENATION = 'FRAMES_SEARCH_SUCCESS_WITH_CONCATENATION';
const FRAMES_SEARCH_FAILURE = 'FRAMES_SEARCH_FAILURE';

const SET_FACETS = 'SET_FACETS';
const SET_CURRENT_PAGE_FROM = 'SET_CURRENT_PAGE_FROM';
const SET_NO_MORE_PAGES = 'SET_NO_MORE_PAGES';
const SET_VIEW = 'SET_VIEW';

const RECOMMENDATIONS_SEARCH_REQUEST = 'RECOMMENDATIONS_SEARCH_REQUEST';
const RECOMMENDATIONS_SEARCH_SUCCESS = 'RECOMMENDATIONS_SEARCH_SUCCESS';
const FILTERED_RECOMMENDATIONS_SEARCH_SUCCESS = 'FILTERED_RECOMMENDATIONS_SEARCH_SUCCESS';
const RECOMMENDATIONS_SEARCH_FAILURE = 'RECOMMENDATIONS_SEARCH_FAILURE';

export const requestFrames = (
  facets: Facets = {},
  pageFrom: number = 0,
  pageSize: number = PAGE_SIZE,
) => ({
  type: FRAMES_SEARCH_REQUEST,
  payload: { facets, pageFrom, pageSize },
});

export const requestRecommendation = (
  storeFrameId: string = '',
  facets: Facets = {},
) => ({
  type: RECOMMENDATIONS_SEARCH_REQUEST,
  payload: { storeFrameId, facets },
});

export const onFetchFramesSuccess = (frameSearchResults: FrameSearchResults) => ({
  type: FRAMES_SEARCH_SUCCESS,
  payload: frameSearchResults,
});

export const onFetchFramesSuccessWithConcatenation = (frames : Frame[][]) => ({
  type: FRAMES_SEARCH_SUCCESS_WITH_CONCATENATION,
  payload: frames,
});

export const onFetchFramesFailure = (error) => ({
  type: FRAMES_SEARCH_FAILURE,
  payload: error,
});

export const setFacets = (facets?: Facets) => ({
  type: SET_FACETS,
  payload: facets,
});

export const setView = (view: View) => ({
  type: SET_VIEW,
  payload: view,
});

export const setCurrentPageFrom = (pageFrom: number = 0) => ({
  type: SET_CURRENT_PAGE_FROM,
  payload: pageFrom,
});

export const setNoMorePages = (value: boolean) => ({
  type: SET_NO_MORE_PAGES,
  payload: value,
});

export const onFetchRecommendationsSuccess = (frameSearchResults: FrameSearchResults) => ({
  type: RECOMMENDATIONS_SEARCH_SUCCESS,
  payload: frameSearchResults,
});

export const setFilteredRecommendations = (frames: Frame[][]) => ({
  type: FILTERED_RECOMMENDATIONS_SEARCH_SUCCESS,
  payload: frames,
});

export const onFetchRecommendationsFailure = (error) => ({
  type: RECOMMENDATIONS_SEARCH_FAILURE,
  payload: error,
});

export function* fetchFrames(action: ReturnType<typeof requestFrames>) {
  let queryParams : string[][] = [];
  let pageFrom: number = 0;
  let pageSize: number = 0;
  if (action) {
    const { facets } = action.payload;
    ({ pageFrom, pageSize } = action.payload);
    queryParams = Object.values(facets).map((facet: Facet) => [facet.type, facet.value]);
    queryParams.push(['page_from', String(pageFrom)]);
    queryParams.push(['page_size', String(pageSize)]);
  }

  try {
    const frames = yield call(WarbyApi.fetchFrames, queryParams);
    // there is no more pages to load when the result items count is 0 or less than the page size
    yield put(setNoMorePages(frames.items.length === 0 || frames.items.length < pageSize));
    if (pageFrom === 0) {
      yield put(onFetchFramesSuccess(frames));
    } else {
      yield put(onFetchFramesSuccessWithConcatenation(frames.items));
    }
    yield put(setFilteredRecommendations([]));
  } catch (error) {
    yield put(onFetchFramesFailure(error));
  }
}

export function* fetchRecommendationFrames(action: ReturnType<typeof requestRecommendation>) {
  const { storeFrameId = '', facets = {} }: {
    storeFrameId: string,
    facets: Facets
  } = action.payload || {};

  try {
    const recommendationsFrames = yield call(WarbyApi.fetchRecommendationFrames, storeFrameId);
    yield put(onFetchRecommendationsSuccess(recommendationsFrames));
    const filteredRecommendationsFrames = filterRecommendations(recommendationsFrames, facets);
    yield put(setFilteredRecommendations(filteredRecommendationsFrames.items));
  } catch (error) {
    yield put(onFetchRecommendationsFailure(error));
  }
}

interface State {
  loading: boolean;
  view: View;
  frames?: Frame[][];
  recommendationsFrames?: Frame[][];
  filteredRecommendationsFrames?: Frame[][];
  availableFacets?: AvailableFacets;
  totalFrameOptions?: number;
  error?: Error;
  pageFrom: number;
  noMorePages: boolean;
  facets: Facets;
  term?: string;
}

export const defaultFacets: Facets = {
  kind: {
    type: 'kind',
    value: 'eyeGlasses',
  },
  bridgeFit: {
    type: 'bridgeFit',
    value: 'standardBridge',
  },
};

const initialState : State = {
  loading: false,
  view: View.StyleAssistant,
  pageFrom: 0,
  noMorePages: true,
  facets: defaultFacets,
};

const reducer = (state = initialState, action) : State => {
  switch (action.type) {
    case SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case FRAMES_SEARCH_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case FRAMES_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        frames: action.payload.items,
        totalFrameOptions: action.payload.total,
        availableFacets: action.payload.facets,
      };
    case FRAMES_SEARCH_SUCCESS_WITH_CONCATENATION:
      return {
        ...state,
        loading: false,
        frames: [
          ...(state.frames || []),
          ...action.payload,
        ],
      };
    case FRAMES_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        totalFrameOptions: 0,
        error: action.payload,
      };
    case SET_FACETS:
      return {
        ...state,
        facets: action.payload,
      };
    case SET_CURRENT_PAGE_FROM:
      return {
        ...state,
        pageFrom: action.payload,
      };
    case SET_NO_MORE_PAGES:
      return {
        ...state,
        noMorePages: action.payload,
      };
    case RECOMMENDATIONS_SEARCH_REQUEST:
      return {
        ...state,
        error: undefined,
      };
    case RECOMMENDATIONS_SEARCH_SUCCESS:
      return {
        ...state,
        recommendationsFrames: action.payload.items,
      };
    case FILTERED_RECOMMENDATIONS_SEARCH_SUCCESS:
      return {
        ...state,
        filteredRecommendationsFrames: action.payload,
      };
    case RECOMMENDATIONS_SEARCH_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

function* saga() {
  yield takeLatest(FRAMES_SEARCH_REQUEST, fetchFrames);
  yield takeLatest(RECOMMENDATIONS_SEARCH_REQUEST, fetchRecommendationFrames);
}

export default {
  reducer,
  saga,
};
