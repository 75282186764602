import React from 'react';
import styled from '@emotion/styled';

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 64px;
`;

const SpinnerWheel = styled.span`
  animation: spin 1s infinite linear;
  border-bottom: 4px solid rgba(0, 0, 0, .15);
  border-left: 4px solid #676f78;
  border-radius: 99999px;
  border-right: 4px solid rgba(0, 0, 0, .15);
  border-top: 4px solid rgba(0, 0, 0, .15);
  display: block;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateZ(0);
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner: React.FC = () => (
  <SpinnerContainer>
    <SpinnerWheel />
  </SpinnerContainer>
);
