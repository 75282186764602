import { Facet, Facets, Frame, WidthVariant } from '../../service-clients/warby-api-types';
import { FacetTypes } from '../../components/frame-style-assistant/constants';

function applyWidthFilters(frameFamily: Frame[], widthFacets: Facets) {
  let newFrameFamily = frameFamily;
  const widthValues = Object.values(widthFacets).map((facet: Facet) => facet.value);

  newFrameFamily = newFrameFamily.filter((frameItem: Frame) => frameItem.widthVariants.some(
    (item: WidthVariant) => widthValues.includes(item.width)),
  );
  return newFrameFamily;
}

export function elementMatches(element: Frame[], facets: Facets) {
  let newFrameFamily: Frame[] = element;
  const widthFacets: Facets = {};

  Object.entries(facets).forEach(([key, { value, type }]) => {
    switch (type) {
      case FacetTypes.availableWidths:
        widthFacets[key] = { value, type };
        break;
      case FacetTypes.kind:
        newFrameFamily = newFrameFamily.filter((frameItem: Frame) => frameItem.kind === value);
        break;
      case FacetTypes.bridgeFit:
        newFrameFamily = newFrameFamily.filter((frameItem: Frame) => frameItem.bridgeFit === value);
        break;
      default:
        break;
    }
  });
  // finally the widths filter is applied
  if (JSON.stringify(widthFacets) !== '{}') {
    newFrameFamily = applyWidthFilters(newFrameFamily, widthFacets);
  }
  return newFrameFamily;
}

export function filterRecommendations(initialItems, facets) {
  const recommendationsList = initialItems;
  if (facets) {
    const newItems: Frame[][] = [];
    initialItems.items.forEach(element => {
      const newElement = elementMatches(element, facets);
      if (newElement.length) {
        newItems.push(newElement);
      }
    });
    recommendationsList.items = newItems;
  }
  return recommendationsList;
}
