import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

export const ContentContainer = styled('div')`
  background-color: ${colors.white};

  width: 752px;
  min-height: 200px;

  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  border-radius: 1rem;
`;
