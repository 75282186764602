import styled from '@emotion/styled';
import { DatePicker } from '@mantine/dates';
import { colors } from '../../../../styles/colors';

export const StyledDatePicker = styled(DatePicker)`
  & .mantine-DatePicker-day {
    color: ${colors.stone};
    font-size: 14px;
    font-weight: 400;
    font-family: proxima-nova,serif;
  }

  & [data-selected] {
    background-color: ${colors.allports};
    color: ${colors.white};
    border-radius: 50%;
    font-weight: 600;
    line-height: 20px;
  }

  & .mantine-DatePicker-monthCell {
    padding: 5px 10px 5px 10px;
  }

  & .mantine-DatePicker-calendarHeader {
    max-width: 100%;
    padding-left: .5rem;
    padding-right: .5rem;
    margin-bottom: 2rem;
  }

  & .mantine-DatePicker-calendarHeaderControlIcon {
    color: ${colors.allports};
  }

  & .mantine-DatePicker-weekday {
    color: ${colors.charcoal};
    font-weight: 600;
    font-size: 14px;
    font-family: proxima-nova, sans-serif;
  }

  & .mantine-DatePicker-calendarHeaderLevel {
    font-size: 16px;
    font-weight: 600;
    font-family: proxima-nova;
    color: ${colors.charcoal};
  }
`;
