import React from 'react';
import { connect } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from '@warbyparker/retail-design-system';
import {
  REMOVE_CUSTOMER_FROM_WAITLIST,
  CLEAR_REMOVE_CUSTOMER_FROM_WAITLIST_ERROR,
} from 'src/middleware/waitlist';

import Modal from 'src/modal/index';

const Container = styled('div')`
  background: white;
  border-radius: 8px;
`;

const Content = styled('div')`
  padding: 66px 60px 48px;
`;

const Header = styled('h2')`
  color: #414b56;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  padding: 0 25.5px 18px;
  text-align: center;
`;

const Subtext = styled('p')`
  color: #676f78;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  text-align: center;
`;

const Buttons = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 60px;
  margin: 30px 0 0 48px;
  width: 100%;

  button:first-of-type {
  }

  button:nth-of-type(2) {
    margin-left: 12px;
  }
`;

const RemoveCustomerModal = ({ dispatch, error, removing }) => {
  const history = useHistory();

  const handleCancel = async () => {
    if (error) {
      // clear out any stale errors
      await (dispatch({ type: CLEAR_REMOVE_CUSTOMER_FROM_WAITLIST_ERROR }));
    }

    history.push('/');
  };

  const handleClick = () => {
    const { pathname } = window.location;
    const { positionId } = matchPath(pathname, '*remove-customer/:positionId').params;
    dispatch({
      type: REMOVE_CUSTOMER_FROM_WAITLIST,
      payload: { waitlist_position_id: positionId },
    });
  };

  const modalHeaderText = error ? 'Oh no!' : 'Remove customer from list?';
  const modalSubtext = error
    ? 'There was an error when trying to remove this customer.' : 'You cannot undo this action.';

  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
  return (
    <Modal hasCloseButton={false}>
      <Container>
        <Content>
          <Header>{modalHeaderText}</Header>
          <Subtext>
            {modalSubtext}
            {error && (
              <>
                <br />
                Please cancel and try again.
              </>
            )}
          </Subtext>
          <Buttons>
            <Button
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              color="destructive"
              disabled={error}
              onClick={handleClick}
              loading={removing}
            >
              Remove
            </Button>
          </Buttons>
        </Content>
      </Container>
    </Modal>
  );
};

const select = (state) => ({
  error: state.waitlist.error.remove_customer,
  removing: state.waitlist.removing,
});

export default connect(select)(RemoveCustomerModal);
