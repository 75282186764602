import styled from '@emotion/styled';

export const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
`;

export const FilterCategoryContainer = styled.div`
  display:flex;
  flex-direction: column;    
  row-gap: 8px;
`;

export const FilterCategoryTitle = styled.strong`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;
