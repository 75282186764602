import styled from '@emotion/styled';

export const Container = styled.span`
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgb(248, 248, 248);

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  & > embed {
    width: 100%;
    height: 100%;
  }

  & > div {
    background-color: white;

   
    padding: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
