export function filterAppointmentsByTime(appointmentList) {
  const currentTime = new Date();
  const now = new Date(currentTime);

  const filteredAppointments = appointmentList.filter(appointment => {
    const appointmentTime = new Date(appointment.startAt);

    return (
      appointmentTime >= now
    );
  });

  return filteredAppointments;
}

export const activeFiltersCount = (
  activeFilters: { [key: string]: string[] },
  defaultOptions: string[],
): number => Object.values(activeFilters)
  .flat()
  .filter(option => !defaultOptions.includes(option))
  .length;
