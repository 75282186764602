import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { ADD_CUSTOMER_TO_WAITLIST, CLEAR_POSTED_FLAG } from 'src/middleware/waitlist/index';

import { getDefaultCategories } from './customer-form-default-categories';

import { CustomerFormContainer, formatTelephoneInput } from './customer-form';

export const formatActiveSubcategories = (category) => {
  if (!category) return false;

  // filter out only active subcategories
  const consolidatedCat = category.reduce((obj, subcat) => {
    if (subcat.active) {
      obj[subcat.key] = true; // eslint-disable-line no-param-reassign
    }
    return obj;
  }, {});

  // if no subcategories are active, set category to false by default
  return Object.entries(consolidatedCat).length > 0 ? consolidatedCat : false;
};

const AddCustomerPage = ({ customerAccount, dispatch, error, posted, posting, positions }) => {
  // get customer info from query, if available
  const parsedQuery = queryString.parse(window.location.search);

  const {
    id: queryId,
    name: queryName,
    order: orderId,
    next: redirect,
    categories: queryCategoriesString, // lens_replacement,contacts, ...
  } = parsedQuery;

  const tel = customerAccount.telephone;

  const defaultCategories = getDefaultCategories();

  const cpuAdjustmentIndex = defaultCategories.optician.findIndex(({ key }) => key === 'cpu_adjustment');

  const [formData, updateFormData] = useState({
    name: queryName || '',
    notes: orderId ? `Order #${orderId}` : '',
    telephone: tel ? formatTelephoneInput(tel) : '',
    partySize: 1,
    customerId: queryId || null,
    // eslint-disable-next-line no-constant-condition
    categories: /* cpuAdjustment === 'true' */ false ? {
      ...defaultCategories,
      optician: [
        ...defaultCategories.optician.slice(0, cpuAdjustmentIndex),
        {
          ...defaultCategories.optician[cpuAdjustmentIndex],
          active: true,
        },
        ...defaultCategories.optician.slice(cpuAdjustmentIndex + 1),
      ],
    } : defaultCategories,
  });

  useEffect(() => {
    dispatch({ type: CLEAR_POSTED_FLAG });
  }, []);

  useEffect(() => {
    if (tel) {
      updateFormData({
        ...formData,
        telephone: formatTelephoneInput(tel),
      });
    }
  }, [tel]);

  useEffect(() => {
    const queryCategories = queryCategoriesString?.split(',') || [];

    updateFormData(data => ({
      ...data,
      categories: (() => ({
        ...data.categories,
        optician: data.categories.optician
          .map(cat => ({ ...cat, active: queryCategories.includes(cat.key) })),
        shopping: data.categories.shopping
          .map(cat => ({ ...cat, active: queryCategories.includes(cat.key) })),
      }))(),
    }));
  }, []);

  const handleSubmit = () => {
    const {
      name,
      telephone,
      notes,
      categories: { optician, shopping },
      partySize,
      customerId,
    } = formData;
    // strip all non-numeric characters and symbols
    const numericTelephone = telephone.replace(/[^0-9]/g, '');

    // consolidate categories
    const shoppingSubcategories = formatActiveSubcategories(shopping);
    const opticianSubcategories = formatActiveSubcategories(optician);
    const categories = {
      shopping: shoppingSubcategories,
      optician: opticianSubcategories,
    };

    dispatch({
      type: ADD_CUSTOMER_TO_WAITLIST,
      payload: {
        name,
        notes,
        telephone: numericTelephone,
        categories,
        party_size: partySize,
        customer_id: customerId,
        redirect,
      },
    });
  };

  const loading = posting || (!posting && posted);

  return (
    <CustomerFormContainer
      formHeader="Add visitor to waitlist"
      formData={formData}
      dispatch={dispatch}
      updateFormData={updateFormData}
      error={error}
      handleSubmit={handleSubmit}
      loading={loading}
      sectionHeader="Add someone new"
      positions={positions}
      redirect={redirect}
    />
  );
};

const select = (state) => ({
  customerAccount: state.waitlist.customerAccount,
  error: state.waitlist.error.customer,
  posted: state.waitlist.posted,
  posting: state.waitlist.posting,
  positions: state.waitlist.positions,
});

export default connect(select)(AddCustomerPage);
