import {
  enableActivityTracking,
  newTracker,
  SelfDescribingJson,
  trackSelfDescribingEvent,
} from '@snowplow/browser-tracker';
import { BookedCustomerShopEvent, NewExamBookingEvent } from '../types/snowplow-events';
import { SnowplowSchemas } from '../constants';

class EventTracker {
  appId: string;

  collectorURL: string;

  trackers: string[] = [];

  constructor(collectorURL?: string) {
    this.appId = 'waitlistApp';
    this.collectorURL = collectorURL || process.env.SNOWPLOW_COLLECTOR_URL || '';
    this.initializeTracker('waitlistAppTracker');
  }

  private initializeTracker(trackerNamespace: string) {
    newTracker(trackerNamespace, this.collectorURL, {
      appId: this.appId,
      cookieSameSite: 'Lax',
      platform: 'web',
      stateStorageStrategy: 'cookieAndLocalStorage',
      contexts: {
        session: true,
      },
      anonymousTracking: true,
    });
    enableActivityTracking({
      minimumVisitLength: 30,
      heartbeatDelay: 10,
    });
    this.trackers.push(trackerNamespace);
  }

  public trackNewExamBookingEvent = (data: NewExamBookingEvent) => {
    try {
      trackSelfDescribingEvent({
        event: {
          schema: SnowplowSchemas.events.newExamBookingEvent,
          data,
        } as SelfDescribingJson<NewExamBookingEvent>,
      });
    } catch (error) {
      console.error('Error tracking event: New Exam Booking Event', error);
    }
  };

  public trackBookedCustomerShopEvent = (data: BookedCustomerShopEvent) => {
    try {
      trackSelfDescribingEvent({
        event: {
          schema: SnowplowSchemas.events.bookedCustomerShopEvent,
          data,
        } as SelfDescribingJson<BookedCustomerShopEvent>,
      });
    } catch (error) {
      console.error('Error tracking event: Booked Customer Shop Event', error);
    }
  };
}

export default EventTracker;
