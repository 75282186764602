import React from 'react';

import { Button } from '@warbyparker/retail-design-system';
import styled from '@emotion/styled';

const PaginationContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    color: #a1a4a6;
    font-weight: 900;
    cursor: pointer;
    text-align: center;
    width: 100%;
    height: 100%;
    max-width: 700px;
    max-height: 400px;
`;

const Pagination = styled('div')`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    color: #a1a4a6;
    font-weight: 900;
    cursor: pointer;
    text-align: center;
    width: 100%;
    height: 100%;
    max-width: 700px;
    max-height: 400px;
`;

const PageNumber = styled(Button)`
  background-color: #e4e6e8;
  color: #a1a4a6;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
  min-width: 48px;
  min-height: 48px;
  padding: 10px !important;
`;

const PreviousPage = styled(Button)`
  padding: 10px 12px !important;
  min-width: 48px;
  min-height: 48px;
`;

const NextPage = styled(Button)`
  padding: 10px 12px !important;
  min-width: 48px;
  min-height: 48px;
`;

const Paginate = ({
  currentPage,
  pageCount,
  goToPage,
  previousPage,
  nextPage,
  pageOptions,
}) => (
  <PaginationContainer>
    <Pagination>
      <PreviousPage
        disabled={currentPage === 1}
        onClick={previousPage}
      >
        Previous
      </PreviousPage>

      {pageOptions.map(pageNum => (
        <PageNumber
          disabled={pageNum === currentPage}
          key={pageNum}
          onClick={() => goToPage(pageNum)}
        >
          {pageNum}
        </PageNumber>
      ))}

      <NextPage
        disabled={currentPage === pageCount}
        onClick={nextPage}
      >
        Next
      </NextPage>
    </Pagination>
  </PaginationContainer>
);

export default Paginate;
