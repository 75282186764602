import styled from '@emotion/styled';
import { colors } from '../../../../styles/colors';

type StyledFooterProps = {
  showNextAvailableEyeExams: boolean;
};

export const StyledFooter = styled.footer<StyledFooterProps>`
  bottom: 0;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${colors.white};
  position: sticky;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 32px;
  overflow: hidden;
  height: ${props => (props.showNextAvailableEyeExams ? '202px' : '80px')};
  transition: height 0.3s ease;
  z-index: 3;

  & button:not(:first-of-type) {
    margin-left: 15px;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 calc(100%)
`;

export const AvailableExamsTogglerContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
`;

export const AvailableExamsTogglerLabel = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
`;

export const AvailableExamsTogglerButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid ${colors.grayBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const FooterActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
