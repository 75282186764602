const knownTerms = {
  glasses_cl: 'Eyeglasses/Contacts',
  cl: 'Contacts',
  glasses: 'Eyeglasses',
  curwear: '',
  newwear: '(New)',
  img: 'Image',
};

export function formatExamType(value?: string[] | null): string {
  if (!value || !value?.length) {
    return 'Not Specified';
  }

  return value
    .filter(Boolean)
    .map(type => (
      type
        .toLowerCase()
        .replace('glasses_cl', knownTerms.glasses_cl)
        .replace('curwear', '')
        .split('_')
        .map(word => (
          knownTerms[word]
            ? knownTerms[word]
            : (word).charAt(0).toUpperCase() + (word).substring(1)
        ))
        .join(' ')
        .trim()
    ))
    .join(', ');
}
