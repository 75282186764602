import { SortColumn, SortOption, SortOrder } from 'src/constants';

export const AppointmentsSortOptions = [
  {
    column: SortColumn.StartAt,
    order: SortOrder.Desc,
    option: SortOption.Ascending,
  },
  {
    column: SortColumn.StartAt,
    order: SortOrder.Asc,
    option: SortOption.Descending,
  },
  {
    column: SortColumn.PatientFirstName,
    order: SortOrder.Asc,
    option: SortOption['A-Z'],
  },
  {
    column: SortColumn.CreatedAt,
    order: SortOrder.Desc,
    option: SortOption.Newest,
  },
  {
    column: SortColumn.CreatedAt,
    order: SortOrder.Asc,
    option: SortOption.Oldest,
  },
];
