import React from 'react';
import { SortItem } from 'src/middleware/eye-exams/types';
import { RadioButtonGroup, RadioButtonLabel, RadioButton } from './styles';
import { AppointmentsSortOptions } from './appointments-sort-options';

interface SortOptionsProps {
  setExpandedSortMenu: (value: boolean) => void;
  onSortChange: (item: SortItem) => void;
  sortItem: SortItem;
}

export const SortOptions: React.FC<SortOptionsProps> = ({
  sortItem,
  onSortChange,
  setExpandedSortMenu,
}) => {
  const handleOptionClick = (selectedSortItem: SortItem) => {
    if (selectedSortItem) {
      onSortChange({ ...selectedSortItem });
      setExpandedSortMenu(false);
    }
  };
  return (
    <RadioButtonGroup>
      {AppointmentsSortOptions.map((item) => (
        <RadioButtonLabel key={`${item.option}-option`}>
          <RadioButton
            key={item.option}
            type="radio"
            value={item.option}
            checked={sortItem.option === item.option}
            onChange={() => handleOptionClick(item)}
          />
          {item.option}
        </RadioButtonLabel>
      ))}
    </RadioButtonGroup>
  );
};
