import React from 'react';
import { createPortal } from 'react-dom';

interface PortalProps extends React.PropsWithChildren<{}> {
  element: HTMLElement
  id: string
}

const Portal: React.FC<PortalProps> = ({ element, id, children }) => createPortal(
  <>{children}</>,
  element,
  id,
);

export default Portal;
