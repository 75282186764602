import React from 'react';
import styled from '@emotion/styled';

const StyledDropdown = styled('div')`
  position: absolute;
  z-index: 2;
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  max-height: 336px;
  overflow-y: scroll;
  width: 100%;
  box-shadow: 0 14px 18px rgba(0, 36, 84, .3);
  -webkit-overflow-scrolling: touch;
`;

const Dropdown = ({ children }) => (
  <StyledDropdown>{children}</StyledDropdown>
);

export default Dropdown;
