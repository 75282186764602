import React from 'react';
import styled from '@emotion/styled';

const Wrap = styled('div')`
  padding: 40px 40px;
  h1 {
    margin: 0;
    padding-bottom: 10px;
  }
  p a {
    font-size: 20px;
  }
`;

const ErrorPage = ({ error }) => (
  <Wrap>
    <h1>Whoops!</h1>
    <h2>{error}</h2>
    <p>
      <a href="/">Refresh</a>
    </p>
  </Wrap>
);

export default ErrorPage;
