export const createScheduledSet = (lifetime: number) => {
  const data = new Set();

  const add = (item: any) => {
    if (data.has(item) || item === null) return;

    data.add(item);

    setTimeout(() => {
      data.delete(item);
    }, lifetime);
  };

  return {
    add,
    has: data.has,
    delete: data.delete,
    toArray: () => Array.from(data),
  };
};
