import React, { useEffect, useMemo, useState } from 'react';
import { CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG } from 'feature-flags';
import { useDispatch } from 'react-redux';
import { Spinner } from '../spinner';
import FeatureFlagged from '../feature-flagged';
import DataViewToolbar from '../data-view-toolbar/dataViewToolbar';
import AppointmentList from '../appointments/appointment-list';
import { Container, EmptyListMessage } from './styles';
import { filterAppointmentsByTime } from '../../utils/customerList';
import { AppointmentsState, SortItem } from '../../middleware/eye-exams/types';
import { resetAppointmentsSort, updateAppointmentsSort } from '../../middleware/eye-exams/actions';
import ErrorBlock from '../error-block';

interface EyeExamsTabProps {
  error: string,
  sortItem: SortItem,
  appointments: AppointmentsState,
  isAppointmentsLoading: boolean,
  confirmEyeExamFeatureEnabled: boolean,
  onFilterChange: (showFilteredAppointments: boolean) => void
}

const EyeExamsTab = ({
  error,
  sortItem,
  appointments,
  isAppointmentsLoading,
  confirmEyeExamFeatureEnabled,
  onFilterChange,
}: EyeExamsTabProps) => {
  const dispatch = useDispatch();
  const [showFilteredAppointments, setShowFilteredAppointments] = useState(true);

  const handleSortChange = (selectedSortItem: SortItem) => {
    dispatch(updateAppointmentsSort(selectedSortItem));
  };

  useEffect(() => {
    onFilterChange(showFilteredAppointments);
    dispatch(resetAppointmentsSort());
  }, []);

  const handleAppointmentViewClick = () => {
    setShowFilteredAppointments((prevState) => {
      onFilterChange(!prevState);
      return !prevState;
    });
  };

  const filteredAppointments = useMemo(() =>
    filterAppointmentsByTime(appointments.data), [appointments.data]);

  const filterButtonText = showFilteredAppointments ? 'Show All' : 'Show Upcoming';

  const getContent = () => {
    if (isAppointmentsLoading) {
      return <Spinner />;
    }

    if (appointments.data.length === 0) {
      return <EmptyListMessage>— No booked exams —</EmptyListMessage>;
    }

    return (
      <AppointmentList
        data={showFilteredAppointments ? filteredAppointments : appointments.data}
        pageCount={!showFilteredAppointments ? appointments.pageCount : null}
        page={!showFilteredAppointments ? appointments.page : null}
        confirmEyeExamFeatureEnabled={confirmEyeExamFeatureEnabled}
        onToggleAppointmentsView={handleAppointmentViewClick}
        filterButtonText={filterButtonText}
        onSortChange={handleSortChange}
        sortItem={sortItem}
        pageSize={50}
      />
    );
  };

  return (
    <Container>
      <FeatureFlagged feature={CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG}>
        <DataViewToolbar
          onSortChange={handleSortChange}
          sortItem={sortItem}
        />
      </FeatureFlagged>
      <>
        {error && <ErrorBlock error={error} />}
        {getContent()}
      </>
    </Container>
  );
};

export default EyeExamsTab;
