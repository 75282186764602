import { getNewRelicAgent } from '..';

type CompliantQRCodeData = {
  reference: 'ios' | 'android' | 'web' | 'mweb' | 'unknown';
};

/**
 * Plucks data from the QR code that does not potentially identify
 * the customer in any way.
 */
const pluckCompliantData = (qrCodeData: CompliantQRCodeData) => {
  const { reference } = qrCodeData;

  return {
    reference,
  };
};

export const onQRCodeScanned = (qrCodeData: CompliantQRCodeData) => {
  const agent = getNewRelicAgent();
  agent.addPageAction('qr-code-scan', {
    ...pluckCompliantData(qrCodeData),
    app: 'waitlist',
    location: 'add-customer',
  });
};

export const logQrScanEventSagaHandler = (resolution: {
  payload: { data: CompliantQRCodeData | string };
}) => {
  const {
    payload: { data },
  } = resolution;

  try {
    const qrCodeScanData = typeof data === 'object' ? data : JSON.parse(data);

    onQRCodeScanned(qrCodeScanData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};
