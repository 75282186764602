import { call, put, takeLatest } from 'redux-saga/effects';
import WarbyApi from '../../service-clients/warby-api';
import { Frame } from '../../service-clients/warby-api-types';

export const FRAME_GET_REQUEST = 'FRAME_GET_REQUEST';
export const FRAME_GET_SUCCESS = 'FRAME_GET_SUCCESS';
export const FRAME_GET_FAILURE = 'FRAME_GET_FAILURE';

export const requestFrame = (frameId: string) => ({
  type: FRAME_GET_REQUEST,
  payload: { frameId },
});

export const onGetFrameSuccess = (frameVariants: Frame[]) => ({
  type: FRAME_GET_SUCCESS,
  payload: frameVariants,
});

export const onGetFrameFailure = (error: Error) => ({
  type: FRAME_GET_FAILURE,
  payload: error,
});

interface State {
  loading: boolean;
  frameConfigFamily: Frame[];
  error?: Error[];
}

const initialState : State = {
  loading: false,
  frameConfigFamily: [],
};

export function* getFrame(action: ReturnType<typeof requestFrame>) {
  const { frameId } = action.payload;

  try {
    const frameVariants = yield call(WarbyApi.getFrame, frameId);
    yield put(onGetFrameSuccess(frameVariants));
  } catch (error) {
    yield put(onGetFrameFailure(error as Error));
  }
}

const reducer = (state = initialState, action) : State => {
  switch (action.type) {
    case FRAME_GET_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
        frameConfigFamily: [],
      };
    case FRAME_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        frameConfigFamily: action.payload,
      };
    case FRAME_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

function* saga() {
  yield takeLatest(FRAME_GET_REQUEST, getFrame);
}

export default {
  reducer,
  saga,
};
