/* eslint-disable no-undef */
import { call, fork, put, select, take, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import { logQrScanEventSagaHandler } from 'src/new-relic/custom-actions/qr-code-scan-action';

import API from 'src/service-clients/helios-retail';
import urlstr from '../../utils/urlstr';
import { AUTO_REFRESH_RATE_MS } from '../../constants';
import toCheckFeature from '../../connect-helpers/toCheckFeature';
import { AUTO_REFRESH_FEATURE_FLAG } from '../../../feature-flags';

export const SET_SELECTED_STATUS = 'SET_SELECTED_STATUS';

export const FETCH_WAITLIST = 'FETCH_WAITLIST';
export const FETCH_WAITLIST_SUCCESS = 'FETCH_WAITLIST_SUCCESS';
export const FETCH_WAITLIST_FAILURE = 'FETCH_WAITLIST_FAILURE';

export const ENABLE_AUTO_REFRESH = 'ENABLE_AUTO_REFRESH';
export const DISABLE_AUTO_REFRESH = 'DISABLE_AUTO_REFRESH';

export const FETCH_WAITLIST_LOG_ITEMS = 'FETCH_WAITLIST_LOG_ITEMS';
export const FETCH_WAITLIST_LOG_ITEMS_SUCCESS = 'FETCH_WAITLIST_LOG_ITEMS_SUCCESS';
export const FETCH_WAITLIST_LOG_ITEMS_FAILURE = 'FETCH_WAITLIST_LOG_ITEMS_FAILURE';

const FETCH_FACILITY_DISPLAY_ADDRESS = 'FETCH_FACILITY_DISPLAY_ADDRESS';
const FETCH_FACILITY_DISPLAY_ADDRESS_SUCCESS = 'FETCH_FACILITY_DISPLAY_ADDRESS_SUCCESS';
const FETCH_FACILITY_DISPLAY_ADDRESS_FAILURE = 'FETCH_FACILITY_DISPLAY_ADDRESS_FAILURE';

export const ADD_CUSTOMER_TO_WAITLIST = 'ADD_CUSTOMER_TO_WAITLIST';
export const ADD_CUSTOMER_TO_WAITLIST_SUCCESS = 'ADD_CUSTOMER_TO_WAITLIST_SUCCESS';
export const ADD_CUSTOMER_TO_WAITLIST_FAILURE = 'ADD_CUSTOMER_TO_WAITLIST_FAILURE';
export const EDIT_CUSTOMER_IN_WAITLIST = 'EDIT_CUSTOMER_IN_WAITLIST';
export const EDIT_CUSTOMER_IN_WAITLIST_SUCCESS = 'EDIT_CUSTOMER_IN_WAITLIST_SUCCESS';
export const EDIT_CUSTOMER_IN_WAITLIST_FAILURE = 'EDIT_CUSTOMER_IN_WAITLIST_FAILURE';
export const CLEAR_CUSTOMER_WAITLIST_ERROR = 'CLEAR_CUSTOMER_WAITLIST_ERROR';
export const CLEAR_POSITIONS_WAITLIST_ERROR = 'CLEAR_POSITIONS_WAITLIST_ERROR';
export const CLEAR_POSITION_LOGS_WAITLIST_ERROR = 'CLEAR_POSITION_LOGS_WAITLIST_ERROR';
export const UPDATE_CUSTOMER_STATUS = 'UPDATE_CUSTOMER_STATUS';
export const UPDATE_CUSTOMER_STATUS_SUCCESS = 'UPDATE_CUSTOMER_STATUS_SUCCESS';
export const UPDATE_CUSTOMER_STATUS_FAILURE = 'UPDATE_CUSTOMER_STATUS_FAILURE';

export const REMOVE_CUSTOMER_FROM_WAITLIST = 'REMOVE_CUSTOMER_FROM_WAITLIST';
export const REMOVE_CUSTOMER_FROM_WAITLIST_SUCCESS = 'REMOVE_CUSTOMER_FROM_WAITLIST_SUCCESS';
export const REMOVE_CUSTOMER_FROM_WAITLIST_FAILURE = 'REMOVE_CUSTOMER_FROM_WAITLIST_FAILURE';
export const CLEAR_REMOVE_CUSTOMER_FROM_WAITLIST_ERROR = 'CLEAR_REMOVE_CUSTOMER_FROM_WAITLIST_ERROR';

export const SEND_RETURN_SMS = 'SEND_RETURN_SMS';
export const SEND_RETURN_SMS_SUCCESS = 'SEND_RETURN_SMS_SUCCESS';
export const SEND_RETURN_SMS_FAILURE = 'SEND_RETURN_SMS_FAILURE';
export const CLEAR_SEND_RETURN_SMS_ERROR = 'CLEAR_SEND_RETURN_SMS_ERROR';

export const WAITLIST_START_SHOPPING = 'WAITLIST_START_SHOPPING';
export const WAITLIST_START_SHOPPING_FAILURE = 'WAITLIST_START_SHOPPING_FAILURE';

export const WAITLIST_START_SHOPPING_WITH_EMAIL = 'WAITLIST_START_SHOPPING_WITH_EMAIL';

export const WAITLIST_RESEND_INTAKE_FORM = 'WAITLIST_RESEND_INTAKE_FORM';

export const FETCH_WAITLIST_CUSTOMER_ACCOUNT = 'FETCH_WAITLIST_CUSTOMER_ACCOUNT';
export const FETCH_WAITLIST_CUSTOMER_ACCOUNT_FAILURE = 'FETCH_WAITLIST_CUSTOMER_ACCOUNT_FAILURE';
export const SET_WAITLIST_CUSTOMER_ACCOUNT = 'SET_WAITLIST_CUSTOMER_ACCOUNT';

export const FETCH_WAITLIST_FACILITY_TEAM = 'FETCH_WAITLIST_FACILITY_TEAM';
export const FETCH_WAITLIST_FACILITY_TEAM_SUCCESS = 'FETCH_WAITLIST_FACILITY_TEAM_SUCCESS';
export const FETCH_WAITLIST_FACILITY_TEAM_FAILURE = 'FETCH_WAITLIST_FACILITY_TEAM_FAILURE';

export const ADD_ASSIGNEE_TO_WAITLIST = 'ADD_ASSIGNEE_TO_WAITLIST';
export const ADD_ASSIGNEE_TO_WAITLIST_SUCCESS = 'ADD_ASSIGNEE_TO_WAITLIST_SUCCESS';
export const ADD_ASSIGNEE_TO_WAITLIST_FAILURE = 'ADD_ASSIGNEE_TO_WAITLIST_FAILURE';
export const CLEAR_ADD_ASSIGNEE_TO_WAITLIST_ERROR = 'CLEAR_ADD_ASSIGNEE_TO_WAITLIST_ERROR';

export const CLEAR_POSTED_FLAG = 'CLEAR_POSTED_FLAG';

export const REQUEST_QR_FOR_WAITLIST = 'REQUEST_QR_FOR_WAITLIST';

export const dispatchFetchFacilityDisplayAddress = () => ({
  type: FETCH_FACILITY_DISPLAY_ADDRESS,
});

export const selectAutoRefreshEnabled = (state) => state.waitlist.autoRefreshEnabled;
const selectAutoRefreshFeatureEnabled = (state) => toCheckFeature(state)(AUTO_REFRESH_FEATURE_FLAG);

function* fetchWaitlist() {
  const token = yield select((state) => state.auth.token);
  let data;

  try {
    const response = yield API.getWaitlist({ token });
    data = response.positions;
  } catch (err) {
    const msg = 'Error fetching store waitlist';
    logger.error({ err }, msg);
    yield put({
      type: FETCH_WAITLIST_FAILURE,
      payload: { error: err.message },
    });
    const autoRefreshFeatureEnabled = yield select(selectAutoRefreshFeatureEnabled);
    if (autoRefreshFeatureEnabled) yield put({ type: DISABLE_AUTO_REFRESH });
    return;
  }

  yield put({ type: FETCH_WAITLIST_SUCCESS, payload: { data } });
}

function* fetchWaitlistLogItems({ positionId }) {
  const token = yield select((state) => state.auth.token);
  let data;

  try {
    const response = yield API.getWaitlistLogItems({ token, positionId });
    data = response.log_items;
  } catch (err) {
    const msg = 'Error fetching waitlist log items';
    logger.error({ err }, msg);
    yield put({
      type: FETCH_WAITLIST_LOG_ITEMS_FAILURE,
      payload: { error: err.message },
    });
    return;
  }

  yield put({ type: FETCH_WAITLIST_LOG_ITEMS_SUCCESS, payload: { data } });
}

function* fetchFacilityDisplayAddress() {
  const [token, facilityId] = yield select((state) => [
    state.auth.token,
    state.auth.me.facility.id,
  ]);

  try {
    const facilityDisplayAddress = yield API.getFacilityDisplayAddress({
      token,
      facilityId,
    });

    yield put({
      type: FETCH_FACILITY_DISPLAY_ADDRESS_SUCCESS,
      payload: facilityDisplayAddress,
    });
  } catch (err) {
    logger.error({ err }, 'Couldn\'t fetch facility Display Address');

    yield put({
      type: FETCH_FACILITY_DISPLAY_ADDRESS_FAILURE,
      payload: err.message,
    });
  }
}

function* fetchWaitlistFacilityTeam() {
  const token = yield select((state) => state.auth.token);
  let data;

  try {
    const response = yield API.getWaitlistFacilityTeam({ token });
    data = response;
  } catch (err) {
    const msg = 'Error fetching store waitlist facility team';
    logger.error({ err }, msg);
    yield put({
      type: FETCH_WAITLIST_FACILITY_TEAM_FAILURE,
      payload: { error: err.message },
    });
    return;
  }

  yield put({ type: FETCH_WAITLIST_FACILITY_TEAM_SUCCESS, payload: { data } });
}

function* fetchCustomerAccount({ customer_id }) {
  const token = yield select((state) => state.auth.token);
  let response;

  try {
    response = yield API.getCustomerAccount({ token, customer_id });
  } catch (err) {
    const msg = 'Error fetching customer account';
    logger.error({ err }, msg);
    yield put({
      type: FETCH_WAITLIST_CUSTOMER_ACCOUNT_FAILURE,
      payload: { error: err.message },
    });
    return;
  }

  yield put({
    type: SET_WAITLIST_CUSTOMER_ACCOUNT,
    payload: {
      id: response.customer_id,
      email: response.email,
      telephone: response.telephone,
    },
  });
}

function* addCustomerToWaitlist(
  history,
  {
    payload: {
      name,
      notes,
      telephone,
      categories,
      party_size,
      customer_id,
      redirect,
    },
  },
) {
  const token = yield select((state) => state.auth.token);
  let response;

  try {
    response = yield API.addCustomerToWaitlist({
      token,
      name,
      notes,
      telephone,
      categories,
      party_size,
      customer_id,
    });
  } catch (err) {
    const msg = 'Error adding customer to store waitlist';
    logger.error({ err }, msg);
    yield put({
      type: ADD_CUSTOMER_TO_WAITLIST_FAILURE,
      payload: { error: err.message },
    });
    return;
  }

  // redirect to waitlist after adding a customer
  yield put({ type: ADD_CUSTOMER_TO_WAITLIST_SUCCESS, payload: response });

  if (redirect) {
    history.push(`/?next=${redirect}`);
  } else {
    history.push('/');
  }
}

function* editCustomerInWaitlist(
  history,
  {
    waitlist_position_id,
    payload: {
      name,
      notes,
      telephone,
      categories,
      status,
      party_size,
      customer_id,
      assignee,
    },
  },
) {
  const token = yield select((state) => state.auth.token);
  let response;

  try {
    response = yield API.editCustomerInWaitlist({
      token,
      waitlist_position_id,
      name,
      notes,
      telephone,
      categories,
      status,
      party_size,
      customer_id,
      assignee,
    });
  } catch (err) {
    if (assignee) {
      const assigneeErrorMsg = 'Error assigning team member to customer';
      logger.error({ err }, assigneeErrorMsg);
      yield put({
        type: ADD_ASSIGNEE_TO_WAITLIST_FAILURE,
        payload: { error: err.message },
      });
    }

    const msg = 'Error editing customer in store waitlist';
    logger.error({ err }, msg);
    yield put({
      type: EDIT_CUSTOMER_IN_WAITLIST_FAILURE,
      payload: { error: err.message },
    });
    return;
  }

  if (status === 'in_progress' || assignee) {
    yield put({ type: ADD_ASSIGNEE_TO_WAITLIST_SUCCESS });
  }
  // redirect to waitlist after editing a customer
  yield put({ type: EDIT_CUSTOMER_IN_WAITLIST_SUCCESS, payload: response });
  history.push('/');
}

function* updateCustomerStatus(
  history,
  { waitlist_position_id, payload: { status } },
) {
  // if moving a position to in progress, redirect to the modal
  // to add an assignee to the position
  if (status === 'in_progress') {
    history.push(`/add-assignee/${waitlist_position_id}`);
  }

  const token = yield select((state) => state.auth.token);
  let response;

  try {
    response = yield API.editCustomerInWaitlist({
      token,
      waitlist_position_id,
      status,
    });
  } catch (err) {
    const msg = 'Error updating status of waitlist position';
    logger.error({ err }, msg);
    yield put({
      type: UPDATE_CUSTOMER_STATUS_FAILURE,
      payload: { error: err.message },
    });
    return;
  }

  // Re-fetch the waitlist after updating a customer's status.
  // Not redirecting because the browser is already at "/".
  yield put({ type: FETCH_WAITLIST });
  yield put({ type: UPDATE_CUSTOMER_STATUS_SUCCESS, payload: response });
}

function* removeCustomerFromWaitlist(
  history,
  { payload: { waitlist_position_id } },
) {
  const token = yield select((state) => state.auth.token);
  let response;

  try {
    response = yield API.removeCustomerFromWaitlist({
      token,
      waitlist_position_id,
    });
  } catch (err) {
    const msg = 'Error removing customer from store waitlist';
    logger.error({ err }, msg);
    yield put({
      type: REMOVE_CUSTOMER_FROM_WAITLIST_FAILURE,
      payload: { error: err.message },
    });
    return;
  }

  // redirect to waitlist after removing a customer
  yield put({ type: REMOVE_CUSTOMER_FROM_WAITLIST_SUCCESS, payload: response });
  history.push('/');
}

function* sendReturnSMSToCustomer({ payload: { waitlist_position_id } }) {
  const token = yield select((state) => state.auth.token);
  let response;

  try {
    response = yield API.sendReturnSMSToCustomer({
      token,
      waitlist_position_id,
    });
  } catch (err) {
    const msg = 'Error sending return SMS to customer';
    logger.error({ err }, msg);
    yield put({
      type: SEND_RETURN_SMS_FAILURE,
      payload: { error: err.message },
    });
    return;
  }

  // Re-fetch the waitlist after sending a return SMS.
  // Not redirecting because the browser is already at "/".
  yield put({ type: FETCH_WAITLIST });
  yield put({ type: SEND_RETURN_SMS_SUCCESS, payload: response });
}

function* startShoppingFromWaitlist({ payload: { waitlist_position_id } }) {
  const token = yield select((state) => state.auth.token);
  let response;

  try {
    response = yield API.createEstimateFromWaitlist({
      token,
      waitlist_position_id,
    });
  } catch (err) {
    const msg = 'Error creating estimate from waitlist position';
    logger.error({ err }, msg);
    yield put({
      type: WAITLIST_START_SHOPPING_FAILURE,
      payload: { error: err.message },
    });
    return;
  }

  // Redirect to POE with the newly created estimate
  window.location.href = `https://${process.env.POE_DOMAIN}/visit/estimate/${response.estimate_id}`;
}

function* startShoppingWithEmail({ payload: patient }) {
  const token = yield select((state) => state.auth.token);
  const email = patient.patientEmail;

  try {
    const { id: visitId, estimate_id: estimateId } = yield API.createVisitWithEstimate({ token });

    let visitUrl = `https://${process.env.POE_DOMAIN}/new-visit/${visitId}/estimate/${estimateId}`;

    const customers = email
      ? yield API.getCustomers({ token, query: encodeURIComponent(email) })
      : [];
    const selectedCustomer = customers.find(customer => customer.email === email);

    if (selectedCustomer) {
      const params = new URLSearchParams({
        customer_id: selectedCustomer.id,
        customer_email: selectedCustomer.email,
      });

      visitUrl = `${visitUrl}?${params}`;
    } else {
      const params = new URLSearchParams({
        customer_email: email,
        customer_first_name: patient.firstName,
        customer_last_name: patient.lastName,
        customer_phone_number: patient.phoneNumber,
      });
      visitUrl = `${visitUrl}/customer?${params}`;
    }

    window.location.assign(visitUrl);
  } catch (err) {
    const msg = `Error creating estimate from email "${email}"`;
    logger.error({ err }, msg);
  }
}

function* resendIntakeForm({ payload: appointmentId }) {
  const token = yield select((state) => state.auth.token);
  try {
    yield API.resendIntakeForm({
      token,
      appointmentId });
  } catch (err) {
    const msg = `Error resending intake form with id"${appointmentId}"`;
    logger.error({ err }, msg);
  }
}

export function* positionsAutoRefresh() {
  while (true) {
    yield delay(AUTO_REFRESH_RATE_MS);
    const autoRefreshEnabled = yield select(selectAutoRefreshEnabled);
    if (!autoRefreshEnabled) break;
    yield put({ type: FETCH_WAITLIST });
  }
}

const defaultErrorState = {
  customer: null,
  positions: null,
  positionLogs: null,
  remove_customer: null,
  send_sms: null,
  update_status: null,
  customer_account: null,
  start_shopping: null,
  facility_display_address: null,
  facility_team: null,
  add_assignee: null,
};

const initialState = {
  error: defaultErrorState,
  loading: false,
  positions: [],
  positionLogs: [],
  autoRefreshEnabled: false,
  selected_status: 'waiting',
  facility_display_address: null,
  facility_team: {
    opticians: [],
    sales_advisors: [],
  },

  // adding a customer
  posted: false,
  posting: false,

  // removing a customer
  removed: false,
  removing: false,

  // sending a return sms
  sending: false,
  sent: false,

  // customer account info for the add/edit form
  customerAccount: {
    id: null,
    email: null,
  },

  // getting facility team
  facility_team_loading: false,
  facility_team_loaded: false,

  // assigning a team member
  assigned: false,
  assigning: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_STATUS:
      return {
        ...state,
        error: defaultErrorState,
        selected_status: payload.selected_status,
      };
    case FETCH_WAITLIST:
      return {
        ...state,
        error: defaultErrorState,
        loading: true,
      };
    case FETCH_WAITLIST_SUCCESS:
      return {
        ...state,
        error: state.error,
        facility_team: state.facility_team,
        selected_status: state.selected_status,
        loading: false,
        positions: payload.data,
      };
    case FETCH_WAITLIST_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          positions: payload.error,
        },
        loading: false,
      };
    case FETCH_WAITLIST_LOG_ITEMS:
      return {
        ...state,
        error: defaultErrorState,
        loading: true,
      };
    case FETCH_WAITLIST_LOG_ITEMS_SUCCESS:
      return {
        ...state,
        error: state.error,
        loading: false,
        positionLogs: payload.data,
      };
    case FETCH_WAITLIST_LOG_ITEMS_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          positionLogs: payload.error,
        },
        loading: false,
      };
    case FETCH_FACILITY_DISPLAY_ADDRESS_SUCCESS:
      return {
        ...state,
        error: {
          ...state.error,
          facility_address: defaultErrorState.facility_display_address,
        },
        facility_display_address: payload,
      };
    case FETCH_FACILITY_DISPLAY_ADDRESS_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          facility_address: payload,
        },
        facility_display_address: null,
      };
    case FETCH_WAITLIST_FACILITY_TEAM:
      return {
        ...state,
        error: defaultErrorState,
        facility_team_loading: true,
      };
    case FETCH_WAITLIST_FACILITY_TEAM_SUCCESS:
      return {
        ...state,
        error: state.error,
        facility_team_loading: false,
        facility_team_loaded: true,
        facility_team: payload.data,
      };
    case FETCH_WAITLIST_FACILITY_TEAM_FAILURE:
      return {
        ...state,
        facility_team_loading: false,
        error: {
          ...state.error,
          facility_team: payload.error,
        },
      };
    case ADD_ASSIGNEE_TO_WAITLIST:
      return {
        ...state,
        assigning: true,
      };
    case ADD_ASSIGNEE_TO_WAITLIST_SUCCESS:
      return {
        ...state,
        assigning: false,
        assigned: true,
      };
    case ADD_ASSIGNEE_TO_WAITLIST_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          add_assignee: payload.error,
        },
        assigning: false,
      };
    case CLEAR_ADD_ASSIGNEE_TO_WAITLIST_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          add_assignee: null,
        },
      };
    case UPDATE_CUSTOMER_STATUS:
    case EDIT_CUSTOMER_IN_WAITLIST:
    case ADD_CUSTOMER_TO_WAITLIST:
      return {
        ...state,
        posting: true,
      };
    case UPDATE_CUSTOMER_STATUS_SUCCESS:
    case EDIT_CUSTOMER_IN_WAITLIST_SUCCESS:
      return {
        ...state,
        error: defaultErrorState,
        posted: true,
        posting: false,
      };
    case ADD_CUSTOMER_TO_WAITLIST_SUCCESS:
      return {
        ...state,
        error: defaultErrorState,
        selected_status: 'waiting',
        posted: true,
        posting: false,
      };
    case EDIT_CUSTOMER_IN_WAITLIST_FAILURE:
    case ADD_CUSTOMER_TO_WAITLIST_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          customer: payload.error,
        },
        posted: false,
        posting: false,
      };
    case CLEAR_CUSTOMER_WAITLIST_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          customer: null,
        },
      };
    case CLEAR_POSITION_LOGS_WAITLIST_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          positionLogs: null,
        },
      };
    case CLEAR_POSITIONS_WAITLIST_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          positions: null,
        },
      };
    case REMOVE_CUSTOMER_FROM_WAITLIST:
      return {
        ...state,
        removing: true,
      };
    case REMOVE_CUSTOMER_FROM_WAITLIST_SUCCESS:
      return {
        ...state,
        error: defaultErrorState,
        removed: true,
        removing: false,
      };
    case REMOVE_CUSTOMER_FROM_WAITLIST_FAILURE:
      return {
        ...state,
        removed: false,
        removing: false,
        error: {
          ...state.error,
          remove_customer: payload.error,
        },
      };
    case CLEAR_REMOVE_CUSTOMER_FROM_WAITLIST_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          remove_customer: null,
        },
      };
    case SEND_RETURN_SMS:
      return {
        ...state,
        sending: true,
      };
    case SEND_RETURN_SMS_SUCCESS:
      return {
        ...state,
        error: defaultErrorState,
        sending: false,
        sent: true,
      };
    case UPDATE_CUSTOMER_STATUS_FAILURE:
      return {
        ...state,
        sending: false,
        sent: false,
        error: {
          ...state.error,
          update_status: payload.error,
        },
      };
    case SEND_RETURN_SMS_FAILURE:
      return {
        ...state,
        sending: false,
        sent: false,
        error: {
          ...state.error,
          send_sms: payload.error,
        },
      };
    case WAITLIST_START_SHOPPING_FAILURE:
      return {
        ...state,
        sending: false,
        sent: false,
        error: {
          ...state.error,
          start_shopping: payload.error,
        },
      };
    case CLEAR_SEND_RETURN_SMS_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          send_sms: null,
        },
      };
    case FETCH_WAITLIST_CUSTOMER_ACCOUNT_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          customer_account: payload.error,
        },
      };
    case SET_WAITLIST_CUSTOMER_ACCOUNT:
      return {
        ...state,
        error: {
          ...state.error,
          customer_account: null,
        },
        customerAccount: payload,
      };
    case CLEAR_POSTED_FLAG:
      return {
        ...state,
        posted: false,
      };
    case ENABLE_AUTO_REFRESH:
      return {
        ...state,
        autoRefreshEnabled: true,
      };
    case DISABLE_AUTO_REFRESH:
      return {
        ...state,
        autoRefreshEnabled: false,
      };
    default:
      return state;
  }
};

const QR_CODE_REQUEST_SCAN = 'QR_CODE_REQUEST_SCAN';
const QR_PICKER_DID_SCAN = 'QR_PICKER_DID_SCAN';

function* redirectToWaitlistForm(resolution) {
  const { payload: { data } } = resolution;

  try {
    const { id: customerId /* , full_name: fullName, cpus */ } = typeof data === 'object' ? data : JSON.parse(data);

    const token = yield select((state) => state.auth.token);

    const customer = yield API.getCustomerAccount({ token, customer_id: customerId });
    const { first_name: firstName, last_name: lastName } = customer;

    window.location.href = urlstr({
      host: window.location.host,
      pathname: '/add-customer',
      query: {
        name: `${firstName} ${lastName}`,
        id: customerId,
        // ...heck. need to get the cpu ids from somewhere
        // cpu_adjustment: Array.isArray(cpus) && !!cpus.length,
        // cpus: Array.isArray(cpus) ? cpus : [],
        cpu_adjustment: false,
        cpus: [],
      },
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

function* qrCodeScanSaga() {
  // State machine for scan events:
  // * Generally, a scan should kick off configuring a new item
  // * If we request a scan for an existing item, we need to take the
  //   next scan event and use it to attach the barcode to that item.
  const actionTypes = [
    REQUEST_QR_FOR_WAITLIST,
  ];
  let action = yield take(actionTypes);
  while (true) {
    yield put({ ...action, type: QR_CODE_REQUEST_SCAN });
    // We may not resolve the scan, so we need to watch out for another
    // request in addition to a scan event.
    const resolution = yield take([...actionTypes, QR_PICKER_DID_SCAN]);
    if (resolution.type === QR_PICKER_DID_SCAN) {
      yield call(logQrScanEventSagaHandler, resolution);
      switch (action.type) {
        case REQUEST_QR_FOR_WAITLIST:
          yield call(redirectToWaitlistForm, resolution);
          break;
        default:
          throw new Error('QR scan received in invalid context!');
      }
      action = yield take(actionTypes);
    } else {
      // It's not actually a resolution, it's another request so let's loop:
      action = resolution;
    }
  }
}

function* saga(history) {
  yield takeEvery(FETCH_WAITLIST, fetchWaitlist);
  yield takeEvery(FETCH_WAITLIST_LOG_ITEMS, fetchWaitlistLogItems);
  yield takeEvery(ADD_CUSTOMER_TO_WAITLIST, addCustomerToWaitlist, history);
  yield takeEvery(EDIT_CUSTOMER_IN_WAITLIST, editCustomerInWaitlist, history);
  yield takeEvery(UPDATE_CUSTOMER_STATUS, updateCustomerStatus, history);
  yield takeEvery(
    REMOVE_CUSTOMER_FROM_WAITLIST,
    removeCustomerFromWaitlist,
    history,
  );
  yield takeEvery(SEND_RETURN_SMS, sendReturnSMSToCustomer);
  yield takeEvery(WAITLIST_START_SHOPPING, startShoppingFromWaitlist);
  yield takeEvery(FETCH_WAITLIST_CUSTOMER_ACCOUNT, fetchCustomerAccount);
  yield takeEvery(FETCH_WAITLIST_FACILITY_TEAM, fetchWaitlistFacilityTeam);
  yield takeLatest(WAITLIST_START_SHOPPING_WITH_EMAIL, startShoppingWithEmail);
  yield takeLatest(FETCH_FACILITY_DISPLAY_ADDRESS, fetchFacilityDisplayAddress);
  yield takeLatest(WAITLIST_RESEND_INTAKE_FORM, resendIntakeForm);
  yield takeLatest(ENABLE_AUTO_REFRESH, positionsAutoRefresh);

  yield fork(qrCodeScanSaga);
}

export default {
  reducer,
  saga,
};
