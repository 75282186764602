import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;
  padding: 30px;
  border-top: 1px solid #E4E6E8;
`;

const Left = styled('div')`
  display: flex;
  flex: 1;
  flex-flow: column;
`;

const Content = styled('div')`
  display: flex;
  flex: 1;
  font-size: 18px;
  font-weight: 600;
`;

const Info = styled('div')`
  font-size: 18px;
`;

const Result = ({ content, cta, info, onClick }) => (
  <Container onClick={onClick}>
    <Left>
      {content && <Content>{content}</Content>}
      {info && <Info>{info}</Info>}
    </Left>
    {cta}
  </Container>
);

export default Result;
