import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from '@warbyparker/retail-design-system';

import {
  ADD_ASSIGNEE_TO_WAITLIST,
  CLEAR_ADD_ASSIGNEE_TO_WAITLIST_ERROR,
  EDIT_CUSTOMER_IN_WAITLIST,
  FETCH_WAITLIST,
  dispatchFetchFacilityDisplayAddress,
} from 'src/middleware/waitlist';

import Modal from 'src/modal';
import { formatTeamMemberName } from '../utils/formatTeamMemberName';

const Container = styled('div')`
  background: white;
  border-radius: 8px;
`;

const Content = styled('div')`
  padding: 66px 60px 48px;
`;

const Header = styled('h2')`
  color: #414b56;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  padding: 0 25.5px 18px;
  text-align: center;
`;

const PrimaryButtonContainer = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 36px 0px 54px;
  width: 100%;
`;

const Divider = styled('p')`
  color: #a1a4a6;
  font-size: 20px;
  margin: 24px auto 54px;
  max-width: 768px;
  text-align: center;
`;

const Subtext = styled('p')`
  color: #676f78;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  text-align: center;
`;

const Section = styled('div')`
  display: flex;
  justify-content: center;
  padding: 0 6px;
  width: 100%;
`;

const Input = styled('input')`
  border: 1px solid #e1e5e6;
  border-radius: 6px;
  font-family: proxima-nova, sans-serif;
  height: 66px;
  padding: 0 24px;
  width: 60%;

  &:focus {
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.24);
    outline: 0;
  }
`;

const Buttons = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 0 0 48px;
  width: 100%;

  button:nth-of-type(2) {
    margin-left: 12px;
  }
`;

export const combineAdvisorsAndOpticians = ({ opticians, sales_advisors }) => {
  // opticians can also have sales advisors roles so for a
  // position that has category of both optician and shopping,
  // combine both the optician and sales advisors list into
  // one deduped list that is sorted alphabetically
  const advisorIds = sales_advisors.map(advisor => advisor.id);
  const dedupedOpticians = opticians.filter(optician => !advisorIds.includes(optician.id));
  const combinedTeam = [...new Set([...sales_advisors, ...dedupedOpticians])];
  return combinedTeam.sort((a, b) => a.full_name.localeCompare(b.full_name));
};

export const formatTeamMemberList = ({
  opticians,
  position,
  sales_advisors,
}) => {
  if ((!sales_advisors.length && !opticians.length) || !position.id) {
    return [];
  }
  return combineAdvisorsAndOpticians({ opticians, sales_advisors });
};

export const setAssigneeIdAndRole = ({
  name,
  opticians,
  sales_advisors,
}) => {
  const combinedTeam = combineAdvisorsAndOpticians({ opticians, sales_advisors });
  const assignee = combinedTeam.find(member => member.full_name === name);

  if (!assignee) {
    return { assigneeId: null, assigneeRole: null };
  }

  const assigneeRole = 'optician_assigned';
  const assigneeId = assignee.id;

  return { assigneeId, assigneeRole };
};

const AddAssigneeModal = ({
  assigning,
  dispatch,
  error,
  facility_team: { sales_advisors, opticians },
  loading,
  me,
  positions,
}) => {
  const history = useHistory();

  const formattedSalesAdvisors = useMemo(
    () => sales_advisors.map(formatTeamMemberName), [sales_advisors],
  );

  const formattedOpticians = useMemo(() => opticians.map(formatTeamMemberName), [opticians]);

  const [assigneeData, setAssigneeData] = useState({
    assigneeId: null,
    assigneeRole: null,
    cancelling: false,
    error: null,
  });

  const [position, setPosition] = useState({});

  useEffect(() => {
    if (!positions.length && !loading) {
      // on page refresh, it's possible to lose the store
      // waitlist positions in the Redux store so fetch
      // the list again in the event that happens
      dispatch({ type: FETCH_WAITLIST });
    }

    if (!position.id) {
      const { pathname } = window.location;
      const { positionId } = matchPath(pathname, '*add-assignee/:positionId').params;
      const positionMatch = positions.find(pos => pos.id === positionId) || {};
      setPosition(positionMatch);
    }
  }, [positions]);

  useEffect(() => {
    dispatch(dispatchFetchFacilityDisplayAddress());
  }, []);

  const renderTeamMemberList = () => {
    const teamMemberList = formatTeamMemberList({
      opticians: formattedOpticians,
      position,
      sales_advisors: formattedSalesAdvisors,
    });

    if (!teamMemberList.length) {
      const emptyListText = "Oh no! There doesn't seem to be any team members at this facility.";
      return (
        <Subtext>
          {emptyListText}
          <br />
          Please cancel and try again.
        </Subtext>
      );
    }

    return (
      teamMemberList.map(member => (
        <option key={member.id} value={member.full_name}>{member.full_name}</option>
      ),
      )
    );
  };

  const handleCancel = async () => {
    setAssigneeData({
      ...assigneeData,
      cancelling: true,
    });

    if (error || assigneeData.error) {
      // clear out any stale errors
      await (dispatch({ type: CLEAR_ADD_ASSIGNEE_TO_WAITLIST_ERROR }));
      setAssigneeData({
        ...assigneeData,
        error: null,
      });
    }

    history.push('/');
  };

  const handleSelfAssignment = async () => {
    const { id } = me;
    const assigneeRole = 'optician_assigned';
    const assigneeId = id;
    setAssigneeData({
      ...assigneeData,
      assigneeId,
      assigneeRole,
    });

    if (!assigneeId || !assigneeRole) {
      // if any assignee information is missing, surface error
      // and prevent API call to update position
      const assigneeError = 'There was an error in trying to assign someone to this customer.';
      setAssigneeData({
        ...assigneeData,
        error: assigneeError,
      });

      return;
    }

    await (dispatch({ type: ADD_ASSIGNEE_TO_WAITLIST }));

    dispatch({
      type: EDIT_CUSTOMER_IN_WAITLIST,
      waitlist_position_id: position.id,
      payload: {
        assignee: {
          id: assigneeId,
          role: assigneeRole,
        },
      },
    });
  };

  const handleChange = event => {
    const { value } = event.target;
    // with input and an option list, the event handler fires every time input
    // is changed (i.e., when you type in 1 letter and have not yet selected an
    // option). To only update the assigneeId when an option has been selected,
    // check if the a space exists, implying that a full name has been selected
    if (value.includes(' ')) {
      const { assigneeId, assigneeRole } = setAssigneeIdAndRole({
        name: value,
        opticians: formattedOpticians,
        sales_advisors: formattedSalesAdvisors,
      });
      setAssigneeData({
        ...assigneeData,
        assigneeId,
        assigneeRole,
      });
    }
  };

  const handleSubmit = async () => {
    const { assigneeId, assigneeRole } = assigneeData;

    if (!assigneeId || !assigneeRole) {
      // if any assignee information is missing, surface error
      // and prevent API call to update position
      const assigneeError = 'There was an error in trying to assign someone to this customer.';

      setAssigneeData({
        ...assigneeData,
        error: assigneeError,
      });
    }

    await (dispatch({ type: ADD_ASSIGNEE_TO_WAITLIST }));

    dispatch({
      type: EDIT_CUSTOMER_IN_WAITLIST,
      waitlist_position_id: position.id,
      payload: {
        assignee: {
          id: assigneeId,
          role: assigneeRole,
        },
      },
    });
  };

  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
  const modalHeaderText = (error || assigneeData.error)
    ? 'Oh no!' : 'Assign a team member to this customer!';
  const modalErrorSubtext = (
    error && 'There was an error when trying to assign an employee to this customer.'
  ) || (assigneeData.error);
  const { assigneeId, cancelling } = assigneeData;
  return (
    <Modal hasCloseButton={false}>
      <Container>
        <Content>
          <Header>{modalHeaderText}</Header>
          <Subtext>
            {(error || assigneeData.error) && (
              <>
                {modalErrorSubtext}
                <br />
                Please cancel and try again.
              </>
            )}
          </Subtext>
          <PrimaryButtonContainer>
            <Button loading={assigning} onClick={handleSelfAssignment}>
              Assign to me
            </Button>
          </PrimaryButtonContainer>
          <Divider>— OR —</Divider>
          <Section>
            <Input
              autoComplete="off"
              list="assigneeList"
              name="example"
              onBlur={handleChange}
              onChange={handleChange}
              placeholder="Assign an optician or sales advisor"
              type="text"
            />
          </Section>
          <datalist id="assigneeList">
            {renderTeamMemberList()}
          </datalist>
          <Buttons>
            <Button
              loading={assigning}
              color="secondary"
              disabled={!assigneeId}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              color="destructive"
              onClick={handleCancel}
              loading={cancelling}
            >
              Cancel
            </Button>
          </Buttons>
        </Content>
      </Container>
    </Modal>
  );
};

const select = (state) => ({
  me: state.auth.me.user,
  assigning: state.waitlist.assigning,
  error: state.waitlist.error.add_assignee,
  facility_team: state.waitlist.facility_team,
  positions: state.waitlist.positions,
  loading: state.waitlist.loading,
});

export default connect(select)(AddAssigneeModal);
