import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../styles/colors';

export type BadgeProps = {
  variant: 'primary' | 'secondary';
  children: React.ReactNode;
};

const baseStyles = `
  display: inline-block;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  vertical-align: baseline;
  border-radius: 4px;
`;

const primaryStyles = `
  background-color: ${colors.lightGreen};
  color: ${colors.green};
`;

const secondaryStyles = `
  background-color: ${colors.silver};
  color: ${colors.stone};
`;

const StyledBadge = styled.span<BadgeProps>`
  ${baseStyles}
  ${(props) => (props.variant === 'primary' ? primaryStyles : secondaryStyles)}
`;

export default StyledBadge;
