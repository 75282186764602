import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import {
  CLEAR_SEND_RETURN_SMS_ERROR,
  DISABLE_AUTO_REFRESH,
  ENABLE_AUTO_REFRESH,
  SEND_RETURN_SMS,
} from '../../middleware/waitlist';
import CustomerItem from '../customer-item';
import { Container, EmptyListMessage } from './styles';
import ErrorBlock from '../error-block';

interface WaitlistTabProps {
  error: string,
  positions: any,
  sendSMSError: string,
  selectedStatus: string,
  positionsLoading: boolean,
  autoRefreshFeatureEnabled: boolean,
}

const WaitlistTab = ({
  error,
  positions,
  sendSMSError,
  selectedStatus,
  positionsLoading,
  autoRefreshFeatureEnabled,
}: WaitlistTabProps) => {
  const dispatch = useDispatch();
  const [expandedPositionId, setExpandedPositionId] = useState(null);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      dispatch({ type: DISABLE_AUTO_REFRESH });
    } else {
      dispatch({ type: ENABLE_AUTO_REFRESH });
    }
  };

  useEffect(() => {
    if (autoRefreshFeatureEnabled) {
      dispatch({ type: ENABLE_AUTO_REFRESH });
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      if (autoRefreshFeatureEnabled) {
        dispatch({ type: DISABLE_AUTO_REFRESH });
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
    };
  }, []);

  const filteredAndSortedPositions = useMemo(() => positions
    .filter(p => p.status === selectedStatus)
    .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()),
  [positions, selectedStatus]);

  const statusText = useMemo(() => {
    switch (selectedStatus) {
      case 'in_progress':
        return 'No visitors in progress';
      case 'complete':
        return 'No completed visits';
      default:
        return 'No visitors waiting';
    }
  }, [selectedStatus]);

  const handleSMSClick = (id: string) => {
    if (sendSMSError) {
      dispatch({ type: CLEAR_SEND_RETURN_SMS_ERROR });
    }
    dispatch({
      type: SEND_RETURN_SMS,
      payload: { waitlist_position_id: id },
    });
  };

  return (
    <Container>
      {error && <ErrorBlock error={error} />}
      {!positionsLoading && positions.length === 0 ? (
        <EmptyListMessage>{`— ${statusText} —`}</EmptyListMessage>
      ) : (
        filteredAndSortedPositions.map((position) => (
          <CustomerItem
            key={position.id}
            expanded={expandedPositionId === position.id}
            setExpandedPositionId={setExpandedPositionId}
            handleSMSClick={handleSMSClick}
            position={position}
            selectedStatus={selectedStatus}
          />
        ))
      )}
    </Container>
  );
};

export default WaitlistTab;
