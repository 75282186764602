/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useToggle } from 'src/hooks/useToggle';
import Portal from 'src/components/portal';
import { StyledBackdrop, StyledContainer } from './styles';

interface ModalContainerProps extends React.HTMLAttributes<HTMLSpanElement> {
  id: string
}

export interface ModalContainerRef {
  closeModal: () => void
  openModal: () => void
}

export const ModalContainer = React.forwardRef<ModalContainerRef, ModalContainerProps>(
  ({ children, id, ...props }, ref) => {
    const backdropRef = React.useRef<HTMLDivElement>(null);
    const [isOpen, toggleOpen] = useToggle(false);

    const closeModal = React.useCallback(() => {
      toggleOpen(false);
    }, []);

    const openModal = React.useCallback(() => {
      toggleOpen(true);
    }, []);

    React.useImperativeHandle(ref, () => ({
      closeModal,
      openModal,
    }));

    React.useEffect(() => {
      const modalBackdrop = backdropRef.current;

      const listener = () => {
        if (isOpen) closeModal();
      };

      if (!modalBackdrop) return () => undefined;

      modalBackdrop.addEventListener('click', listener);

      return () => {
        modalBackdrop.removeEventListener('click', listener);
      };
    }, []);

    return (
      <Portal id={id} element={document.body}>
        {isOpen && (
        <StyledContainer ref={backdropRef} {...props}>
          <StyledBackdrop>
            {children}
          </StyledBackdrop>
        </StyledContainer>
        )}
      </Portal>
    );
  });
