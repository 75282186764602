import { Frame, FrameSearchResults } from './warby-api-types';

const HOST = `https://${process.env.WARBY_API_DOMAIN}`;

export default {
  async fetchFrames(facets: string[][]) {
    const queryParams = new URLSearchParams(facets);
    return await fetch(`${HOST}/v1/catalog/frames/search?${queryParams}`, {
      headers: {
        'wp-system': 'retail',
      },
    }).then(res => res.json()) as FrameSearchResults;
  },

  async fetchRecommendationFrames(storeFrameId: string) {
    return await fetch(`${HOST}/v1/recommendations/similarshape?storeFrameId=${storeFrameId}`, {
      headers: {
        'wp-system': 'retail',
      },
    }).then(res => res.json()) as FrameSearchResults;
  },

  async getFrame(frameId: string) {
    return await fetch(`${HOST}/v1/catalog/frames/${frameId}`, {
      headers: {
        'wp-system': 'retail',
      },
    })
      .then(res => res.json()) as Frame[];
  },

  async fetchFramesByPcProductIds(favorites: string[][]) {
    const queryParams = new URLSearchParams(favorites);
    return await fetch(`${HOST}/v1/catalog/frames/bypcproductids?${queryParams}`, {
      headers: {
        'wp-system': 'retail',
      },
    })
      .then(res => res.json()) as Frame[];
  },
};
