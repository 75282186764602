/* eslint-disable no-undef */
import { put, takeLatest } from 'redux-saga/effects';

import RetailService from 'src/service-clients/helios-retail';

export const AUTH_REQUEST = 'AUTH_REQUEST';
const AUTH_FAILURE = 'AUTH_FAILURE';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const TOKEN_SUCCESS = 'TOKEN_SUCCESS';

const initialState = {
  token: null,
  error: null,
  me: null,
};

export const setUser = (me) => ({
  type: AUTH_SUCCESS,
  payload: me,
});

export function* fetchTokenOrRedirect() {
  let token;
  try {
    token = yield RetailService.getUserJwt();
  } catch (err) {
    logger.error({ err }, 'Auth Failure');
    yield put({ type: AUTH_FAILURE, payload: err.message });
    return;
  }

  if (token) {
    yield put({ type: TOKEN_SUCCESS, payload: token });
    if (window.WP && window.WP.POEBridge && window.WP.POEBridge.onAuthTokenFetched) {
      window.WP.POEBridge.onAuthTokenFetched(token);
    } else if (window.IS_HERMES) {
      logger.info({}, 'No POE bridge to send auth token');
    }
    return;
  }

  // Redirect to login if we reach this point
  const url = new window.URL(`https://${process.env.HELIOS_RETAIL_DOMAIN}/login`);
  const params = new window.URLSearchParams();
  params.append('next', window.location.href);
  url.search = String(params);
  window.location.replace(String(url));
}

function* fetchMe({ payload: token }) {
  let me;
  try {
    me = yield RetailService.getUserMe({ token });
  } catch (err) {
    logger.error({ err }, 'Auth Failure');
    yield put({ type: AUTH_FAILURE, payload: err.message });
    return;
  }
  yield put(setUser(me));
  if (window.WP && window.WP.POEBridge && window.WP.POEBridge.currentUserUpdate) {
    window.WP.POEBridge.currentUserUpdate(me);
  } else if (window.IS_HERMES) {
    logger.info({}, 'No POE bridge to send current user info');
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOKEN_SUCCESS:
      return { ...state, token: payload, error: null };
    case AUTH_FAILURE:
      return { ...state, token: null, error: payload };
    case AUTH_SUCCESS:
      return { ...state, me: payload };
    default:
      return state;
  }
};

function* saga() {
  yield takeLatest(AUTH_REQUEST, fetchTokenOrRedirect);
  yield takeLatest(TOKEN_SUCCESS, fetchMe);
}

export default {
  reducer,
  saga,
};
