import React from 'react';
import { findNextEarliestDate } from 'src/utils/formatDate';
import { APPOINTMENT_TYPES_NAMES, Availability } from 'src/constants';
import Badge from '../../../shared-components/badge';
import { CardTileContainer, LabelBold, NextAvailableSection } from './styles';
import { AvailabilityMap } from '../../../types/appointments';

export const CardTile = ({ data, isActive, facility: { short_name } }) => {
  const availabilityMap: AvailabilityMap = {
    [Availability.Today]: 'primary',
    [Availability.Tomorrow]: 'secondary',
  };
  const nextAvailable = findNextEarliestDate(
    data.appointments[short_name]
      ? data.appointments[short_name].perDate : {},
  );

  return (
    <CardTileContainer active={isActive}>
      <LabelBold>{APPOINTMENT_TYPES_NAMES[data.serviceName]}</LabelBold>
      <NextAvailableSection>
        <span>Next Available: </span>
        <Badge variant={availabilityMap[nextAvailable]}>{nextAvailable}</Badge>
      </NextAvailableSection>
    </CardTileContainer>
  );
};
