import styled from '@emotion/styled';
import { colors } from '../../../styles/colors';

export const Page = styled('div')`
  height: 100vh;
  overflow-y: scroll;
  background: ${colors.silk};
  -webkit-overflow-scrolling: touch;
`;

export const Content = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 264px); // header + tabs + footer = 78 + 106 + 80
`;
