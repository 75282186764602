import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';

import {
  EDIT_CUSTOMER_IN_WAITLIST,
  FETCH_WAITLIST,
  FETCH_WAITLIST_LOG_ITEMS,
  CLEAR_CUSTOMER_WAITLIST_ERROR,
  CLEAR_POSITION_LOGS_WAITLIST_ERROR,
  CLEAR_POSITIONS_WAITLIST_ERROR,
  CLEAR_POSTED_FLAG,
} from 'src/middleware/waitlist/index';

import { getDefaultCategories } from './customer-form-default-categories';

import { CustomerFormContainer, formatTelephoneInput } from './customer-form';

// This method takes in all subcategories and
// formats them to only include the key and active
// status in the API request. False active statuses
// must be included in the event that an existing
// customer has a subcategory set to true but it
// is updated to false in the form
export const formatCategories = (category) => {
  if (!category) return false;

  // filter out only active subcategories
  const consolidatedCat = category.reduce((obj, subcat) => ({
    ...obj,
    [subcat.key]: subcat.active,
  }), {});

  // if no subcategories are active, set category to false by default
  return Object.entries(consolidatedCat).length > 0 ? consolidatedCat : false;
};

// This method takes in a category and returns
// the subcategories reformatted to follow the
// structure of default categories. If none of
// the subcategories have been selected, return
// false by default
export const formatSubcategories = (name, category) => {
  const defaultCategories = getDefaultCategories();
  const subcategories = defaultCategories[name];

  if (!category) {
    return subcategories;
  }

  const updatedSubcategories = subcategories.map(subcat => {
    const { key } = subcat;
    // if the key exists in the category that has been
    // updated and the active status is the opposite of
    // what it is originally set to in default category,
    // reset the active status
    subcat.active = !!category[key]; // eslint-disable-line no-param-reassign

    return subcat; // eslint-disable-line consistent-return
  });

  return updatedSubcategories;
};

// This method returns the input categories, with defaults
// filled in for categories not included in the input.
export const applyDefaultCategories = (categories) => {
  if (!categories) return getDefaultCategories();

  const { optician, shopping } = categories;
  const formattedOpticianCat = formatSubcategories('optician', optician);
  const formattedShoppingCat = formatSubcategories('shopping', shopping);
  const formattedCategories = {
    optician: formattedOpticianCat,
    shopping: formattedShoppingCat,
  };

  return formattedCategories;
};

const EditCustomerPage = ({ dispatch, error, posted, posting, positions }) => {
  const history = useHistory();
  const { pathname } = window.location;
  const { positionId } = matchPath(pathname, '*edit-customer/:positionId').params;

  // Look for the position to be edited in the waitlist.
  // If it isn't there, fetch the waitlist
  const position = positions.find(p => p.id === positionId);
  useEffect(() => {
    dispatch({ type: FETCH_WAITLIST_LOG_ITEMS, positionId });
    if (!position) {
      dispatch({ type: FETCH_WAITLIST });
    }
    dispatch({ type: CLEAR_POSTED_FLAG });
  }, []);

  const [formData, updateFormData] = useState({
    name: '',
    notes: '',
    telephone: '',
    partySize: 1,
    customerId: null,
    categories: applyDefaultCategories(),
  });

  // Update the form data no more than once when the position is found
  const [positionFound, updatePositionFound] = useState(false);
  if (position && !positionFound) {
    updateFormData({
      customerId: position.customer_id || null,
      name: position.name || '',
      notes: position.notes || '',
      partySize: position.party_size || '',
      telephone: formatTelephoneInput(position.telephone) || '',
      categories: applyDefaultCategories(position.categories),
    });
    updatePositionFound(true);
  }

  // If there are positions in the waitlist, but the particular position id
  // is not in the waitlist, display an error.
  // (The most possible case of this is if the edit page is refreshed after
  // another user deleted the particular position.)
  let positionError = null;
  if (positions.length && !position) {
    positionError = 'Position not found';
  }

  const handleCancel = async () => {
    if (error) {
      // clear out any stale errors
      await (dispatch({ type: CLEAR_CUSTOMER_WAITLIST_ERROR }));
      await (dispatch({ type: CLEAR_POSITIONS_WAITLIST_ERROR }));
      await (dispatch({ type: CLEAR_POSITION_LOGS_WAITLIST_ERROR }));
    }
    history.push('/');
  };

  const handleSubmit = () => {
    if (!position) return;
    const { name, telephone, notes, partySize, categories, customerId } = formData;
    const {
      name: positionName = '',
      telephone: positionTelephone = '',
      notes: positionNotes = '',
      party_size: positionPartySize = 1,
      customer_id: positionCustomerId,
    } = position;

    // Consider null values on the existing position to be empty strings.
    // This helps when we decide which values were updated.
    const existingName = positionName === null ? '' : positionName;
    const existingTelephone = positionTelephone === null ? '' : positionTelephone;
    const existingNotes = positionNotes === null ? '' : positionNotes;
    const existingPartySize = positionPartySize === null ? 1 : positionPartySize;

    // strip all non-numeric characters and symbols
    const numericTelephone = telephone.replace(/[^0-9]/g, '');
    const existingNumericTelephone = existingTelephone.replace(/[^0-9]/g, '');

    // Craft a payload only of fields that were modified
    const payload = {};
    if (name !== existingName) payload.name = name;
    if (notes !== existingNotes) payload.notes = notes;
    if (partySize !== existingPartySize) payload.party_size = partySize;
    if (numericTelephone !== existingNumericTelephone) payload.telephone = numericTelephone;
    if (customerId !== positionCustomerId) payload.customer_id = customerId;

    // Add categories to the payload
    const { optician, shopping } = categories;
    payload.categories = {
      optician: formatCategories(optician),
      shopping: formatCategories(shopping),
    };

    dispatch({
      type: EDIT_CUSTOMER_IN_WAITLIST,
      waitlist_position_id: positionId,
      payload,
    });
  };

  const loading = posting || (!posting && posted);

  // If there are no positions in the waitlist, attempting to
  // edit a position is futile, so prevent it
  const disableInput = !positions.length || !!positionError;

  return (
    <CustomerFormContainer
      formHeader="Edit visitor"
      formData={formData}
      dispatch={dispatch}
      updateFormData={updateFormData}
      error={error || positionError}
      disableInput={disableInput}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      loading={loading}
      positions={positions}
      isEditPage
      sectionHeader="Edit someone new"
      positionId={positionId}
    />
  );
};

const select = (state) => {
  const errors = state.waitlist.error;
  const error = errors.customer || errors.positionLogs || errors.positions;

  return {
    error,
    posted: state.waitlist.posted,
    posting: state.waitlist.posting,
    positions: state.waitlist.positions,
  };
};

export default connect(select)(EditCustomerPage);
