// eslint-disable-next-line max-classes-per-file
import { RequestMethod } from '../helios-retail-types';

class HttpRequest {
  public token: string;

  public method: RequestMethod | undefined;

  public endpoint: string;

  public headers: { [key: string]: string };

  public body?: any;

  constructor() {
    this.token = '';
    this.method = undefined;
    this.endpoint = '';
    this.headers = {};
    this.body = undefined;
  }
}

interface HttpRequestBuilder {
  setAuthorization(token: string): void;
  setMethod(method: RequestMethod): void;
  setEndpoint(endpoint: string): void;
  setBody<T>(body: T): void;
  configureRequest(): Request;
}

export class HeliosHttpRequestBuilder implements HttpRequestBuilder {
  private httpRequest: HttpRequest;

  constructor() {
    this.httpRequest = new HttpRequest();
  }

  setAuthorization(token: string) {
    this.httpRequest.token = token;
  }

  setMethod(method: RequestMethod) {
    this.httpRequest.method = method;
  }

  setBody<T>(body: T): void {
    this.httpRequest.body = body;
  }

  setEndpoint(endpoint: string): void {
    this.httpRequest.endpoint = endpoint;
  }

  configureRequest(): Request {
    return new Request(this.httpRequest.endpoint, {
      method: this.httpRequest.method,
      headers: {
        Authorization: `bearer ${this.httpRequest.token}`,
      },
      body: this.httpRequest.body,
      credentials: 'include',
    });
  }
}
