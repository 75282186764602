import React from 'react';
import ReactDOM from 'react-dom';
import { initNewRelic } from 'src/new-relic';
import { injectLoggerIntoGlobal } from 'src/providers/logger/injectLoggerIntoGlobal';
import App from './src/app';

initNewRelic();
injectLoggerIntoGlobal();

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
