import { Auth } from '../types/auth';

type RelevantState = {
  auth?: Auth;
};

// Ideally we just use `useSelector`, but we can't since our redux packages are so outdated
// Updating redux-related packages is a massive effort however
const toCheckFeature = (state: RelevantState) => (flag: string) => {
  const {
    auth: {
      me: {
        features = [],
      } = {},
    } = {},
  } = state;

  const userHasFeature = features.includes(flag);
  return userHasFeature;
};

export type CheckFeature = (flag: string) => boolean;

export default toCheckFeature;
