import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CMS_PAGES, EYE_EXAM, INITIAL_PAGE, PAGE_SIZE, WAITLIST } from 'src/constants';
import {
  dispatchFetchFacilityDisplayAddress,
  FETCH_WAITLIST,
  FETCH_WAITLIST_FACILITY_TEAM,
  SET_SELECTED_STATUS,
} from 'src/middleware/waitlist';
import useSnowplowContext from 'src/hooks/useSnowplowContext';
import { fetchAppointmentsList, fetchFacilityEyeExamServices } from 'src/middleware/eye-exams';
import { withModal } from 'src/modal';
import {
  AUTO_REFRESH_FEATURE_FLAG,
  CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG,
  CUSTOMER_QR_CODES,
  NATIVE_EYE_EXAM_BOOKING_FEATURE_FLAG,
} from 'feature-flags';
import toCheckFeature from 'src/connect-helpers/toCheckFeature';
import { useToast } from 'src/contexts/toast';
import AddAssigneeModal from '../../add-assignee-modal';
import HeaderNav from '../../header-nav';
import RemoveCustomerModal from '../../remove-customer-modal';
import StatusTabs from '../../status-tabs';
import OverflowPage, { OverflowPageRef } from '../../overflow-page';
import Footer from '../components/footer';
import WaitlistTab from '../../waitlist-tab';
import EyeExamsTab from '../../eye-exams-tab/eye-exams-tab';
import { Content, Page } from './styles';
import { filterAppointmentsByTime } from '../../../utils/customerList';
import { AppointmentsState, SortItem } from '../../../middleware/eye-exams/types';
import { Facility, User } from '../../../types/auth';
import { FacilityEyeExamServices } from '../../../middleware/eye-exams/eye-exam-types';
import { FacilityDisplayAddress, FacilityTeam } from '../../../middleware/waitlist/types';
import ConfirmAppointmentModalContent
  from '../../appointments/confirm-appointment-modal/confirm-appointment-modal-page';
import { Appointment } from '../../../service-clients/pmp-api-types';
import { logger } from '../../../providers/logger';
import { requestNativeEyeExamBooking } from '../../../middleware/eye-exams/actions';

interface WaitlistProps {
  sortItem: SortItem,
  appointments: AppointmentsState,
  isLoadingPositions: boolean,
  isLoadingAppointments: boolean,
  facility: Facility,
  facilityDisplayAddress: FacilityDisplayAddress,
  facilityDisplayAddressError: string,
  facilityEyeExamServices: FacilityEyeExamServices,
  facility_team: FacilityTeam,
  modal: any,
  positions: any,
  selectedStatus: string,
  sendSMSError: string,
  updateStatusError: string,
  user: User,
  startShoppingError: string,
  enableQRCodeScan: boolean,
  autoRefreshFeatureEnabled: boolean,
  confirmEyeExamFeatureEnabled: boolean,
  canRequestNativeEyeExamBooking:boolean,
}

const Waitlist = ({
  sortItem,
  appointments,
  isLoadingPositions,
  isLoadingAppointments,
  facility,
  facilityDisplayAddress,
  facilityDisplayAddressError,
  facilityEyeExamServices,
  facility_team,
  modal,
  positions,
  selectedStatus,
  sendSMSError,
  updateStatusError,
  user,
  startShoppingError,
  enableQRCodeScan,
  autoRefreshFeatureEnabled,
  confirmEyeExamFeatureEnabled,
  canRequestNativeEyeExamBooking,
}: WaitlistProps) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { eventTracker } = useSnowplowContext();

  const [appointmentsCount, setAppointmentsCount] = useState(0);

  const isRemoveModal = window.location.pathname.includes('remove-customer');
  const isAddAssigneeModal = window.location.pathname.includes('add-assignee');
  const contentRef = useRef<HTMLDivElement>(null);
  const overflowPageRef = useRef<OverflowPageRef>(null);

  const parsedQuery = queryString.parse(window.location.search);
  const redirect = parsedQuery.next;
  const { redirectToExams } = parsedQuery;

  const error = useMemo(() => sendSMSError || updateStatusError || startShoppingError,
    [sendSMSError, updateStatusError, startShoppingError]);

  const canAccessExamsTab = useMemo(() => (
    facilityEyeExamServices
      && facilityEyeExamServices?.attributes?.eyeExamProviderType
        !== EYE_EXAM.PROVIDER_TYPE.NO_EXAMS
  ), [facilityEyeExamServices]);

  const isWaitlistTab = useMemo(() => [
    WAITLIST.STATUS.WAITING,
    WAITLIST.STATUS.IN_PROGRESS,
    WAITLIST.STATUS.COMPLETE,
  ].includes(selectedStatus), [selectedStatus]);

  const openBookEyeExam = useCallback(() => {
    eventTracker?.trackNewExamBookingEvent({
      advisorId: user.id,
      createdAt: new Date(),
      defaultFacilityId: facility?.id,
    });

    if (!overflowPageRef.current) return;

    addToast({
      title: 'Redirected to Booking Exam Page!',
    });

    overflowPageRef.current.openPage();
  }, [eventTracker, user.id, facility?.id, overflowPageRef]);

  const handleSelectStatus = useCallback(status => {
    dispatch({
      type: SET_SELECTED_STATUS,
      payload: { selected_status: status },
    });
  }, []);

  useEffect(() => {
    if (canAccessExamsTab) {
      dispatch(fetchAppointmentsList({
        page: INITIAL_PAGE,
        pageSize: PAGE_SIZE,
      }));
    }
  }, [sortItem, canAccessExamsTab]);

  useEffect(() => {
    if (!facilityDisplayAddress) {
      dispatch(dispatchFetchFacilityDisplayAddress());
    }

    if (!facilityEyeExamServices) {
      dispatch(fetchFacilityEyeExamServices());
    }

    if (!isRemoveModal && !isAddAssigneeModal) {
      dispatch({ type: FETCH_WAITLIST });
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { opticians, sales_advisors } = facility_team;
    if (isAddAssigneeModal && (!sales_advisors.length && !opticians.length)) {
      dispatch({ type: FETCH_WAITLIST_FACILITY_TEAM });
    }
  }, []);

  const filteredAppointments = useMemo<Appointment[]>(() =>
    filterAppointmentsByTime(appointments.data), [appointments.data]);

  const handleFilterChange = (showFilteredAppointments: boolean) => {
    if (showFilteredAppointments) {
      setAppointmentsCount(filteredAppointments.length);
    } else {
      setAppointmentsCount(appointments.data.length);
    }
  };

  // Remove hook, filteredAppointments, handleFilterChange and appointmentCount state
  // after moving filtering logic to the backend side.
  // After we can just show length of appointments.data
  useEffect(() => {
    setAppointmentsCount(filteredAppointments.length);
  }, [appointments.data.length]);

  useEffect(() => {
    if (canAccessExamsTab && redirectToExams) {
      handleSelectStatus(WAITLIST.STATUS.EYE_EXAMS);
    }
  }, [canAccessExamsTab]);

  useEffect(() => {
    if (error && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [error]);

  const Modal = modal || (() => null);

  const bookEyeExamURL = useMemo(() => {
    const baseUrl = `${process.env.WARBY_BOOKING_URL}/${CMS_PAGES.EYE_EXAM_BOOKING}`;
    const canBookEyeExam = facilityEyeExamServices
      && facilityEyeExamServices?.attributes?.eyeExamProviderType
      !== EYE_EXAM.PROVIDER_TYPE.NO_EXAMS;

    if (
      facilityDisplayAddressError
      || !facilityDisplayAddress
      || !canBookEyeExam) {
      return `${baseUrl}`;
    }

    const {
      city_slug: citySlug,
      location_slug: locationSlug,
    } = facilityDisplayAddress;

    return `${baseUrl}/${citySlug}/${locationSlug}`;
  }, [facilityDisplayAddress, facilityDisplayAddressError, facilityEyeExamServices]);

  const handleRefresh = () => {
    dispatch({ type: FETCH_WAITLIST });
    dispatch(fetchAppointmentsList({
      page: appointments.page || INITIAL_PAGE,
      pageSize: PAGE_SIZE,
    }));
  };

  const openNativeEyeExam = useCallback(() => {
    dispatch(requestNativeEyeExamBooking());

    if (!window.WP.POEBridge.onEyeExamBookingCompleted) {
      logger.error({}, 'POE Bridge does not have required handlers: onEyeExamBookingCompleted');
      return;
    }

    window.WP.POEBridge.onEyeExamBookingCompleted = () => {
      handleRefresh();
    };
  }, []);

  return (
    <>
      <Page>
        <HeaderNav
          loading={isLoadingPositions || isLoadingAppointments}
          facilityName={facility.name}
          onRefresh={handleRefresh}
          positions={positions}
          showRefresh
          backUrl={redirect}
        />
        <StatusTabs
          appointmentsCount={appointmentsCount}
          canAccessExamsTab={canAccessExamsTab}
          positions={positions}
          selectedStatus={selectedStatus}
          handleSelectStatus={handleSelectStatus}
        />
        <Modal />
        <Content ref={contentRef}>
          {
            isWaitlistTab
              ? (
                <WaitlistTab
                  error={error}
                  positions={positions}
                  sendSMSError={sendSMSError}
                  selectedStatus={selectedStatus}
                  positionsLoading={isLoadingPositions}
                  autoRefreshFeatureEnabled={autoRefreshFeatureEnabled}
                />
              )
              : (
                <EyeExamsTab
                  error={error}
                  sortItem={sortItem}
                  appointments={appointments}
                  isAppointmentsLoading={isLoadingAppointments}
                  confirmEyeExamFeatureEnabled={confirmEyeExamFeatureEnabled}
                  onFilterChange={handleFilterChange}
                />
              )
          }
        </Content>
        <Footer
          enableQRCodeScan={enableQRCodeScan}
          openBookEyeExam={canRequestNativeEyeExamBooking ? openNativeEyeExam : openBookEyeExam}
        />
      </Page>
      {!facilityDisplayAddressError && (
      <OverflowPage
        title="Eye Exam Booking"
        src={bookEyeExamURL}
        ref={overflowPageRef}
      />
      )}
    </>
  );
};

const select = (state) => ({
  sortItem: state.eyeExams.appointments.sortItem,
  user: state.auth.me.user,
  facility: state.auth.me.facility,
  facilityDisplayAddress: state.waitlist.facility_display_address,
  facilityDisplayAddressError: state.waitlist.error.facility_display_address,
  facilityEyeExamServices: state.eyeExams.facility,
  positions: state.waitlist.positions,
  appointments: state.eyeExams.appointments,
  isLoadingPositions: state.waitlist.loading,
  isLoadingAppointments: state.eyeExams.loading.appointments,
  selectedStatus: state.waitlist.selected_status,
  sendSMSError: state.waitlist.error.send_sms,
  updateStatusError: state.waitlist.error.update_status,
  startShoppingError: state.waitlist.error.start_shopping,
  facility_team: state.waitlist.facility_team,
  enableQRCodeScan: !!window.IS_HERMES && toCheckFeature(state)(CUSTOMER_QR_CODES),
  autoRefreshFeatureEnabled: toCheckFeature(state)(AUTO_REFRESH_FEATURE_FLAG),
  confirmEyeExamFeatureEnabled: toCheckFeature(state)(CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG),
  canRequestNativeEyeExamBooking:
      toCheckFeature(state)(NATIVE_EYE_EXAM_BOOKING_FEATURE_FLAG) && !!window.IS_HERMES,
});

const WaitlistPage = connect(select)(Waitlist);
export default WaitlistPage;

export const AddAssigneeModalPage = withModal(WaitlistPage, AddAssigneeModal);
export const RemoveCustomerModalPage = withModal(WaitlistPage, RemoveCustomerModal);
export const ConfirmAppointmentModalPage = withModal(WaitlistPage, ConfirmAppointmentModalContent);
