import { useCallback, useState } from 'react';

type ToggleFn = (newValue?: boolean) => void;

interface UseToggleFn {
  (initialState?: boolean): [value: boolean, toggleValue: ToggleFn]
}

export const useToggle: UseToggleFn = (initialState = false) => {
  const [value, setValue] = useState(initialState);

  const toggleValue = useCallback((newValue?: boolean) => {
    setValue(oldValue => Boolean(newValue !== undefined ? newValue : !oldValue));
  }, []);

  return [value, toggleValue];
};
