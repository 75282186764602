import styled from '@emotion/styled';
import React from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { setView } from 'src/middleware/frame-style-assistant';
import { requestCustomerFavorites } from 'src/middleware/favorites';

import Filters from './filters';
import FrameStyleAssistant from './pages/frame-style-assistant';
import Favorites from './pages/favorites';
import Photobooth from './pages/photobooth';
import { View } from './constants';
import { colors } from '../../styles/colors';
import { FrameStyleAssistantHeader } from './frame-style-assistant-header';
import { useAppSelector } from '../../hooks';

const Page = styled.div`
  background: ${colors.silk};
  height: 100%;
  left: 0;
  min-height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
`;

const Content = styled.div`
  scroll-behavior: smooth;
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 60px);
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  box-sizing: border-box;
  box-shadow: 0px -8px 11px rgba(0, 0, 0, 0.12);
  border-radius: 16px 16px 0px 0px;
`;

const FrameStyleAssistantPage = () => {
  const parsedQuery = queryString.parse(window.location.search);
  const dispatch = useDispatch();

  const currentView = useAppSelector(state => state.frameStyleAssistant.view);
  const currentKind = useAppSelector(state => state.frameStyleAssistant.facets.kind?.value);

  const toggleView = (selected: View) => {
    if (currentView === selected) dispatch(setView(View.StyleAssistant));
    else dispatch(setView(selected));
  };

  // fetch the favorites, if customerId is passed. we only need to do this once
  const { customerId } = parsedQuery;
  if (customerId && (useAppSelector(state => state.favorites.favoritesIds) === undefined)) {
    dispatch(requestCustomerFavorites(String(customerId)));
  }

  return (
    <Page>
      <FrameStyleAssistantHeader
        view={currentView}
        toggleView={toggleView}
        kind={currentKind?.toLowerCase() || currentView}
      />
      <Content>
        {currentView === View.StyleAssistant && <FrameStyleAssistant />}
        {currentView === View.Favorites && <Favorites />}
        {currentView === View.Photobooth && <Photobooth />}
      </Content>
      <Footer>
        <Filters />
      </Footer>
    </Page>
  );
};

export default FrameStyleAssistantPage;
