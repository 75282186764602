import { FilterStrategy, SortOrder } from '../../constants';
import { GetAppointmentsQueryParams } from '../../middleware/eye-exams/types';

enum FilterConditions {
  GreaterThanOrEqual = 'gteq',
  LessThan = 'lt',
  Include = 'incl',
  NotIn = 'notin',
  In = 'in',
}

const getSortParam = (sort: { [key: string]: SortOrder }): string => {
  const sortQuery = Object.entries(sort).reduce((queryString: string[], [key, value]) => {
    queryString.push(`sort=${key}:${value}`);
    return queryString;
  }, []);

  return sortQuery.join('&');
};

const getTimeRangeParam = (
  timeRange: { from: { by: string, filter: string }, to: { by: string, filter: string } },
): string => {
  const { from, to } = timeRange;

  const hasFromFilter = from.by && from.filter;
  const hasToFilter = to.by && to.filter;

  const fromQuery = hasFromFilter ? `where=${from.by}:${FilterConditions.GreaterThanOrEqual}:${from.filter}` : '';
  const toQuery = hasToFilter ? `where=${to.by}:${FilterConditions.LessThan}:${to.filter}` : '';

  return [fromQuery, toQuery].filter((query) => query).join('&');
};

const getConfigurationsParam = (
  configurations: { [key in FilterStrategy]: string[] },
  strategy: (criteria: string, configurations: string[]) => string): string => {
  const configurationQuery = Object.entries(configurations).reduce((
    queryString: string[],
    [key, value],
  ) => {
    queryString.push(strategy(key, value));
    return queryString;
  }, []);

  return configurationQuery.filter((query) => query)[0];
};

const getInclusiveConfigurationsStrategy = (
  criteria: string,
  configurations: string[],
): string => {
  if (criteria !== FilterStrategy.Include || !configurations.length) return '';

  return `where=config.options:${FilterConditions.Include}:${configurations.join(',')}`;
};

const getExclusiveConfigurationsStrategy = (
  criteria: string,
  configurations: string[],
): string => {
  if (criteria !== FilterStrategy.Exclude || !configurations.length) return '';

  return `where=config.options:${FilterConditions.NotIn}:${configurations.join(',')}`;
};

const getStatusParam = (status: string[]): string => {
  if (!status || !status.length) return '';

  return `where=status:${FilterConditions.In}:${status.join(',')}`;
};

const getFacilityShortNameParam = (facilityShortName: string): string => {
  if (!facilityShortName) return '';

  return `facility_short_name=${facilityShortName}`;
};

const getExcludedAppointments = (appointments: string[]): string => {
  if (!appointments || !appointments.length) return '';

  return `id_not_in:${(appointments).join(',')}`;
};

const parseAppointmentQueryToQueryParams = (query: GetAppointmentsQueryParams) => {
  const { facilityShortName } = query;
  const { sort, timeRange, configurations, status, excludeAppointmentsById } = query.where;
  const sorting = getSortParam(sort);
  const timeInterval = getTimeRangeParam(timeRange);
  const statusFilter = getStatusParam(status);
  const excludedAppointments = getExcludedAppointments(excludeAppointmentsById);
  const includeConfigurations = getConfigurationsParam(
    configurations,
    getInclusiveConfigurationsStrategy,
  );
  const excludeConfigurations = getConfigurationsParam(
    configurations,
    getExclusiveConfigurationsStrategy,
  );

  return [
    getFacilityShortNameParam(facilityShortName),
    excludedAppointments,
    includeConfigurations,
    excludeConfigurations,
    statusFilter,
    sorting,
    timeInterval,
  ].filter((param) => param).join('&');
};

export default parseAppointmentQueryToQueryParams;
