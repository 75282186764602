import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
// @ts-ignore
import { colors } from '../../../styles/colors';

const Page = styled.div`
  height: 100%;
  line-height: 0;
`;

const Video = styled.video`
  width: 50vw;
`;

const Content = styled.div`
  padding-top: 78px;
`;

const ErrorContainer = styled.div`
  padding-top: 78px;
  line-height: 36px;
  color: ${colors.red};
  text-align: center;
`;

/**
 * Pause the video when it is clicked or tapped.
 * This gives the illusion of taking a picture.
 */
const togglePause = (videoElement: HTMLVideoElement) => {
  if (videoElement.paused) {
    videoElement.play();
  } else {
    videoElement.pause();
  }
};

// This component displays four video elements with their source
// set as the device's camera. We don't actually save a photo, but
// tapping the video pauses it, so it seems as though we take a photo.
const Photobooth = () => {
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<string | null>(null);
  const video1 = useRef<HTMLVideoElement>(null);
  const video2 = useRef<HTMLVideoElement>(null);
  const video3 = useRef<HTMLVideoElement>(null);
  const video4 = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>();

  // inspired by
  // https://www.timveletta.com/blog/2020-07-14-accessing-react-state-in-your-component-cleanup-with-hooks/
  useEffect(() => {
    streamRef.current = stream;
  }, [stream]);

  // Get a media stream from the device's camera
  useEffect(() => {
    const getStream = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
          audio: false,
        });
        setStream(mediaStream);
      } catch (err: any) {
        setError(err.message);
      }
    };
    getStream();

    // Stop the media stream when this component unmounts
    return () => {
      if (streamRef.current) streamRef.current.getTracks().forEach(track => track.stop());
    };
  }, []);

  // When each Video element becomes available, set its source
  useEffect(() => {
    if (stream && video1 && video1.current) {
      video1.current.srcObject = stream;
      video1.current.play();
    }
  }, [stream, video1]);

  useEffect(() => {
    if (stream && video2 && video2.current) {
      video2.current.srcObject = stream;
      video2.current.play();
    }
  }, [stream, video2]);

  useEffect(() => {
    if (stream && video3 && video3.current) {
      video3.current.srcObject = stream;
      video3.current.play();
    }
  }, [stream, video3]);

  useEffect(() => {
    if (stream && video4 && video4.current) {
      video4.current.srcObject = stream;
      video4.current.play();
    }
  }, [stream, video4]);

  return (
    <Page>
      {error && (
        <ErrorContainer>
          <p>
            Please make sure you allow the app to access the camera!
          </p>
          <p>{error}</p>
        </ErrorContainer>
      )}
      <Content>
        <Video
          ref={video1}
          onClick={({ target }) => togglePause(target as HTMLVideoElement)}
        />
        <Video
          ref={video2}
          onClick={({ target }) => togglePause(target as HTMLVideoElement)}
        />
        <Video
          ref={video3}
          onClick={({ target }) => togglePause(target as HTMLVideoElement)}
        />
        <Video
          ref={video4}
          onClick={({ target }) => togglePause(target as HTMLVideoElement)}
        />
      </Content>
    </Page>
  );
};

export default Photobooth;
