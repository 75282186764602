import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

interface ContainerProps {
  type?: 'info' | 'success' | 'error'
  hasDescription: number
  time: number;
}

const toastFadeIn = keyframes`
  from {
    right: -120%;
    opacity: 0;
  }

  to {
    right: 0%;
    opacity: 1;
  }
`;

const toastFadeOut = keyframes`
  from {
    right: 0%;
    opacity: 1;
  }

  to {
    right: -120%;
    opacity: 0
  }
`;

const toastTypeVariations = {
  info: css`
    background: #EBF9FF;
    color: #0089BF;
  `,
  success: css`
    background: #e6fffa;
    color: #2e656a;
  `,
  error: css`
    background: #fddede;
    color: #c53030;
  `,
};

export const Container = styled.div<ContainerProps>`
  width: 360px;

  position: relative;
  padding: 16px 16px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  display: flex;

  animation: ${toastFadeIn} 0.5s, ${toastFadeOut} 1s ${props => props.time}s;

  & + div {
    margin-top: 8px;
  }

  ${props => toastTypeVariations[props.type || 'info']}

  > div {
    flex: 1;

    p {
      display: block;
      margin-bottom: 0;
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  ${props =>
    !props.hasDescription
    && css`
      align-items: center;
    `}
`;
