import { ModalContainer, ModalContainerRef } from './modal-container';
import { ModalContent } from './modal-content';
import { MotalTitle } from './modal-title';
import { ModalDescription } from './modal-description';
import { ModalActions } from './modal-actions';

interface PopoverModalComposition {
  Actions: typeof ModalActions
  Container: typeof ModalContainer
  Content: typeof ModalContent
  Description: typeof ModalDescription
  Title: typeof MotalTitle
}

export const PopoverModal: PopoverModalComposition = Object.freeze({
  Actions: ModalActions,
  Container: ModalContainer,
  Content: ModalContent,
  Description: ModalDescription,
  Title: MotalTitle,
});

export type { ModalContainerRef };
