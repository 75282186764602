import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

export const DescriptionContainer = styled('p')`
  font-weight: 400;
  font-size: 1rem;
  color: ${colors.tundora};

  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;

  max-height: 3.5rem;
  margin-top: .5rem;
`;
