import React, { ChangeEventHandler } from 'react';
import styled from '@emotion/styled';
import { SearchIcon, Close24Icon as CloseIcon } from '@warbyparker/retail-design-system';
import { colors } from '../../styles/colors';

const StyledBar = styled.div`
  display: flex;
  width: 100%;
  height: 55px;
  transition: top .3s ease-in-out;
  box-sizing: border-box;
  box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  margin: 8px 24px;
  padding: 0 12px;
`;

const SearchInput = styled.div`
  flex-grow: 1;
  margin: 0 12px;
  overflow: hidden;
  input {
    appearance: none;
    border-radius: 0;
    border: 0;
    color: ${colors.charcoal};
    font-size: 18px;
    line-height: 24px;
    outline: 0;
    width: 100%;
    padding: 0;
    background-color: transparent;
  }
  input::placeholder {
    color: ${colors.smoke};
  }
`;

const ClearButton = styled.button`
  display: inherit;
  background: ${colors.white};
  border: none;
  cursor: pointer;
  border-radius: 100px;
  color: ${colors.charcoal};
  padding: 0;
`;

const WidthSetter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
`;

interface SearchProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClear: React.MouseEventHandler<HTMLButtonElement>;
  searchValue: string;
}

const SearchBar : React.FC<SearchProps> = ({
  onChange,
  onClear,
  searchValue,
}) => (
  <StyledBar>
    <WidthSetter>
      <SearchIcon />
      <SearchInput>
        <input
          aria-label="Search frames"
          autoCorrect="off"
          autoComplete="off"
          placeholder="Search frames"
          value={searchValue}
          id="search-input"
          onChange={onChange}
        />
      </SearchInput>
      <ClearButton
        aria-label="Clear search"
        onClick={onClear}
      >
        <CloseIcon />
      </ClearButton>
    </WidthSetter>
  </StyledBar>
);

export default SearchBar;
