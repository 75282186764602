import React from 'react';
import styled from '@emotion/styled';

const Loading = styled('div')`
  height: 100vh;
  width: 100%;
  background: white url("/static/loading.gif") no-repeat fixed center;
`;

export default () => (
  <Loading />
);
