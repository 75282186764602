/* eslint-disable react/require-default-props */
import React, { ChangeEventHandler } from 'react';
import styled from '@emotion/styled';
import { CardSelect } from '@warbyparker/retail-design-system';

const Hidden = styled.div`
  opacity: 0;
  height: 0;
  pointer-events: none;
`;

interface ColorSwatchProps {
  swatch?: string;
}
const ColorSwatch = styled.span<ColorSwatchProps>`
  display: inline-block;
  width: 1em;
  height: 1em;
  background: ${props => `url(${props.swatch})`};
  background-size: contain;
`;

const formatId = (name) => `filters-${name}`;

interface FacetProps {
  active: boolean;
  allowMultiple: boolean;
  facetGroup: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  size: 'small' | 'medium' | 'large';
  title: string;
  value: string;
  swatch?: string;
}

const FacetCard : React.FC<FacetProps> = ({
  active,
  allowMultiple,
  facetGroup,
  onChange,
  size,
  title,
  value,
  swatch,
}) => (
  <div>
    <CardSelect
      size={size}
      active={active}
      title={title}
      htmlFor={formatId(value)}
      icon={facetGroup === 'colors' ? <ColorSwatch swatch={swatch} /> : undefined}
    />
    <Hidden>
      <input
        aria-hidden="true"
        id={formatId(value)}
        name={allowMultiple ? value : facetGroup}
        type={allowMultiple ? 'checkbox' : 'radio'}
        checked={active}
        onChange={onChange}
        value={value}
      />
    </Hidden>
  </div>
);

export default FacetCard;
