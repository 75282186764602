import { RequestMethod } from '../helios-retail-types';
import { HeliosHttpRequestBuilder } from './http-request-builder';
import parseResponse from '../utils/parseResponse';

export class HeliosClient {
  private static heliosToken: string;

  static authorize(token: string) {
    HeliosClient.heliosToken = token;
    return this;
  }

  private static configureRequest<T>(endpoint: string, method: RequestMethod, body?: T): Request {
    const httpRequestBuilder = new HeliosHttpRequestBuilder();
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}${endpoint}`;

    httpRequestBuilder.setAuthorization(this.heliosToken);
    httpRequestBuilder.setEndpoint(url);
    httpRequestBuilder.setMethod(method);
    if (body) {
      httpRequestBuilder.setBody(JSON.stringify(body));
    }

    return httpRequestBuilder.configureRequest();
  }

  public static async get<T>(endpoint: string): Promise<T> {
    const request = this.configureRequest(endpoint, 'GET');

    const response = await fetch(request);

    return await parseResponse({ response }) as Promise<T>;
  }

  public static async patch<T>(endpoint: string, data: T): Promise<T> {
    const request = this.configureRequest(endpoint, 'PATCH', data);

    const response = await fetch(request);

    return await parseResponse({ response }) as Promise<T>;
  }
}
