import React from 'react';
import styled from '@emotion/styled';

import { WAITLIST } from 'src/constants';
import { colors } from '../styles/colors';

const StatusHeader = styled('div')`
  box-sizing: border-box;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 106px;
  left: 0;
  position: sticky;
  padding: 20px 0;
  right: 0;
  z-index: 3;
  top: 78px;
  border-width: 1px 0;
  border-style: solid;
  border-color: ${colors.silver};
`;

const StatusHeaderFixedWidthContainer = styled('div')`
  width: 768px;
  margin: auto;
`;

const StatusHeaderInnerContainer = styled('div')`
  padding: 0 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
`;

const StatusTab = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  flex-grow: 1;
  flex-basis: 0;
`;

const Bubble = styled('div')<{ selected: boolean, count: number }>`
  background: ${(props) => (props.selected ? colors.allports : colors.white)};
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid ${(props) => (props.selected ? colors.allports : colors.grayChateau)};
  font-weight: 600;
  font-size: ${(props) => (props.count >= 100 ? '14px;' : '18px')};
  color: ${(props) => (props.selected ? colors.white : colors.stone)};
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StatusText = styled('div')<{ selected: boolean }>`
  cursor: pointer;
  font-weight: 600;
  font-style: normal;
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  color:${(props) => (props.selected ? colors.allports : colors.stone)};
`;

const StatusTabs = ({
  appointmentsCount,
  canAccessExamsTab,
  positions,
  selectedStatus,
  handleSelectStatus,
}) => {
  const positionCounts = {
    waiting: 0,
    in_progress: 0,
    complete: 0,
  };
  (positions || []).forEach(position => {
    if (Object.keys(positionCounts).includes(position.status)) {
      positionCounts[position.status] += 1;
    }
  });

  return (
    <StatusHeader>
      <StatusHeaderFixedWidthContainer>
        <StatusHeaderInnerContainer>
          {canAccessExamsTab && (
            <StatusTab
              onClick={() => handleSelectStatus(WAITLIST.STATUS.EYE_EXAMS)}
            >
              <Bubble count={appointmentsCount} selected={selectedStatus === 'eye_exams'}>
                {appointmentsCount}
              </Bubble>
              <StatusText selected={selectedStatus === WAITLIST.STATUS.EYE_EXAMS}>Exams</StatusText>
            </StatusTab>
          )}
          <StatusTab
            onClick={() => handleSelectStatus(WAITLIST.STATUS.WAITING)}
          >
            <Bubble count={positionCounts.waiting} selected={selectedStatus === 'waiting'}>
              {positionCounts.waiting}
            </Bubble>
            <StatusText selected={selectedStatus === WAITLIST.STATUS.WAITING}>Waiting</StatusText>
          </StatusTab>

          <StatusTab
            onClick={() => handleSelectStatus(WAITLIST.STATUS.IN_PROGRESS)}
          >
            <Bubble count={positionCounts.in_progress} selected={selectedStatus === 'in_progress'}>
              {positionCounts.in_progress}
            </Bubble>
            <StatusText selected={selectedStatus === WAITLIST.STATUS.IN_PROGRESS}>
              In Progress
            </StatusText>
          </StatusTab>

          <StatusTab
            onClick={() => handleSelectStatus(WAITLIST.STATUS.COMPLETE)}
          >
            <Bubble count={positionCounts.complete} selected={selectedStatus === 'complete'}>
              {positionCounts.complete}
            </Bubble>
            <StatusText selected={selectedStatus === WAITLIST.STATUS.COMPLETE}>Complete</StatusText>
          </StatusTab>
        </StatusHeaderInnerContainer>
      </StatusHeaderFixedWidthContainer>
    </StatusHeader>
  );
};

export default StatusTabs;
