import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

const DateCellWrapper = styled('div')`
  cursor: pointer;
  position: relative;
  display: inline-block;
  
  &:hover .date-cell-tooltip-text {
    visibility: visible;
    opacity: 1;
  }
`;

const DateTooltip = styled('span')`
  visibility: hidden;
  position: absolute;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
  
  &::after {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
  }
  
  &.tooltip-left {
    top: -5px;
    bottom: auto;
    right: 128%;
  }
  
  &.tooltip-left::after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
  }
  
`;

const Table = styled('table')`
  border-spacing: 0px;
  font-family: proxima-nova,sans-serif;
  font-size: 16px;
  width: 100%
`;

const Thead = styled('thead')`
  th {
    line-height: 26px;
    color: #a1a4a6;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 12px 13px;
    background-color: #F8F8F8;
  }
`;

const Tbody = styled('tbody')`
  td {
    line-height: 26px;
    color: #414B56;
    background: white;
    border-top: 1px solid #E1E5E6;
    text-overflow: ellipsis;
    padding: 6px 13px;
  }
  tr:first-of-type td:first-of-type {
    border-top-left-radius: 7px;
  }
  tr:first-of-type td:last-child {
    border-top-right-radius: 7px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 7px;
  }
  tr:last-child td:first-of-type {
    border-bottom-left-radius: 7px;
  }
`;

const DateCell = styled('td')`
  width: 70px;
`;

const DateValue = ({ value }) => {
  const formattedShortTime = moment(value).format('LT');
  const formattedFullDate = moment(value).format('LLL');

  return (
    <DateCellWrapper>
      {formattedShortTime}
      <DateTooltip className="date-cell-tooltip-text tooltip-left">{formattedFullDate}</DateTooltip>
    </DateCellWrapper>
  );
};

export const WaitlistItemLogs = ({ logs }) => (
  <Table>
    <Thead>
      <tr>
        <th>Details</th>
        <th>Creator</th>
        <th>Created</th>
      </tr>
    </Thead>
    <Tbody>
      {
        logs.map(logItem => (
          <tr key={logItem.id}>
            <td>{logItem.details}</td>
            <td>{logItem.created_by_name}</td>
            <DateCell>
              <DateValue value={logItem.created_at} />
            </DateCell>
          </tr>
        ))
      }
    </Tbody>
  </Table>
);
