import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { CardSelect, Button } from '@warbyparker/retail-design-system';

import { CLEAR_CUSTOMER_WAITLIST_ERROR, SET_WAITLIST_CUSTOMER_ACCOUNT } from 'src/middleware/waitlist';
import { CUSTOMER_SEARCH_REQUEST } from 'src/middleware/customer-search';

import { useHistory } from 'react-router-dom';
import CustomerAccountSelector from './customer-account-selector';
import AnimatedLoader from './animated-loader';
import HeaderNav from './header-nav';

import exclamationIcon from './icon-exclamation.svg';
import { WaitlistItemLogs } from './waitlist-item-logs';
import { getDefaultCategories } from './customer-form-default-categories';
import { useAppSelector } from '../hooks';
import { localeSelector } from '../selectors';

const Page = styled('div')`
  background: #f8f8f8;
  height: 100%;
  left: 0;
  min-height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
`;

const Content = styled('div')`
  max-width: 768px;
  margin: 0 auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
`;

const ErrorContainer = styled('div')`
  align-items: center;
  background: rgba(214, 0, 60, 0.1);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  margin: 40px auto 0;
  width: 704px;
`;

const ErrorIcon = styled('span')`
  background: url(${exclamationIcon}) no-repeat;
  background-size: 100 %;
  display: inline-block;
  height: 15px;
  margin: 0 15px 0;
  width: 15px;
`;

const ErrorPara = styled('p')`
  color: #e47c4c;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 18px 0;
  text-align: center;
`;

const FormHeader = styled('h1')`
  color: #414b56;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding-top: 36px;
  text-align: center;
`;

const Container = styled('div')`
  display: flex;
  margin: 0 24px;
  padding: 12px 0 0;
`;

const Section = styled('div')`
  display: flex;
  flex-wrap: wrap;
  padding: 0 6px;
  flex-direction: column;
  flex: 1;
`;

const CategorySection = styled(Section)`
  h1 {
    margin-left: 0;
    padding: 0;
  }
`;

const CategoryCardContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 18px;
  row-gap: 12px;
  margin-top: 18px;
  width: 100%;
`;

const Label = styled('p')`
  color: #a1a4a6;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 6px;
`;

const Input = styled('input')`
  border: 1px solid #e1e5e6;
  border-radius: 6px;
  font-family: proxima-nova, sans-serif;
  height: 66px;
  padding: 0 24px;

  &:focus {
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.24);
    outline: 0;
  }
`;

const NotesInput = styled('textarea')`
  border: 1px solid #e1e5e6;
  border-radius: 6px;
  font-family: proxima-nova, sans-serif;
  height: 112px;
  padding: 24px;
  resize: none;

  &:focus {
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.24);
    outline: 0;
  }
`;

const ButtonContainer = styled('div')`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  margin: 48px 30px;
  padding: 0 0 36px;
  
  button {
    margin-left: 12px;
  }
`;

const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px;
  padding: 12px 0 0;
`;

const Header = styled('h1')`
  color: #414b56;
  font-size: 24px;
  font-weight: 600;
  margin: ${props => (props.noTopMargin ? '0 24px 12px' : '30px 0 12px')};
  max-width: 768px;
  padding: 0 6px;
`;

const Divider = styled('div')`
  height: 48px;
  max-width: 768px;
`;

export const formatTelephoneInput = (val = '') => {
  if (!val) return '';

  // Strip non-numeric characters and truncate to maximum
  // digit length of telephone number
  const digits = val.replace(/\D/g, '').substr(0, 10);
  const format = '000-000-0000';

  // Add in dash spacers with empty string as default.
  // If removing characters, automatically remove dash
  // as position changes from number after dash to number
  // before dash
  return digits.split('').reduce((acc, digit) => {
    const position = format[acc.length];
    const isNotNumeric = position === '-';
    if (position && isNotNumeric) {
      // eslint-disable-next-line no-param-reassign
      acc += format[acc.length];
    }
    // eslint-disable-next-line no-param-reassign
    acc += digit;
    return acc;
  }, '');
};

const emptyForm = {
  name: '',
  notes: '',
  telephone: '',
  partySize: 1,
  customerId: null,
  categories: getDefaultCategories(),
};

export const CustomerForm = ({
  formHeader,
  formData,
  dispatch,
  updateFormData,
  error,
  facility,
  handleSubmit,
  loading,
  handleCancel = () => {},
  isEditPage = false,
  isReadOnlyPage = false,
  disableInput = false,
  sectionHeader,
  positions,
  positionId,
  cancelButtonText = 'Cancel',
  positionLogs,
  redirect,
}) => {
  const { name, notes, telephone, categories, partySize, customerId } = formData;
  const isSaveButtonDisabled = !(name || telephone);
  const saveButtonText = isEditPage ? 'Save changes' : 'Add to wait list';

  const history = useHistory();
  const handleTextChange = async (e) => {
    // clear out any stale error
    if (error) {
      dispatch({ type: CLEAR_CUSTOMER_WAITLIST_ERROR });
    }

    e.preventDefault();
    const key = e.target.name;
    const val = e.target.value;
    let formattedVal = null;

    if (key === 'telephone') {
      formattedVal = formatTelephoneInput(val);
    }

    updateFormData({
      ...formData,
      [key]: formattedVal || val,
    });
  };

  const handleCategoriesChange = async (subcategory) => {
    // update the one selected subcategory's active
    // status to the opposite of what it previously
    // was to allow select/deselect
    if (!disableInput) {
      subcategory.active = !subcategory.active; // eslint-disable-line no-param-reassign
      updateFormData({
        ...formData,
        categories: formData.categories,
      });
    }
  };

  const searchCustomer = q => {
    const action = { type: CUSTOMER_SEARCH_REQUEST, payload: q };
    dispatch(action);
  };

  const selectCustomer = customer => {
    const newFormData = { customerId: customer.id };

    if (!name && customer.first_name) newFormData.name = `${customer.first_name} ${customer.last_name}`;
    if (!telephone && customer.telephone) newFormData.telephone = customer.telephone;

    dispatch({
      type: SET_WAITLIST_CUSTOMER_ACCOUNT,
      payload: {
        id: customer.id,
        email: customer.email,
        telephone: telephone || customer.telephone,
      },
    });

    updateFormData({
      ...formData,
      ...newFormData,
    });
  };

  const removeCustomer = () => {
    updateFormData(emptyForm);
  };

  const onEditButtonClick = () => {
    const editUrl = `/edit-customer/${positionId}`;
    history.push(editUrl);
  };

  return (
    <Page>
      <HeaderNav
        facilityName={facility.name}
        positions={positions}
        backUrl={redirect || '/'}
      />
      <Content>
        {error && (
          <ErrorContainer>
            <ErrorIcon />
            <ErrorPara>{error}</ErrorPara>
          </ErrorContainer>
        )}
        <FormHeader data-testid="form-header">{formHeader}</FormHeader>
        <HeaderContainer>
          <Header>Lookup existing customer</Header>
          {
            isReadOnlyPage && (
              <Button onClick={onEditButtonClick} data-testid="top-edit-visitor-button">
                Edit Visitor
              </Button>
            )
          }
        </HeaderContainer>
        <Container>
          <Section>
            <Label>Customer account (optional)</Label>
            <CustomerAccountSelector
              customerId={customerId}
              searchCustomer={searchCustomer}
              selectCustomer={selectCustomer}
              removeCustomer={removeCustomer}
              dispatch={dispatch}
              disabled={disableInput}
            />
          </Section>
        </Container>
        <Divider />
        <Header noTopMargin>{sectionHeader}</Header>
        <Container>
          <Section>
            <Label>Visitor name</Label>
            <Input
              disabled={disableInput}
              name="name"
              onChange={handleTextChange}
              type="text"
              value={name}
            />
          </Section>
          <Section>
            <Label>Visitor phone</Label>
            <Input
              disabled={disableInput}
              name="telephone"
              onChange={handleTextChange}
              pattern="[0-9\-]+"
              // type is telephone to bring up numeric keyboard on iPad
              type={window.IS_HERMES ? 'tel' : 'text'}
              value={telephone}
            />
          </Section>
        </Container>
        <Container>
          <Section>
            <Label>Party Size</Label>
            <Input
              disabled={disableInput}
              min="1"
              name="partySize"
              onChange={handleTextChange}
              type="number"
              value={partySize}
            />
          </Section>
        </Container>
        <Container>
          <CategorySection>
            <Header>Are they here to shop?</Header>
            <CategoryCardContainer>
              {categories.shopping.map(subcat => (
                <CardSelect
                  key={subcat.id}
                  active={subcat.active}
                  onClick={() => handleCategoriesChange(subcat)}
                  disabled={disableInput}
                  textAlign="start"
                  title={subcat.displayName}
                  subtitle={subcat.subtext}
                />
              ))}
            </CategoryCardContainer>
            <Header>Or to see an optician?</Header>
            <CategoryCardContainer>
              {
                categories.optician
                  .filter(subcat => (useAppSelector(localeSelector) === 'US' ? true : subcat.key !== 'lens_replacement'))
                  .map(subcat => (
                    <CardSelect
                      key={subcat.id}
                      active={subcat.active}
                      hasSubtext={!!subcat.subtext}
                      onClick={() => handleCategoriesChange(subcat)}
                      disabled={disableInput}
                      textAlign="start"
                      title={subcat.displayName}
                      subtitle={subcat.subtext}
                    />
                  ))
              }
            </CategoryCardContainer>
          </CategorySection>
        </Container>
        <Divider />
        <Header noTopMargin>Anything else?</Header>
        <Container>
          <Section>
            <Label>Additional Notes</Label>
            <NotesInput
              disabled={disableInput}
              name="notes"
              onChange={handleTextChange}
              type="text"
              value={notes}
            />
          </Section>
        </Container>
        {
          (isEditPage || isReadOnlyPage) && (
            <>
              <Divider />
              <Header noTopMargin>Logs</Header>
              <Container data-testid="waitlist-item-logs-table">
                <WaitlistItemLogs logs={positionLogs} />
              </Container>
            </>
          )
        }
        <ButtonContainer>
          {
            (isEditPage || isReadOnlyPage) && (
              <Button
                disabled={loading}
                onClick={handleCancel}
                type="button"
                data-testid="bottom-cancel-button"
                size="large"
                color="secondary"
              >
                {cancelButtonText}
              </Button>
            )
          }
          {
            !isReadOnlyPage && (
              <Button
                disabled={isSaveButtonDisabled}
                onClick={handleSubmit}
                data-testid="bottom-save-button"
                size="large"
              >
                { loading ? <AnimatedLoader /> : saveButtonText }
              </Button>
            )
          }
        </ButtonContainer>
      </Content>
    </Page>
  );
};

const select = (state) => ({
  facility: state.auth.me.facility,
  positionLogs: state.waitlist.positionLogs,
});

export const CustomerFormContainer = connect(select)(CustomerForm);
