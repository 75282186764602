import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { UPDATE_CUSTOMER_STATUS, WAITLIST_START_SHOPPING } from 'src/middleware/waitlist/index';
import { Button } from '@warbyparker/retail-design-system';

const OVERFLOW_BUTTON_HEIGHT_PX = 48;
const MENU_ITEM_HEIGHT_PX = 45;
const SPACER_HEIGHT_PX = 8;

export const getMenuHeightPx = (position, isNativeWrapper) => {
  // There are always at least 5 items in the menu.
  // There can be up to 8 in some circumstances.
  let items = 5;
  if (position.customer_id) items += 1;
  if (position.customer_id && !isNativeWrapper) items += 1;
  if (position.status !== 'waiting') items += 1;
  return items * MENU_ITEM_HEIGHT_PX + SPACER_HEIGHT_PX;
};

export const getMenuTopPx = (menuHeightPx, showMenuAbove) => {
  // If "top" is not specified, the menu menu would appear
  // below the bottom of the overflow button.
  // We want to move it up some pixels based on whether we
  // are showing it above or below the overflow button.
  if (showMenuAbove) {
    return -(menuHeightPx + OVERFLOW_BUTTON_HEIGHT_PX / 2);
  }
  return (-OVERFLOW_BUTTON_HEIGHT_PX / 2);
};

const OverflowContainer = styled('div')`
  width: 72px;
  height: ${OVERFLOW_BUTTON_HEIGHT_PX}px;
  margin: 0 0 0 6px;
`;

const Overlay = styled('div')`
  width: 100%;
  height: 100%;
  background: rgba(64, 74, 85, .2);
  position: absolute;
  top: 0px;
  left: 0px;
  touch-action: none;
`;

// Why is 'top' -257px or -25px?
//
// If the menu opens below,
// move it up 1/2 the height of the overflow icon.
//
// If the menu opens above, move it up:
// 1/2 the height of the overflow icon (24px)
// The height of the spacer (8px)
// The height of all menu items (45px x number of menu items)
// - (24px + 8px + 45px x 5) = -257px
const Menu = styled('div')`
  width: 250px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(30px);
  border-radius: 12px;
  position: relative;
  right: 225px;
  top: ${props => `${props.menuTopPx}px`};
`;

const MenuItem = styled('button')`
  color: ${props => (props.danger ? '#d6003c' : '#414b56')};
  background: none;
  font-size: 16px;
  font-weight: 600;
  line-height: ${MENU_ITEM_HEIGHT_PX}px;
  margin: 0;
  padding: 0 0 0 22px;
  border: 0;
  border-bottom: 1px solid #e1e5e6;
  cursor: pointer;
  width: 100%;
  display: flex;
  touch-action: none;

  &:last-of-type {
    border-bottom: 0;
  }
`;

const Spacer = styled('div')`
  background-color: #e1e5e6;
  height: ${SPACER_HEIGHT_PX}px;
`;

const OverflowButton = styled(Button)`
  width: 62px;
  height: ${OVERFLOW_BUTTON_HEIGHT_PX}px;
  padding: 0;
  font-size: 26px;
`;

const PositionOverflowMenu = ({ dispatch, position, selectedStatus }) => {
  const history = useHistory();

  // This state tells if the menu should be visible or not
  const [showMenu, setShowMenu] = useState(false);

  // This state tells if the menu should appear above or below the row
  const [showMenuAbove, setShowMenuAbove] = useState(false);

  const editUrl = `/edit-customer/${position.id}`;
  const viewUrl = `/view-customer/${position.id}`;
  const assignUrl = `/add-assignee/${position.id}`;
  const removeUrl = `/remove-customer/${position.id}`;

  const isNativeWrapper = !!window.IS_HERMES;

  const updateStatus = async (status) => {
    dispatch({
      type: UPDATE_CUSTOMER_STATUS,
      waitlist_position_id: position.id,
      payload: { status },
    });
    setShowMenu(false);
  };

  const goToVisit = () => {
    dispatch({
      type: WAITLIST_START_SHOPPING,
      payload: { waitlist_position_id: position.id },
    });
    setShowMenu(false);
  };

  const goToAdmin = () => {
    const url = `https://${process.env.HELIOS_ADMIN_DOMAIN}/shop/customers/${position.customer_id}`;
    window.open(url, '_blank');
  };

  const goToRom = () => {
    const url = new window.URL(`https://${process.env.RETAIL_ORDER_MANAGEMENT_DOMAIN}/customer/${position.customer_id}`);
    const params = new window.URLSearchParams();
    params.append('next', window.location.href);
    url.search = String(params);
    window.location.href = url;
  };

  const menuHeightPx = getMenuHeightPx(position, isNativeWrapper);

  const openMenu = (e) => {
    // Menu should appear above the row if the menu height
    // is greater than the space below the overflow menu button
    const spaceBelow = window.innerHeight
      - e.target.getBoundingClientRect().bottom
      - 104 // The height of the footer
      + (OVERFLOW_BUTTON_HEIGHT_PX / 2);

    setShowMenuAbove(menuHeightPx > spaceBelow);
    setShowMenu(true);
  };

  const menuTopPx = getMenuTopPx(menuHeightPx, showMenuAbove);

  return (
    <OverflowContainer>
      <OverflowButton onClick={openMenu}>
        &middot;&middot;&middot;
      </OverflowButton>
      {
        showMenu && (
          <>
            <Overlay onClick={() => setShowMenu(false)} />
            <Menu menuTopPx={menuTopPx}>
              {
                selectedStatus !== 'waiting' && (
                  <MenuItem onClick={() => updateStatus('waiting')}>
                    Move to waiting
                  </MenuItem>
                )
              }
              {
                selectedStatus !== 'in_progress' && (
                  <MenuItem onClick={() => updateStatus('in_progress')}>
                    Move to in progress
                  </MenuItem>
                )
              }
              {
                selectedStatus !== 'complete' && (
                  <MenuItem onClick={() => updateStatus('complete')}>
                    Move to complete
                  </MenuItem>
                )
              }
              <MenuItem onClick={goToVisit}>
                Go to Shop
              </MenuItem>
              {position.customer_id && !isNativeWrapper && (
                <MenuItem onClick={goToAdmin}>
                  Go to Admin
                </MenuItem>
              )}
              {position.customer_id && (
                <MenuItem onClick={goToRom}>
                  Go to Order History
                </MenuItem>
              )}
              <Spacer />
              <MenuItem onClick={() => history.push(viewUrl)}>
                View visitor
              </MenuItem>
              <MenuItem onClick={() => history.push(editUrl)}>
                Edit visitor
              </MenuItem>
              {position.status !== 'waiting' && (
                <MenuItem onClick={() => history.push(assignUrl)}>
                  {(position.sales_advisor_assigned || position.optician_assigned) ? 'Reassign' : 'Assign'}
                </MenuItem>
              )}
              <MenuItem onClick={() => history.push(removeUrl)} danger>
                Remove visitor
              </MenuItem>
            </Menu>
          </>
        )
      }
    </OverflowContainer>
  );
};

export default PositionOverflowMenu;
