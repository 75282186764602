/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DescriptionContainer } from './styles';

interface ModalDescriptionProps extends React.ComponentProps<'p'> {}

export const ModalDescription: React.FC<ModalDescriptionProps> = ({
  children,
  ...props
}) => (
  <DescriptionContainer {...props}>{children}</DescriptionContainer>
);
