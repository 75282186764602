import React from 'react';
import { Popover } from '@mantine/core';
import { StyledDatePicker } from './styles';
import { DateSection } from '../../styles';

interface DatePickerProps {
  currentDate: Date,
  onDateChanged: (date: Date) => void;
  applyDateFilter: (date: Date) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  children,
  currentDate,
  onDateChanged,
  applyDateFilter,
}) => {
  const formatWeekday = (date: Date) =>
    date.toLocaleString('us-US', { weekday: 'narrow' });

  const onClosedDatePicker = () => {
    applyDateFilter(currentDate);
  };

  return (
    <Popover
      position="bottom"
      shadow="md"
      radius={12}
      onClose={onClosedDatePicker}
    >
      <DateSection>
        <Popover.Target>
          {children}
        </Popover.Target>
        <Popover.Dropdown>
          <StyledDatePicker
            size="xs"
            firstDayOfWeek={0}
            hideOutsideDates
            value={currentDate}
            date={currentDate}
            onDateChange={onDateChanged}
            onChange={onDateChanged}
            weekdayFormat={formatWeekday}
          />
        </Popover.Dropdown>
      </DateSection>
    </Popover>
  );
};

export default DatePicker;
