import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FooterContent, StyledFooter } from './styles';
import FooterActionsSection from './footer-actions-section';
import { REQUEST_QR_FOR_WAITLIST } from '../../../../middleware/waitlist';
import AvailableExamsToggler from './available-exams-toggler';
import NextAvailable from '../../../appointments/next-available';

type FooterProps = {
  enableQRCodeScan: boolean;
  openBookEyeExam: () => void;
};

const Footer: React.FC<FooterProps> = ({ openBookEyeExam, enableQRCodeScan }) => {
  const [showNextAvailableEyeExams, setShowNextAvailableEyeExams] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const onShowEyeExams = () => {
    setShowNextAvailableEyeExams(!showNextAvailableEyeExams);
  };

  return (
    <StyledFooter id="footer" showNextAvailableEyeExams={showNextAvailableEyeExams}>
      <FooterContent>
        <AvailableExamsToggler
          showNextAvailableEyeExams={showNextAvailableEyeExams}
          onShowEyeExams={onShowEyeExams}
        />
        <FooterActionsSection
          enableQRCodeScan={enableQRCodeScan}
          onBookEyeExam={openBookEyeExam}
          onAddCustomer={() => history.push('/add-customer')}
          onScanQRCode={() => dispatch({ type: REQUEST_QR_FOR_WAITLIST })}
        />
      </FooterContent>
      { showNextAvailableEyeExams && <NextAvailable /> }
    </StyledFooter>
  );
};

export default Footer;
