import React, { useState } from 'react';
import { RefreshIcon } from '@warbyparker/retail-design-system';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { HeaderIcon } from '../shared-components/headers';

const SpinningWrapper = styled.div<{ $loading: boolean }>`
  display: flex;
  ${(props) => props.$loading && css`
    animation: ${keyframes`
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    `} 1s linear infinite;
  `}
`;

const StyledHeaderIcon = styled(HeaderIcon)`
  ${(props) => props.disabled && css`
    pointer-events: none;
    opacity: 0.4;
  `}
`;

interface StyledRefreshIconProps {
  loading: boolean;
  onRefresh?: () => void;
}

const StyledRefreshIcon = ({ loading, onRefresh }: StyledRefreshIconProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(loading);

  const handleAnimationIteration = () => {
    if (!loading) setIsLoading(false);
  };

  const handleOnClick = () => {
    setIsLoading(true);
    onRefresh?.();
  };

  return (
    <StyledHeaderIcon onClick={handleOnClick} disabled={isLoading}>
      <SpinningWrapper $loading={isLoading} onAnimationIteration={handleAnimationIteration}>
        <RefreshIcon />
      </SpinningWrapper>
    </StyledHeaderIcon>

  );
};

export default StyledRefreshIcon;
