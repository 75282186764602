import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PAGE_SIZE, MAX_NUM_OF_PAGINATION_BUTTONS } from 'src/constants';
import { fetchAppointmentsList } from 'src/middleware/eye-exams';
import { calculatePageOffset } from 'src/utils/calculatePageOffset';

interface UseAppointmentsPaginationOptions {
  page: number;
  pageSize: number;
  pageCount: number;
}

export const useAppointmentsPagination = ({
  page,
  pageSize: itemsPerPage = PAGE_SIZE,
  pageCount,
}: UseAppointmentsPaginationOptions) => {
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(itemsPerPage);

  const pageOptions = useMemo(() => {
    if (pageCount <= 0) return [];

    const pages = new Array(pageCount).fill(1).map((e, i) => e + i);

    if (pageCount < MAX_NUM_OF_PAGINATION_BUTTONS) return pages;

    const firstPage = pages[0];
    const lastPage = pages[pages.length - 1];
    const pageOffSet = calculatePageOffset(page, pageCount);

    const middlePages = pages.slice(pageOffSet, pageOffSet + MAX_NUM_OF_PAGINATION_BUTTONS - 2);

    return [firstPage, ...middlePages, lastPage];
  }, [page, pageCount]);

  const goToPage = useCallback((pageNumber: number) => dispatch(
    fetchAppointmentsList({ page: pageNumber, pageSize, idNotIn: null })), []);

  const previousPage = useCallback(() => {
    if (page === 1) return;
    dispatch(fetchAppointmentsList({ page: page - 1, pageSize, idNotIn: null }));
  }, []);

  const nextPage = useCallback(() => {
    if (page === pageCount) return;
    dispatch(fetchAppointmentsList({ page: page + 1, pageSize, idNotIn: null }));
  }, []);

  const changePageSize = useCallback((newSize: number) => {
    setPageSize(newSize);
  }, []);

  return {
    pageOptions,
    changePageSize,
    goToPage,
    previousPage,
    nextPage,
  };
};
