/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ContentContainer } from './styles';

interface ModalContentProps extends React.HTMLAttributes<HTMLSpanElement> {}

export const ModalContent: React.FC<ModalContentProps> = ({
  children,
  ...props
}) => (
  <ContentContainer {...props}>{children}</ContentContainer>
);
