import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

export const CardListContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
`;

export const CardList = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  margin: 0 auto;
`;

export const CardListButton = styled.button`
  margin: 0;
  cursor: pointer;
  border: 0;
  background: transparent;
`;

export const CardTileContainer = styled.div<{ active?: boolean }>`
  background-color: ${colors.white};
  border: 1px solid ${colors.grayBorder};
  transform: scale(0.9);
  transition: transform 0.3s ease;
  padding: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  flex-grow: 1;
  flex-basis: 0;
  align-self: stretch;
`;

export const LabelBold = styled('p')`
  color: ${colors.charcoal};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
`;

export const NextAvailableSection = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.stone};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;
