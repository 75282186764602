import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import { Button } from '@warbyparker/retail-design-system';

import * as Styled from './styles';

export type OverflowPageRef = {
  closePage: () => void
  openPage: () => void
};

type OverflowPageProps = {
  title: string
  src: string
};

const OverflowPage: React.ForwardRefRenderFunction<
OverflowPageRef,
OverflowPageProps
> = ({ src, title }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const closePage = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openPage = useCallback(() => {
    setIsOpen(true);
  }, []);

  useImperativeHandle(ref, () => ({
    closePage,
    openPage,
  }), [closePage, openPage]);

  return (
    <>
      {isOpen && (
        <Styled.Container>
          <iframe
            width="100%"
            height="100%"
            referrerPolicy="no-referrer"
            title={title}
            src={src}
          />

          <div>
            <Button
              type="button"
              onClick={closePage}
            >
              Go back to Waitlist
            </Button>
          </div>
        </Styled.Container>
      )}
    </>
  );
};

export default forwardRef(OverflowPage);
