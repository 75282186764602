import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { FilterIcon, ArrowLeft16Icon, ArrowRight16Icon, Button, CaretDown16Icon } from '@warbyparker/retail-design-system';
import { requestFrames, requestRecommendation, setFacets, setCurrentPageFrom, defaultFacets } from '../../middleware/frame-style-assistant';
import { facetGroups, View } from './constants';
import SearchBar from './search-bar';
import FacetCard from './facet-card';
import { colors } from '../../styles/colors';
import { Facet, Facets } from '../../service-clients/warby-api-types';
import { RECOMMENDATIONS_FEATURE_FLAG } from '../../../feature-flags';
import { useAppSelector } from '../../hooks';

const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;
`;

const JustifiedContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Drawer = styled.div`
  height: 450px;
  max-width: 768px;
  display: flex;
  flex-direction: column;
`;

const Text = styled.span`
  font-size: 18px;
`;

const Tabs = styled.div`
  display: flex;
  list-style: none;
  justify-content: center;
  position: relative;
  margin: 24px 0;
`;

interface TabProps {
  active: boolean;
}

const Tab = styled.button<TabProps>`
  background: none;
  border: 0;
  padding: 12px 0;
  color: ${props => (props.active ? 'color: #a1a4a6' : 'inherit')};

  position: relative;
  cursor: pointer;

  margin-right: 18px;
  &:last-of-type {
    margin-right: 0;
  }


  // allow us to maintain tap space/padding
  // but keep border close to text
  ${props => props.active && `
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      left: 0;
      bottom: 6px;
      background: currentColor;
    }
  `}
`;

const DirtyIcon = styled.i`
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: ${colors.darkBlue};

    position: absolute;
    top: 6px;
    right: -6px;
`;

const TabButton = styled.button`
  color: ${colors.stone};
  background: ${colors.white};
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  padding: 10px 10px 7px 10px;
  margin: 0 24px;
  box-shadow: 0px 1px 4px rgba(65, 75, 86, 0.12), 0px 8px 12px rgba(65, 75, 86, 0.12);
`;

const ResetButton = styled(Button)`
    justify-content: flex-start;
    min-width: 120px;
`;

const FacetsList = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  padding: 0;
  border: none;
  & > div {
    margin: 6px;
  }
`;

const calculateActiveFacetCount = (facetNames : string[]) => facetNames
  .filter((name) => !Object.keys(defaultFacets).includes(name))
  .length;

const getPreviousTabIndex = (currentTab, numOfTabs) => {
  let previousTab = (currentTab - 1) % numOfTabs;
  if (previousTab < 0) {
    previousTab += numOfTabs;
  }
  return previousTab;
};

const getNextTabIndex = (currentTab, numOfTabs) => (currentTab + 1) % numOfTabs;

const isFacetGroupDirty = (
  facetGroup: string,
  selectedFacets: Facets,
) => Object.keys(selectedFacets).reduce((isDirty, facet) => {
  const defaultValue = defaultFacets[facet]?.value;
  const { value, type } = selectedFacets[facet];

  if (type === facetGroup && value !== defaultValue) {
    return true;
  }
  return isDirty;
}, false);

const isDirty = (
  selectedFacets: Facets,
) => Object.keys({ ...facetGroups, term: { facets: [{ name: '' }] } })
  .some((fg) => isFacetGroupDirty(fg, selectedFacets));

/**
 * Below are some terms used for our filtering system.
 * - Facet Group: Ex. Bridge Fit, Material, Colors
 * - Facet: Red, Brown, Extra-wide
 */

const Filters = () => {
  const isInitialMount = useRef(true);
  const selectedFacets: Facets = useAppSelector((state) => state.frameStyleAssistant.facets);
  const hasData: boolean = useAppSelector(
    (state) => (state.frameStyleAssistant.frames?.length || 0) > 0,
  );
  const frameFamilies = useAppSelector(state => state.frameStyleAssistant.frames);
  const [isOpen, setOpen] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const selectedFacetGroup = Object.keys(facetGroups)[selectedTab];
  const activeFacetCount = calculateActiveFacetCount(Object.keys(selectedFacets));
  const dispatch = useDispatch();

  const totalFrameOptions = useAppSelector((state) => state.frameStyleAssistant.totalFrameOptions);
  const view = useAppSelector(state => state.frameStyleAssistant.view);

  const containerRef = useRef<HTMLDivElement>(null);
  const features = useAppSelector(state => state.auth?.me?.features);

  useEffect(() => {
    if (frameFamilies?.length === 1 && features?.includes(RECOMMENDATIONS_FEATURE_FLAG)) {
      const { storeFrameId } = frameFamilies[0][0];
      dispatch(requestRecommendation(storeFrameId, selectedFacets));
    }
  }, [frameFamilies, selectedFacets, features]);

  useEffect(() => {
    const checkClickOutside = e => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', checkClickOutside);

    return () => {
      document.removeEventListener('click', checkClickOutside);
    };
  }, []);

  useEffect(() => {
    // this check helps not to request API again when switching back from Frame Config page
    if (!(isInitialMount.current && hasData)) {
      dispatch(setFacets(selectedFacets));
      dispatch(requestFrames(selectedFacets));
      // reset pagination when filter value is changed
      dispatch(setCurrentPageFrom(0));
    }

    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  }, [selectedFacets]);

  const handleFacetOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(setFacets({
        ...selectedFacets,
        [e.target.name]: {
          type: selectedFacetGroup,
          value: e.target.value,
        },
      }));
    } else {
      const newState = { ...selectedFacets };
      delete newState[e.target.name];
      dispatch(setFacets(newState));
    }
  };

  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setFacets({
      ...selectedFacets,
      term: {
        type: 'term',
        value: e.target.value,
      },
    }));
  };

  const handleSearchClear = () => {
    const newState = { ...selectedFacets };
    delete newState.term;
    dispatch(setFacets(newState));
  };

  return (
    <Container ref={containerRef}>
      <JustifiedContent>
        <ResetButton
          color="quiet"
          aria-label="Reset filters"
          onClick={() => {
            dispatch(setFacets(defaultFacets));
          }}
          type="reset"
          disabled={!isDirty(selectedFacets) || view !== View.StyleAssistant}
        >
          {activeFacetCount > 0 ? `Reset ${activeFacetCount}` : 'Reset'}
        </ResetButton>
        <Text>
          {' '}
          {totalFrameOptions}
          {' '}
          frames
        </Text>
        <Button
          color="quiet"
          aria-label={isOpen ? 'Close filters' : 'Open filters'}
          onClick={() => setOpen(!isOpen)}
          startIcon={isOpen ? <CaretDown16Icon /> : <FilterIcon />}
          disabled={view !== View.StyleAssistant}
        >
          {isOpen ? 'Close' : 'Filters'}
        </Button>
      </JustifiedContent>
      {isOpen && (
      <Drawer>
        <JustifiedContent>
          <SearchBar
            onChange={handleSearchTermChange}
            onClear={handleSearchClear}
            searchValue={selectedFacets.term?.value || ''}
          />
        </JustifiedContent>
        <JustifiedContent>
          <TabButton
            aria-label="Previous filter"
            onClick={() => {
              setSelectedTab(getPreviousTabIndex(selectedTab, Object.keys(facetGroups).length));
            }}
          >
            <ArrowLeft16Icon />
          </TabButton>
          <Tabs role="tablist" aria-label="Filter types">
            {Object.keys(facetGroups).map((facetGroup, index) => (
              <Tab
                key={facetGroup}
                active={index === selectedTab}
                aria-selected={index === selectedTab}
                aria-controls={`${facetGroup}-tab`}
                role="tab"
                type="button"
                onClick={() => setSelectedTab(index)}
                id={facetGroup}
              >
                {facetGroups[facetGroup].displayName}
                {isFacetGroupDirty(facetGroup, selectedFacets) && <DirtyIcon />}
              </Tab>
            ))}
          </Tabs>
          <TabButton
            aria-label="Next filter"
            onClick={() => {
              setSelectedTab(getNextTabIndex(selectedTab, Object.keys(facetGroups).length));
            }}
          >
            <ArrowRight16Icon />
          </TabButton>
        </JustifiedContent>
        <FacetsList
          role="tabpanel"
          id={`${selectedFacetGroup}-tab`}
          aria-labelledby={selectedFacetGroup}
        >
          {facetGroups[selectedFacetGroup].facets.map((facet) => (
            <FacetCard
              active={Object.values(selectedFacets).some((f: Facet) => f.value === facet.name)}
              allowMultiple={facetGroups[selectedFacetGroup].allowMultiple}
              facetGroup={selectedFacetGroup}
              key={facet.name}
              size={selectedFacetGroup === 'colors' ? 'small' : 'large'}
              title={facet.displayName}
              swatch={facet.swatch}
              value={facet.name}
              onChange={handleFacetOnChange}
            />
          ),
          )}
        </FacetsList>
      </Drawer>
      )}
    </Container>
  );
};

export default Filters;
