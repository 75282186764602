/*
Why is this needed?

> is `url.format` broken or am I?
> https://github.com/nodejs/help/issues/1176

Would like to:
- extract this as a standalone lib
- support all spaces as options:
  https://nodejs.org/api/url.html#url_url_strings_and_url_objects

Blarg, chrome's URL object is fullabugs:
https://bugs.chromium.org/p/chromium/issues/detail?id=819342

*/

interface IOptions {
  host?: string;
  hostname?: string;
  pathname?: string;
  port?: string | number;
  protocol: string;
  query?: { [s: string]: string };
}

const urlstr = (options: Omit<IOptions, 'protocol'>): string => {
  const defaults: IOptions = {
    hostname: (window.location && window.location.hostname) || 'localhost',
    protocol: 'https:',
  };

  const { host, hostname, pathname, port, protocol, query }: IOptions = {
    ...defaults,
    ...options,
  };
  const dummyURL = 'https://.';
  const url = new window.URL(dummyURL);
  url.hostname = hostname || '';
  url.host = host || url.host;
  url.pathname = pathname || url.pathname;
  url.protocol = protocol as string;

  if (port) {
    url.port = String(port);
  }

  if (query) {
    const params = new window.URLSearchParams();
    for (const [key, val] of Object.entries(query)) {
      if (val !== null) {
        params.append(key, val as string);
      }
    }

    url.search = String(params);
  }

  return url.href;
};

export default urlstr;
