/* eslint-disable no-undef */
import { logger } from '../providers/logger';
import { GetAppointmentsQueryParams } from '../middleware/eye-exams/types';
import type { Appointment } from './pmp-api-types';
import { HeliosClient } from './helios-service/helios-client';
import parseAppointmentQueryToQueryParams from './utils/parseAppointmentQueryToQueryParams';
import parseResponse from './utils/parseResponse';

const headers = ({ token }) => ({
  Authorization: `bearer ${token}`,
});

const waitForGlobalJwt = () => (
  // Wait for a JWT to show up on the window object. This will return a
  // promise that sniffs for the presence of window.WP_JWT and resolve
  // with it or null in case of a timeout. This should be replaced with
  // a crisper interface that the native app can tie in to.
  new Promise((resolve) => {
    let interval;
    let timeout;
    const cleanup = () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
    interval = setInterval(() => {
      if (window.WP_JWT) {
        cleanup();
        resolve(window.WP_JWT);
      }
    }, 50);
    timeout = setInterval(() => {
      cleanup();
      resolve(null);
    }, 2 * 1000);
  })
);

const API = {
  async getUserJwt() {
    const fetch = API.fetchUserJwt();
    const wait = waitForGlobalJwt();
    // Return the first truthy promise result.
    const firstResult = await Promise.race([fetch, wait]);
    if (firstResult) {
      return firstResult;
    }
    const allResults = await Promise.all([fetch, wait]);
    return allResults.reduce((acc, x) => x || acc, null);
  },

  async fetchUserJwt() {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/user/jwt`;
    const response = await fetch(url, {
      credentials: 'include',
    }).catch(e => {
      const message = 'Failed to fetch auth token.';
      logger.error(e, message);
      throw new Error(message);
    });

    // not using parseResponse() since 403 is an acceptable status;
    let data;
    switch (response.status) {
      case 200:
        data = await response.json();
        return data.token;
      case 403:
        // missing credentials, needs to log in
        return null;
      case 500:
        throw new Error('Internal Server Error.');
      default:
        // usually CORS issues
        throw new Error('Something went wrong. 😢');
    }
  },

  async getFacilityDisplayAddress({ token, facilityId }) {
    const uri = `api/v1/facility/${facilityId}/display-address`;
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/${uri}`;
    const request = new window.Request(url, {
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async getFacilityEyeExamServices({ token, facilityId }) {
    const uri = `api/v1/facility/${facilityId}/eye-exam-services`;
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/${uri}`;
    const request = new window.Request(url, {
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async getUserMe({ token }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/user/me`;
    const request = new window.Request(url, {
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async getCustomers({ token, query }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v2/customers?q=${query}`;
    const request = new window.Request(url, {
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    const { customers } = await parseResponse({ response });
    return customers;
  },

  async getWaitlist({ token }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v2/store_waitlist`;
    const request = new window.Request(url, {
      method: 'GET',
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async getWaitlistLogItems({ token, positionId }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v2/store_waitlist/${positionId}/log_items`;
    const request = new window.Request(url, {
      method: 'GET',
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async getWaitlistFacilityTeam({ token }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v2/facility_team`;
    const request = new window.Request(url, {
      method: 'GET',
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async getCustomerAccount({ token, customer_id }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/customers/${customer_id}`;
    const request = new window.Request(url, {
      method: 'GET',
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async getCustomerFavorites({ token, customerId }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/customers/${customerId}/favorites`;
    const request = new window.Request(url, {
      method: 'GET',
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async addCustomerToWaitlist({
    token,
    name,
    notes,
    telephone,
    categories,
    party_size,
    customer_id,
  }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v2/store_waitlist`;
    const request = new window.Request(url, {
      method: 'POST',
      headers: headers({ token }),
      credentials: 'include',
      body: JSON.stringify({
        ...(name && { name }),
        ...(notes && { notes }),
        ...(telephone && { telephone }),
        ...(categories && { categories }),
        ...(party_size && { party_size }),
        ...(customer_id && { customer_id }),
      }),
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async editCustomerInWaitlist({
    token,
    waitlist_position_id,
    name,
    notes,
    telephone,
    categories,
    status,
    party_size,
    customer_id,
    assignee,
  }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v2/store_waitlist/${waitlist_position_id}`;

    // Note that the body can include empty strings as the values.
    // This is how we "remove" a phone number, for example.
    const request = new window.Request(url, {
      method: 'PUT',
      headers: headers({ token }),
      credentials: 'include',
      body: JSON.stringify({
        ...(name !== null && { name }),
        ...(notes !== null && { notes }),
        ...(telephone !== null && { telephone }),
        ...(categories !== null && { categories }),
        ...(status !== null && { status }),
        ...(party_size !== null && { party_size }),
        ...(customer_id !== undefined && { customer_id }),
        ...(assignee !== undefined && { [assignee.role]: assignee.id }),
      }),
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async removeCustomerFromWaitlist({ token, waitlist_position_id }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v2/store_waitlist/${waitlist_position_id}`;
    const request = new window.Request(url, {
      method: 'DELETE',
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async sendReturnSMSToCustomer({ token, waitlist_position_id }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v2/store_waitlist/send_return_sms`;
    const request = new window.Request(url, {
      method: 'POST',
      headers: headers({ token }),
      credentials: 'include',
      body: JSON.stringify({
        waitlist_position_id,
      }),
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async createEstimateFromWaitlist({ token, waitlist_position_id }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v2/store_waitlist/create_estimate`;
    const request = new window.Request(url, {
      method: 'POST',
      headers: headers({ token }),
      credentials: 'include',
      body: JSON.stringify({
        waitlist_position_id,
      }),
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async createVisitWithEstimate({ token }) {
    const endpoint = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v2/visits`;
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: headers({ token }),
      credentials: 'include',
      body: JSON.stringify({
        with_estimate: true,
        estimate_id: null,
      }),
    });
    return parseResponse({ response });
  },

  async resendIntakeForm({ token, appointmentId }) {
    const endpoint = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/eye-exam/appointment/${appointmentId}/remind-intake-form`;
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: headers({ token }),
      credentials: 'include',
    });
    return parseResponse({ response });
  },

  async getAppointmentList({
    token,
    facilityShortName,
    status,
    ...queryParams
  }) {
    const otherParams = {
      page: queryParams.page,
      page_size: queryParams.pageSize,
      id_not_in: queryParams.idNotIn,
      start_from: queryParams.startFrom,
      start_thru: queryParams.startThru,
      doctor_break: queryParams.doctorBreak,
      sort: queryParams.sort,
    };

    const params = new URLSearchParams({
      facility_short_name: facilityShortName,
      status,
      ...(Object.keys(otherParams).reduce((acc, key) => ({
        ...acc,
        ...(otherParams[key] !== null ? { [key]: otherParams[key] } : {}),
      }), {})),
    });

    let url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/eye-exam/appointments`;
    url = `${url}?${params}`;

    const request = new window.Request(url, {
      method: 'GET',
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async updateAppointment({
    token,
    appointment,
  }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/eye-exam/appointment/${appointment.id}`;

    const {
      config,
      configId,
      doctor,
      office,
      patient,
      ...appointmentData
    } = appointment || {};

    const data = {
      ...appointmentData,
      config,
      configId: configId || config?.id || config?.pcProductId || 'dummyId',
      doctorId: doctor?.id || null,
      officeId: office?.id || appointmentData?.officeId,
      patientId: patient?.id || null,
    };

    const request = new window.Request(url, {
      method: 'PUT',
      headers: headers({ token }),
      credentials: 'include',
      body: JSON.stringify({ data }),
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  async getNextAppointmentAvailable({
    token,
    facilityShortNames,
    fromDate,
    appointmentTypes,
    thruDate,
  }) {
    const params = new URLSearchParams({
      facilityShortNames,
      fromDate,
      appointmentTypes,
      thruDate,
    });

    let url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/appointments/next-available`;
    url = `${url}?${params}`;

    const request = new window.Request(url, {
      method: 'GET',
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

  getAppointmentsV2: async (
    token: string,
    appointmentsQuery: GetAppointmentsQueryParams,
  ): Promise<{ data: Appointment[] }> => {
    const queryParams = parseAppointmentQueryToQueryParams(appointmentsQuery);
    return HeliosClient.authorize(token).get<ReturnType<typeof API.getAppointmentsV2>>(`/api/v2/eye-exam/appointments?${queryParams}`);
  },

  partialUpdateAppointment: async (
    token: string,
    appointmentId: string,
    appointmentData: Partial<Appointment>,
  ) => HeliosClient.authorize(token).patch(
    `/api/v1/eye-exam/appointment/${appointmentId}`,
    { data: { ...appointmentData } },
  ),
};

export default API;
