import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ConfirmAppointmentModal from './confirm-appointment-modal';
import { partialUpdateAppointmentData } from '../../../middleware/eye-exams/actions';

const ConfirmAppointmentModalContent = ({
  appointmentUpdateLoading,
  appointmentUpdateError,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { appointmentId } = useParams<{ appointmentId: string }>();

  const handleConfirmClick = () => {
    if (appointmentId) {
      dispatch(partialUpdateAppointmentData(appointmentId, { confirmed: true }));
    }
  };

  const handleCancelClick = () => {
    history.push('/');
  };

  return (
    <ConfirmAppointmentModal
      appointmentUpdateError={appointmentUpdateError}
      appointmentUpdateLoading={appointmentUpdateLoading}
      onConfirmClick={handleConfirmClick}
      onCancelClick={handleCancelClick}
    />
  );
};

const select = (state) => ({
  appointmentUpdateLoading: state.eyeExams.loading.isAppointmentUpdating,
  appointmentUpdateError: state.eyeExams.errors.appointmentUpdateMessage,
});

export default connect(select)(ConfirmAppointmentModalContent);
