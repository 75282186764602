import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { ArrowLeft24Icon, Close24Icon as CloseIcon, ShopIcon } from '@warbyparker/retail-design-system';
import { Header, HeaderIcon, HeaderIconLink } from '../shared-components/headers';
import StyledRefreshIcon from './styled-refresh-icon';

const Facility = styled('div')`
  color: #414b56;
  font-size: 18px;
  margin: 0;
  text-align: center;
`;

const PartySize = styled('p')`
  color: #676f78;
  font-size: 16px;
  text-align: center;
  margin: 8px 0 0;
`;

const FacilityName = styled('span')`
  font-weight: 600;
`;

const EndBlock = styled('div')`
  display: block;
  height: 26px;
  width: 30px;
`;

export const getPartySizeText = positions => {
  if (!positions || !Array.isArray(positions)) return '';

  const inProgressPositions = positions.filter(position => position.status === 'in_progress');
  const cumulativePartySize = inProgressPositions.reduce(
    (sum, { party_size = 0 }) => (sum + party_size),
    0,
  );

  return `${cumulativePartySize} in progress`;
};

const chooseBackAction = (backUrl: string) => {
  if (!backUrl) {
    return (
      <HeaderIconLink key="poe" href={`https://${process.env.POE_DOMAIN}`}>
        <ArrowLeft24Icon />
      </HeaderIconLink>
    );
  }
  if (backUrl.includes('http')) {
    return (
      <HeaderIconLink key="back" href={backUrl}>
        <ArrowLeft24Icon />
      </HeaderIconLink>
    );
  }
  return (
    <HeaderIcon key="back-close" as={Link} to={backUrl}>
      <CloseIcon />
    </HeaderIcon>
  );
};

const HeaderNav = ({
  facilityName,
  positions,
  backUrl,
  onRefresh,
  loading = false,
  showRefresh = false,
}) => (
  <Header
    actionsLeft={[chooseBackAction(backUrl)]}
    actionsRight={[
      (
        <HeaderIconLink key="shop" href={`https://${process.env.POE_DOMAIN}/visit/estimate`}>
          <ShopIcon />
        </HeaderIconLink>
      ),
      showRefresh
        ? <StyledRefreshIcon key="refresh" loading={loading} onRefresh={onRefresh} />
        : <EndBlock key="blank" />,
    ]}
  >
    <Facility>
      Waitlist at
      {' '}
      <FacilityName>{facilityName}</FacilityName>
      <PartySize>{getPartySizeText(positions)}</PartySize>
    </Facility>
  </Header>
);

export default HeaderNav;
