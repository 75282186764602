import React from 'react';
import { ConfirmedTagProps, TagContainer, TagText } from './styles';

const ConfirmedTag = ({ confirmed }: ConfirmedTagProps) => (
  <TagContainer confirmed={confirmed}>
    <TagText confirmed={confirmed}>{confirmed ? 'Yes' : 'No'}</TagText>
  </TagContainer>
);

export default ConfirmedTag;
