import React from 'react';
import { useAppSelector } from '../../../hooks';
import FrameGallery from '../frame-gallery';
import RecommendationGallery from '../recommendation-gallery';
import { Pagination } from '../pagination';
import { RECOMMENDATIONS_FEATURE_FLAG } from '../../../../feature-flags';

const FrameStyleAssistant = () => {
  const frames = useAppSelector(
    (state) => state.frameStyleAssistant.frames,
  );
  const recommendationsFrames = useAppSelector(
    (state) => state.frameStyleAssistant.filteredRecommendationsFrames,
  );
  const features = useAppSelector(state => state.auth?.me?.features);
  const { name: frameName } = frames?.[0]?.[0] || {};
  return (
    <>
      <FrameGallery frames={frames} />
      {frames?.length === 1 && features?.includes(RECOMMENDATIONS_FEATURE_FLAG) && (
        <RecommendationGallery
          frames={recommendationsFrames}
          frameName={frameName}
        />
      )}
      <Pagination />
    </>
  );
};

export default FrameStyleAssistant;
