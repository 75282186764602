import React from 'react';
import { Container, SortingToggle } from './styles';
import { SortItem } from '../../../middleware/eye-exams/types';
import { SortOrder } from '../../../constants';

const orderLabels = {
  [SortOrder.Asc]: 'Descending',
  [SortOrder.Desc]: 'Ascending',
};

interface AppointmentsSortingProps {
  sortItem: SortItem;
  onSortChange: (sortItem: SortItem) => void;
}

export const AppointmentsSorting = ({ sortItem, onSortChange }: AppointmentsSortingProps) => {
  const toggleOrder = React.useCallback(
    () => {
      if (sortItem) {
        onSortChange(
          {
            ...sortItem,
            order: sortItem.order === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc,
          },
        );
      }
    }, [sortItem, onSortChange],
  );

  return (
    <Container>
      <SortingToggle
        onClick={() => toggleOrder()}
        role="button"
        value={sortItem?.order === SortOrder.Desc}
      >
        Sort by time:
        {' '}
        {orderLabels[sortItem?.order || SortOrder.Asc]}
      </SortingToggle>
    </Container>
  );
};

export default AppointmentsSorting;
