import React from 'react';
import styled from '@emotion/styled';
import { CUSTOMER_QR_CODES } from 'feature-flags';
import { connect } from 'react-redux';
import toCheckFeature from 'src/connect-helpers/toCheckFeature';
import { QRCodeScanner } from '../../components/scanner';
import { REQUEST_QR_FOR_WAITLIST } from '../../middleware/waitlist';
import clear from './clear.svg';

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  background: white;
  border: 1px solid #e4e6e8;
  border-radius: 8px;
  border-bottom-left-radius: ${(props) => (props.hasDropdown ? '0px' : '8px')};
  border-bottom-right-radius: ${(props) => (props.hasDropdown ? '0px' : '8px')};
  border-bottom-width: ${(props) => (props.hasDropdown ? '0px' : '1px')};
  padding: 15px 30px;
`;

const Left = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const InputContainer = styled('div')`
  flex: 1;
`;

const Label = styled('label')`
  font-size: 12px;
  font-weight: 600;
  color: #00a2e1;
`;

const Input = styled('input')`
  border: 0;
  outline: 0;
  font-size: 18px;
  width: 100%;
`;

const Cancel = styled('a')`
  align-items: center;
  color: #0089bf;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  padding-left: 24px;
  margin-left: 24px;
  border-left: 1px solid #e4e6e8;
`;

const Clear = styled('a')`
  display: block;
  background: url(${clear}) no-repeat;
  background-size: 100%;
  width: 12px;
  height: 12px;
  margin-left: 24px;
`;

const QRCodeScannerWrapper = styled('span')({
  fontSize: '1.5em',
  color: '#0089bf',
  display: 'flex',
  paddingLeft: '24px',
  marginLeft: '24px',
  borderLeft: '1px solid #e4e6e8',
});

const Search = ({
  dispatch,
  hasDropdown,
  labelText,
  onChange,
  onClear,
  onExit,
  onKeyDown,
  query,
  enableQRCodeScan,
}) => (
  <Container hasDropdown={hasDropdown}>
    <Left>
      <InputContainer>
        <Label>{labelText}</Label>
        <Input
          type="email"
          autoCorrect="off"
          autoComplete="off"
          value={query || ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
          data-browser-test-customer-email
        />
      </InputContainer>
      <Clear onClick={onClear} />
    </Left>
    <Cancel onClick={onExit}>Cancel</Cancel>
    {enableQRCodeScan ? (
      <QRCodeScannerWrapper>
        <QRCodeScanner
          onClick={() => dispatch({ type: REQUEST_QR_FOR_WAITLIST })}
        />
      </QRCodeScannerWrapper>
    ) : null}
  </Container>
);

const select = (state) => ({
  enableQRCodeScan: window.IS_HERMES && toCheckFeature(state)(CUSTOMER_QR_CODES),
});

export default connect(select)(Search);
