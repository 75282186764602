import styled from '@emotion/styled';
import exclamationIcon from '../icon-exclamation.svg';
import { colors } from '../../styles/colors';

export const ErrorContainer = styled('div')`
  align-items: center;
  background: rgba(214, 0, 60, 0.1);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  margin: 24px 0;
  width: 700px;
`;

export const ErrorIcon = styled('span')`
  background: url(${exclamationIcon}) no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 15px;
  margin: 0 15px 0;
  width: 15px;
`;

export const ErrorText = styled('p')`
  color: ${colors.darkOrange};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 18px 0;
  text-align: center;
`;
