import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Frame } from '../../service-clients/warby-api-types';
import API from '../../service-clients/helios-retail';
import WarbyApi from '../../service-clients/warby-api';

export const FETCH_CUSTOMER_FAVORITES = 'FETCH_CUSTOMER_FAVORITES';
export const FETCH_FAVORITES_IDS_SUCCESS = 'FETCH_FAVORITES_IDS_SUCCESS';
export const FETCH_FAVORITES_IDS_FAILURE = 'FETCH_FAVORITES_IDS_FAILURE';

export const FETCH_FAVORITE_FRAMES = 'FETCH_FAVORITE_FRAMES';
export const FAVORITE_FRAMES_SUCCESS = 'FAVORITE_FRAMES_SUCCESS';
export const FAVORITE_FRAMES_FAILURE = 'FAVORITE_FRAMES_FAILURE';

export const requestCustomerFavorites = (customerId: string) => ({
  type: FETCH_CUSTOMER_FAVORITES,
  payload: customerId,
});

export const fetchFavoritesIdsSuccess = (favoritesIds: number[]) => ({
  type: FETCH_FAVORITES_IDS_SUCCESS,
  payload: favoritesIds,
});

export const fetchFavoritesIdsFailure = (error: Error) => ({
  type: FETCH_FAVORITES_IDS_FAILURE,
  payload: error,
});

export const requestFavoriteFrames = (favoritesIds: number[]) => ({
  type: FETCH_FAVORITE_FRAMES,
  payload: favoritesIds,
});

export const onFavoriteFramesSuccess = (favoritesFrameResults: Frame[]) => ({
  type: FAVORITE_FRAMES_SUCCESS,
  payload: favoritesFrameResults,
});

export const onFavoriteFramesFailure = (error) => ({
  type: FAVORITE_FRAMES_FAILURE,
  payload: error,
});

export const tokenSelector = (state) => state.auth.token;

export interface Favorite {
  created: string;
  customer_id: string;
  experience_gender: string;
  id: string;
  origin: string;
  pc_product_id: number;
}

interface State {
  loading: boolean;
  favoritesIds?: number[];
  favoriteFrames?: Frame[][];
  error?: Error;
}

const initialState : State = {
  loading: false,
};

export function* fetchFavoriteFrames(action: ReturnType<typeof requestFavoriteFrames>) {
  const { payload: favoritesIds } = action;
  const queryParams: string[][] = favoritesIds.map(value => ['id', value]) as string[][];

  try {
    const result = yield call(WarbyApi.fetchFramesByPcProductIds, queryParams);
    // format into Frame[][] results to play nice with <FrameGallery />
    // opportunity here to create a new component if we want to expand on future use cases
    const frames = result.map(frame => [frame]);
    yield put(onFavoriteFramesSuccess(frames));
  } catch (error) {
    yield put(onFavoriteFramesFailure(error));
  }
}

export interface CustomerFavoritesResponse {
  count: number;
  favorites: Favorite[];
  total: number;
}

export function* fetchCustomerFavorites(action: ReturnType<typeof requestCustomerFavorites>) {
  const { payload: customerId } = action;
  const token = yield select(tokenSelector);
  let data: CustomerFavoritesResponse;

  try {
    data = yield call(API.getCustomerFavorites, { token, customerId });
  } catch (err) {
    yield put(fetchFavoritesIdsFailure(err as Error));
    return;
  }

  // we only care about the pc_product_id --> let's get these
  if (data.count > 0) {
    const favoritesIds: number[] = data.favorites.map(
      item => item.pc_product_id,
    );
    yield put(fetchFavoritesIdsSuccess(favoritesIds));
    yield put(requestFavoriteFrames(favoritesIds));
  } else {
    yield put(fetchFavoritesIdsSuccess([]));
  }
}

const reducer = (state = initialState, { type, payload }) : State => {
  switch (type) {
    case FETCH_CUSTOMER_FAVORITES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FAVORITES_IDS_SUCCESS:
      return {
        ...state,
        loading: false,
        favoritesIds: payload,
      };
    case FETCH_FAVORITES_IDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_FAVORITE_FRAMES:
      return {
        ...state,
        loading: true,
      };
    case FAVORITE_FRAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        favoriteFrames: payload,
      };
    case FAVORITE_FRAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

function* saga() {
  yield takeLatest(FETCH_CUSTOMER_FAVORITES, fetchCustomerFavorites);
  yield takeLatest(FETCH_FAVORITE_FRAMES, fetchFavoriteFrames);
}

export default {
  reducer,
  saga,
};
