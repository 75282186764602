import React from 'react';

import { useAppSelector } from 'src/hooks';

interface FeatureFlaggedProps {
  feature: string
}

const FeatureFlagged: React.FC<FeatureFlaggedProps> = ({
  children,
  feature,
}) => {
  const features = useAppSelector(state => state.auth?.me?.features);

  return (
    <>
      {features.includes(feature) && children}
    </>
  );
};

export default FeatureFlagged;
