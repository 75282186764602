import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from '@emotion/styled';

import closeIcon from './icons/close.svg';

const Shield = styled('div')`
  align-items: center;
  background: rgba(64, 74, 85, .8);
  box-sizing: border-box;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10; /* XXX: todo on the gripes wiki pages */
`;

const ContentContainer = styled('div')`
  border-radius: 8px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 11; /* XXX */

  aside {
    display: flex;
    flex-direction: column;
    max-width: 560px;
    text-align: center;
  }

  section {
    background: white;
    flex: 1;
    padding: 60px 65px 20px 65px;

    h1 {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }

    p {
      color: #676F78;
      font-size: 18px;
      line-height: 150%;
    }
  }

  footer {
    flex: 0;

    a {
      background: #00A2E1;
      color: white;
      display: block;
      font-size: 18px;
      font-weight: 600;
      padding: 30px;
      text-decoration: none;
    }
  }
`;

const StyledLink = styled(Link)`
  background-image: url(${closeIcon});
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 15px;
  left: 0;
  overflow: hidden;
  padding: 20px;
  position: absolute;
  text-indent: 200px;
  top: 0;
  width: 15px;
`;

const Close = withRouter(({ match }) => {
  const to = `/visit/${match.params.visitId}`;
  return <StyledLink to={to}>Close</StyledLink>;
});

const Modal = ({ children, hasCloseButton = true }) => (
  <Shield>
    <ContentContainer>
      {children}
      {hasCloseButton ? <Close /> : null }
    </ContentContainer>
  </Shield>
);

/*
  This may go against best practice but:
  - win: we can have individual routes wired to modaled views.
  - con: every time a modal is rendered, the Page HTML is re-rendered.
*/
// eslint-disable-next-line react/jsx-props-no-spreading
export const withModal = (Page, modal) => ((props) => <Page modal={modal} {...props} />);

export default Modal;
