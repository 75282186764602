type TeamMember = { full_name: string | null; id: number; };

/**
 * trim and remove extra spaces in the full name of a team member.
 */
function formatTeamMemberName(teamMember: TeamMember) {
  return {
    ...teamMember,
    full_name: teamMember.full_name
      ? teamMember.full_name.split(/\s/g).filter(Boolean).join(' ')
      : teamMember.full_name,
  };
}

export { formatTeamMemberName };
