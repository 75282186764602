import React from 'react';
import styled from '@emotion/styled';
import { FrameItem } from './frame-item';
import { Frame } from '../../service-clients/warby-api-types';
import { resolutions } from '../../styles/resolutions';
import { colors } from '../../styles/colors';
// @ts-ignore
import eyeGlassesIcon from '../icon-vintage-eyeglasses.svg';
import { RECOMMENDATIONS_LIMIT } from './constants';

const RecommendationGalleryWrapper = styled.fieldset`
  margin: 0 auto;
  max-width: 100vw;
  text-align: center;
  column-gap: 22px;
  @media (max-width: ${resolutions.tabletMax}) {
    column-gap: 16px;
    margin: 0 16px auto; 
  }
  @media (max-width: ${resolutions.tabletSMax}) {
    column-gap: 10px;
    margin: 0 16px auto; 
  }
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid ${colors.silver};
  min-height: 15vh;
`;

const IconContainer = styled.legend`
  margin: 0px auto;
  background: ${colors.silk};
`;

const FrameNameLabel = styled.h2`
  color: ${colors.tundora};
  font-family: utopia-std;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: auto 10px;
  font-size: 26px;
  line-height: 30px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: ${resolutions.tabletMax}) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: ${resolutions.tabletSMax}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const EmptyStateLabel = styled.h2`
  color: ${colors.tundora};
  font-family: utopia-std;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: auto 10px;
  font-size: 24px;
  line-height: 30px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: ${resolutions.tabletMax}) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: ${resolutions.tabletSMax}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const CategoriesIcon = styled.img<{ src: string }>`
  display: inline-block;
  height: 30px;
  width: 60px;

  @media (max-width: ${resolutions.tabletMax}) {
    height: 28px;
    width: 56px;
  }

  @media (max-width: ${resolutions.tabletSMax}) {
    height: 24px;
    width: 48px;
  }
`;

const RecommendationGallery = ({ frames, frameName }) => (
  <RecommendationGalleryWrapper data-testid="recommender-gallery-element">
    <IconContainer>
      <FrameNameLabel>
        <CategoriesIcon src={eyeGlassesIcon} />
        {`Similar to ${frameName}`}
      </FrameNameLabel>
    </IconContainer>
    {frames
      && !!frames.length
      && frames
        .filter(
          (frameFamily: Frame[],
            index:number,
          ) => frameFamily.length > 0 && index < RECOMMENDATIONS_LIMIT,
        )
        .map((frameFamily: Frame[]) => (
          <FrameItem key={frameFamily[0].id} frameFamily={frameFamily} />
        ))}
    {frames && !frames.length && (
      <EmptyStateLabel>
        There are no recommended results for this frame.
      </EmptyStateLabel>
    )}
  </RecommendationGalleryWrapper>
);

export default RecommendationGallery;
