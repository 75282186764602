import React from 'react';

type PossibleRef<T> = React.Ref<T> | undefined;
type ComposedRefs<T> = (node: T) => void;

type UseComposedRefsFn = <T>(...refs: Array<PossibleRef<T>>) => ComposedRefs<T>;

const setRef = <T>(ref: PossibleRef<T>, value: T): void => {
  if (ref === null || ref === undefined) return;

  if (typeof ref === 'function') {
    ref(value);

    return;
  }

  // eslint-disable-next-line no-param-reassign
  (ref as React.MutableRefObject<T>).current = value;
};

export const composeRefs = <T>(...refs: PossibleRef<T>[]): ComposedRefs<T> =>
  (node: T) => refs.forEach((ref) => setRef(ref, node));

export const useComposedRefs: UseComposedRefsFn = (...refs) =>
  React.useCallback(composeRefs(...refs), refs);
