import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

export const RadioButtonGroup = styled.fieldset`
  border: none;
  padding: 0;
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const RadioButtonLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.charcoal};
  display: flex;
  align-items: center;
  line-height: 20px;
  gap: 12px;
`;

export const RadioButton = styled.input`
  width: 24px;
  height: 24px;
  &:checked {
    accent-color: ${colors.allports};
  }
`;
