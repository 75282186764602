import React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from '../../../hooks';
import FrameGallery from '../frame-gallery';
import { resolutions } from '../../../styles/resolutions';

const FrameGalleryWrapper = styled.div`
  margin: 0 30px;
  @media (max-width: ${resolutions.tabletSMax}) {
    margin: 0;
  }
  padding-top: 120px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Favorites = () => {
  const frameFamilies = useAppSelector(state => state.favorites.favoriteFrames);

  return (
    frameFamilies?.length
      ? <FrameGallery frames={frameFamilies} />
      : (
        <FrameGalleryWrapper>
          No Favorites to display.
        </FrameGalleryWrapper>
      )
  );
};

export default Favorites;
