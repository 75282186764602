export const formatDate = (date: Date | number): string =>
  Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
    .format(date) // dd-MM-yyyy
    .split('/')
    .reverse()
    .join('/'); // yyyy-MM-dd

export const findNextEarliestDate = (datesObject) => {
  const filteredDates: string[] = [];

  Object.keys(datesObject).forEach((dateStr) => {
    if (datesObject[dateStr]) {
      filteredDates.push(dateStr);
    }
  });
  const currentDate = new Date();
  const today = currentDate.toISOString().split('T')[0];
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  const formattedTomorrow = tomorrow.toISOString().split('T')[0];

  // since the data from API is comming sorted
  // we can get the first value as the earliest date
  const earliestDate = filteredDates[0];
  if (earliestDate) {
    if (earliestDate === today) {
      return 'Today';
    }
    if (earliestDate === formattedTomorrow) {
      return 'Tomorrow';
    }
    return earliestDate;
  }
  return 'No upcoming appointments';
};

export const formatDateToDayAndMonth = (date: Date) =>
  date.toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });
