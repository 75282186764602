import React from 'react';
import { FilterOption } from '../filter-option';
import { handleSelection } from './selectionUtils';
import { FilterCategoryContainer, FilterCategoryTitle, OptionsContainer } from './styles';
import { RadioButtonGroup, RadioButton, RadioButtonLabel } from '../filter-option/styles';

interface FilterCategoryProps {
  title: string;
  options: string[];
  allowMultiple: boolean;
  allowToggle?: boolean;
  selectedOptions: string[];
  handleSelectedOptions: (title: string, options: string[]) => void;
}

export const FilterCategory: React.FC<FilterCategoryProps> = ({
  title,
  options,
  allowMultiple,
  allowToggle,
  selectedOptions,
  handleSelectedOptions,
}) => {
  const handleOptionClick = (option: string) => {
    const newSelectedOptions = handleSelection(
      selectedOptions,
      option,
      allowMultiple,
      allowToggle,
    );
    handleSelectedOptions(title, newSelectedOptions);
  };

  return (
    <FilterCategoryContainer>
      <FilterCategoryTitle>{title}</FilterCategoryTitle>
      {allowToggle ? (
        <RadioButtonGroup>
          {options.map((option) => (
            <RadioButtonLabel key={`${option}-label`}>
              <RadioButton
                key={option}
                type="radio"
                value={option}
                checked={selectedOptions[0] === option}
                onChange={() => handleOptionClick(option)}
              />
              {option}
            </RadioButtonLabel>
          ))}
        </RadioButtonGroup>
      ) : (
        <OptionsContainer>
          {options.map((option) => (
            <FilterOption
              key={option}
              option={option}
              onClick={handleOptionClick}
              isSelected={selectedOptions.includes(option)}
            />
          ),
          )}
        </OptionsContainer>
      )}
    </FilterCategoryContainer>
  );
};
