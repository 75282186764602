const parseError = async response => {
  const body = await response.json();
  const error = body.error || {};
  const msg500 = 'Internal Server Error.';
  const msg = error.message || msg500;

  return new Error(msg);
};

const parseResponse = async ({ response }: { response: Response }) => {
  switch (response.status) {
    case 201:
    case 200:
      return response.json();
    case 204:
      return {};
    case 400:
    case 502:
      throw new Error((await response.json()).message || 'We’ve done something wrong');
    case 500:
      throw await parseError(response);
    default:
      throw new Error('Something went wrong. 😢');
  }
};

export default parseResponse;
