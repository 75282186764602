import React from 'react';
import styled from '@emotion/styled';

const Container = styled('span')`
  &:nth-of-type(2) {
    animation-delay: 0.2s;
    margin-left: 10px;
  }

  &:nth-of-type(3) {
    animation-delay: 0.4s;
    margin-left: 10px;
  }
`;

const Circle = styled(Container)`
  @keyframes loading {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }

  animation-name: loading;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;
`;

const AnimatedLoader = () => (
  <>
    <Circle />
    <Circle />
    <Circle />
  </>
);

export default AnimatedLoader;
