import React from 'react';
import { Button } from '@warbyparker/retail-design-system';

import { PopoverModal, ModalContainerRef } from 'src/components/popover-modal';
import { useComposedRefs } from 'src/hooks/useComposedRefs';

type RemindIntakeFormModalProps = {
  id: string;
  onRemindIntakeForm: () => void;
};

export const RemindIntakeFormModal = React.forwardRef<
ModalContainerRef,
RemindIntakeFormModalProps
>(({
  id,
  onRemindIntakeForm,
}, ref) => {
  const modalRef = React.useRef<ModalContainerRef>(null);
  const allRefs = useComposedRefs(ref, modalRef);

  const handleCloseModal = React.useCallback(() => {
    if (!modalRef.current) return;

    modalRef.current.closeModal();
  }, []);

  const handleSendSMSText = React.useCallback(() => {
    if (!modalRef.current) return;

    onRemindIntakeForm();

    modalRef.current.closeModal();
  }, []);

  return (
    <PopoverModal.Container id={id} ref={allRefs}>
      <PopoverModal.Content style={{ maxWidth: 500, height: 300, justifyContent: 'space-around' }}>
        <PopoverModal.Title>Pause!</PopoverModal.Title>

        <PopoverModal.Description style={{ maxHeight: '4rem' }}>
          Before sending the intake form, please get verbal consent from the
          customer that
          {' '}
          <strong>
            they agree to receive the intake form link via text message.
          </strong>
          {' '}
          (Some customers may incur fees for SMS data and prefer to opt out.)
        </PopoverModal.Description>

        <PopoverModal.Actions style={{ marginTop: 0 }}>
          <Button onClick={handleCloseModal}>Use another method</Button>

          <Button onClick={handleSendSMSText}>Send via Text</Button>
        </PopoverModal.Actions>
      </PopoverModal.Content>
    </PopoverModal.Container>
  );
});
