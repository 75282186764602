import styled from '@emotion/styled';
import { colors } from '../../styles/colors';

export const Container = styled('div')`
  gap: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 0;
`;

export const EmptyListMessage = styled('p')`
  color: ${colors.grayChateau};
  font-size: 20px;
  max-width: 768px;
  text-align: center;
`;
