import styled from '@emotion/styled';
import { colors } from 'src/styles/colors';

export const TitleContainer = styled('h2')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${colors.charcoal};
`;
