import React from 'react';
import styled from '@emotion/styled';

const Searching = styled('div')`
  background: white;
  border-radius: 8px;
  box-shadow: 0 6px 24px rgba(0, 36, 84, .1);
  box-sizing: border-box;
  color: #414B56;
  display: block;
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 24px;
  position: absolute;
  width: 100%;
`;

export default ({ loading }) => {
  if (!loading) return null;
  return <Searching>Searching...</Searching>;
};
