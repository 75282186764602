/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ActionsContainer } from './styles';

interface ModalActionsProps extends React.HTMLAttributes<HTMLHeadingElement> {}

export const ModalActions: React.FC<ModalActionsProps> = ({
  children,
  ...props
}) => (
  <ActionsContainer {...props}>{children}</ActionsContainer>
);
