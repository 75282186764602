import React, { useEffect } from 'react';

// eslint-disable-next-line import/no-cycle
import { TOAST_TIME_IN_MS, ToastMessage, useToast } from 'src/contexts/toast';

import { Container } from './styles';

interface ToastProps {
  message: ToastMessage
}

export const Toast: React.FC<ToastProps> = ({ message }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, TOAST_TIME_IN_MS);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);

  return (
    <Container
      type={message.type}
      hasDescription={Number(!!message.description)}
      time={TOAST_TIME_IN_MS / 1000 - 1}
      onClick={() => removeToast(message.id)}
    >
      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>
    </Container>
  );
};
