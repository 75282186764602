import styled from '@emotion/styled';
import { Button } from '@warbyparker/retail-design-system';
import { colors } from '../../../styles/colors';
import checkIcon from './icons/check-icon.svg';

export const ConfirmButton = styled(Button)`
  border: none;
  background-color: ${colors.allports};

  &:hover,
  &:focus,
  &:active,
  &:active:hover {
    border: none;
    background-color: ${colors.allportsHover};
  }
`;

export const CancelButton = styled(Button)`
  color: ${colors.charcoal};

  &:hover,
  &:focus,
  &:active,
  &:active:hover {
    color: ${colors.charcoal};
    border: 1px solid ${colors.allports};
  }
`;

export const Container = styled.div`
  gap: 24px;
  width: 584px;
  padding: 36px;
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  background: ${colors.white};
  box-sizing: border-box;
`;

export const Content = styled.div`
  gap: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Buttons = styled.div`
  gap: 12px;
  display: flex;
  align-self: stretch;
`;

export const ErrorContainer = styled.div`
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: ${colors.red};
`;

export const CheckIcon = styled.div`
  width: 58px;
  height: 58px;
  background-image: url(${checkIcon});
  background-position: center;
  background-repeat: no-repeat;
`;

export const TextContainer = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  color: ${colors.charcoal};
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
`;

export const InfoText = styled.span`
  color: ${colors.charcoal};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  align-self: stretch;
`;
