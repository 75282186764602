import styled from '@emotion/styled';
import React from 'react';
import { colors } from '../styles/colors';
import { TIME_SENSITIVE_CATEGORY_KEYS } from '../constants';
import { Category } from '../types/category';

const TagContainer = styled.div`
  height: 18px;
  display: flex;
  padding: 2.5px 8px;
  justify-content: center;
  background-color: ${colors.lightBlue};
  border: 1px solid ${colors.darkBlue};
  align-items: center;
  border-radius: 4px;
  cursor: default;
`;

const TagText = styled.span`
  color: ${colors.darkBlue};
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
`;

export const hasTimeSensitiveCategory = ({ optician }: Category) =>
  !!optician && Object.entries(optician).some(
    ([categoryKey, value]) => (TIME_SENSITIVE_CATEGORY_KEYS).includes(categoryKey) && value,
  );

interface TagProps {
  text: string;
}

const Tag = ({ text }: TagProps) => (
  <TagContainer>
    <TagText>
      {text}
    </TagText>
  </TagContainer>
);

export default Tag;
