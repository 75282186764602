import React from 'react';
import { CaretUp12Icon, CaretDown12Icon } from '@warbyparker/retail-design-system';
import { AvailableExamsTogglerButton, AvailableExamsTogglerLabel, AvailableExamsTogglerContainer } from './styles';

type AvailableExamsSectionProps = {
  showNextAvailableEyeExams: boolean;
  onShowEyeExams: () => void;
};

const AvailableExamsToggler: React.FC<AvailableExamsSectionProps> = ({
  showNextAvailableEyeExams,
  onShowEyeExams,
}) => (
  <AvailableExamsTogglerContainer>
    <AvailableExamsTogglerLabel>
      Available Exams
    </AvailableExamsTogglerLabel>
    <AvailableExamsTogglerButton onClick={onShowEyeExams}>
      {showNextAvailableEyeExams ? <CaretUp12Icon /> : <CaretDown12Icon />}
    </AvailableExamsTogglerButton>
  </AvailableExamsTogglerContainer>
);

export default AvailableExamsToggler;
