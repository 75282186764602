import React from 'react';
import Modal from '../../../modal';
import {
  Buttons, CancelButton,
  CheckIcon,
  ConfirmButton,
  Container,
  Content,
  ErrorContainer,
  InfoText,
  TextContainer,
  Title,
} from './styles';

interface ConfirmAppointmentModalProps {
  appointmentUpdateLoading: boolean;
  appointmentUpdateError: string;
  onConfirmClick: () => void;
  onCancelClick: () => void;
}

const ConfirmAppointmentModal = ({
  appointmentUpdateLoading, appointmentUpdateError, onConfirmClick, onCancelClick,
}: ConfirmAppointmentModalProps) => (
  <Modal hasCloseButton={false}>
    <Container>
      <Content>
        <CheckIcon />
        <TextContainer>
          <Title>Confirm Appointment?</Title>
          <InfoText>
            {`Are you sure you want to confirm this appointment? 
            This action will send an email to the patient confirming 
            the time, date, and location of this appointment.`}
          </InfoText>
          {appointmentUpdateError && <ErrorContainer>{appointmentUpdateError}</ErrorContainer>}
        </TextContainer>
      </Content>
      <Buttons>
        <CancelButton color="secondary" onClick={() => onCancelClick()} fullWidth>
          No
        </CancelButton>
        <ConfirmButton color="primary" onClick={onConfirmClick} loading={appointmentUpdateLoading} fullWidth>
          Yes
        </ConfirmButton>
      </Buttons>
    </Container>
  </Modal>
);

export default ConfirmAppointmentModal;
