import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import queryString from 'query-string';
import styled from '@emotion/styled';
import { ArrowLeft16Icon, ArrowLeft24Icon, ArrowRight16Icon } from '@warbyparker/retail-design-system';
import { colors } from '../../styles/colors';
import { resolutions } from '../../styles/resolutions';
// @ts-ignore
import exclamationIcon from '../icon-exclamation.svg';
import { defaultFrameImg, facetGroups } from './constants';
import { Frame, WidthVariant } from '../../service-clients/warby-api-types';

import { Header, Heading, HeaderIcon } from '../../shared-components/headers';

import { requestFrame } from '../../middleware/frame-config';
import { useAppSelector } from '../../hooks';

const Page = styled.div`
  background: ${colors.white};
  height: 100%;
  left: 0;
  min-height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
`;

const Content = styled.div`
  scroll-behavior: smooth;
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 104px);
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  text-align: center;
`;

const Subtext = styled.h2`
  font-family: utopia-std;
  font-size: 24px;
`;

const ButtonListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 840px;

  @media (max-width: ${resolutions.tabletSMax}) {
    width: 660px;
  }
`;

const ColorCircle = styled.img<{ src: string }>`
  outline: none;
  border-radius: 50%;

  width: 30px;
  height: 30px;
  margin-right: 18px;
  box-shadow: 0 0 0 3px ${colors.white}, 0 0 0 5px ${colors.grayChateau};

  @media (max-width: ${resolutions.tabletMax}) {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    box-shadow: 0 0 0 3px ${colors.white}, 0 0 0 4px ${colors.grayChateau};
  }

  @media (max-width: ${resolutions.tabletSMax}) {
    width: 18px;
    height: 18px;
    margin-right: 16px;
    box-shadow: 0 0 0 3px ${colors.white}, 0 0 0 4px ${colors.grayChateau};
  }
`;

const OptionButtonContainer = styled.div<{ center: boolean }>`
  width: ${props => (props.center ? '100%' : '50%')};
  color: ${colors.charcoal};
`;

const OptionButton = styled('button')<{ selected: boolean, padLeft: boolean, center: boolean }>`
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: auto;
  max-width: 95%;
  font-weight: 600;
  margin: ${props => (props.center ? '9px auto' : '12px 0 12px 36px')};
  padding: ${props => (props.padLeft ? '12px 32px' : '12px 32px 12px 13px')};

  color: ${props => (props.selected ? colors.silk : colors.charcoal)};
  background-color: ${props => (props.selected ? colors.allports : colors.silk)};
  border-color: transparent;
  border-width: 1px;
  @media (max-width: ${resolutions.tabletMax}) {
    margin: ${props => (props.center ? '9px auto' : '12px 0 12px 36px;')};
  }

  &:hover {
    background: ${colors.blueHover};
    cursor: pointer;
  }
  &:focus, &:active {
    background: ${colors.darkBlue};
  }
  &:disabled {
    border: 1px dashed ${colors.alto};
    background: none;
    color: ${colors.smoke};
    cursor: not-allowed;
  }
`;

const NavButton = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  font-size: 18px;
  background: none;
  font-weight: 600;
  cursor: pointer;
  padding: 12px;
  color: rgb(103, 111, 120);
  color: ${props => (props.disabled ? colors.grayChateau : colors.stone)};
`;

const ButtonText = styled.div`
  font-size: 18px;
  line-height: 18px;
  align-self: center;
  margin: 0 18px;
  
  @media (max-width: ${resolutions.tabletMax}) {
    font-size: 16px;
    line-height: 16px;
    margin: 0 16px;
  }
`;

const ArrowContainer = styled.div`
  width: unset;
  color: ${colors.stone};
  background: ${colors.white};
  border: none;
  cursor: pointer;
  border-radius: 100px;
  font-size: 16px;
  padding: 19px 19px 16px 19px;
  margin: 0;
  box-shadow: 0px 1px 4px rgba(65, 75, 86, 0.12), 0px 8px 12px rgba(65, 75, 86, 0.12);
  
  @media (max-width: ${resolutions.tabletMax}) {
    font-size: 16px;
    padding: 16px;
  }
`;

const OptionText = styled.div<{ withImage: boolean }>`
  max-width: ${props => (props.withImage ? '90%' : '100%')};
  line-height: 22px;
  height: 22px;
  display: flex;
  font-size: 18px;
  
  @media (max-width: ${resolutions.tabletMax}) {
    font-size: 16px;
  }
`;

const ColorName = styled.div`
  position: relative;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColorTitleDash = styled.div`
  padding: 0px 4px;
`;

const Image = styled.img<{ isDefault: boolean, visible: boolean }>`
  display: ${props => (props.visible ? 'inline-block' : 'none')};
  height: 340px;
  padding-top: 90px;
  padding-bottom: 30px;

  @media (max-width: ${resolutions.tabletMax}) {
    height: 340px;
    padding-bottom: 30px;
  }

  @media (max-width: ${resolutions.tabletSMax}) {
    height: ${props => (props.isDefault ? '270px' : '300px')};
    padding-top: ${props => (props.isDefault ? '105px' : '90px')};  
    padding-bottom: ${props => (props.isDefault ? '25px' : '10px')}; 
  }
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 84px;
  background: white;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 24px;
  box-sizing: border-box;
`;

const AssistantHeading = styled(Heading)`
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 30px;
`;

interface ImageLoadingStatus {
  [frameColorId: string]: boolean;
}

interface Params {
  frameId: string;
}

const getFrameKindLabel = (frameKind?: string): string => {
  let frameKindLabel;
  switch (frameKind) {
    case 'sunGlasses':
      frameKindLabel = '- Sun';
      break;
    case 'eyeGlasses':
      frameKindLabel = '- Optical';
      break;
    default:
      frameKindLabel = '';
  }
  return frameKindLabel;
};

export const FrameConfigPage = () => {
  const dispatch = useDispatch();
  const { frameId: frameFamilyId } = useParams<Params>();
  const frameFamily = useAppSelector(state => state.frameConfig.frameConfigFamily);
  const [selectedColor, setSelectedColor] = useState<Frame | null>(null);
  const [selectedWidthVariant, setSelectedWidthVariant] = useState<WidthVariant | null>(null);
  const [showWidths, setShowWidths] = useState(false);
  const [imageLoadingStatus, setImageLoadingStatus] = useState<ImageLoadingStatus>({});

  const firstFrameItem = frameFamily?.[0];
  const name = firstFrameItem?.name || 'frame';
  const frameKind = firstFrameItem?.kind;

  useEffect(() => {
    dispatch(requestFrame(frameFamilyId));
  }, [frameFamilyId]);

  useEffect(() => {
    setSelectedColor(firstFrameItem);

    // initialize default isLoaded flag for each color of frame
    const defaultLoadingStatus: ImageLoadingStatus = frameFamily.reduce(
      (previousLoadingState, frame) => ({
        ...previousLoadingState,
        [frame.id]: false,
      }), {},
    );
    setImageLoadingStatus(defaultLoadingStatus);
  }, [frameFamily]);

  const onSelectColor = color => {
    setSelectedColor(color);
    setSelectedWidthVariant(null);
  };

  const redirectToPOE = () => {
    const parsedQuery = queryString.parse(window.location.search);
    const visitId = parsedQuery.visit;
    const estimateId = parsedQuery.estimate;

    const backUrl = `${window.location.origin}${window.location.pathname}${window.location.search}`;

    const poeQueryParams = new URLSearchParams({
      backUrl,
      pid: selectedWidthVariant?.id ?? '',
      colorVariantId: selectedColor?.id ?? '',
      width: selectedWidthVariant?.width ?? '',
    });
    if (visitId && estimateId && selectedWidthVariant) {
      window.location.href = `https://${process.env.POE_DOMAIN}/new-visit/${visitId}/estimate/${estimateId}/product-config?${
        poeQueryParams.toString()}`;
    } else if (selectedWidthVariant) {
      window.location.href = `https://${process.env.POE_DOMAIN}/new-visit/estimate/product-config?${
        poeQueryParams.toString()}`;
    }
  };

  const colorButtons = frameFamily.map(color => (
    <OptionButtonContainer key={color.id} center={false}>
      <OptionButton
        onClick={() => onSelectColor(color)}
        selected={(selectedColor === color)}
        padLeft={false}
        center={false}
      >
        <ColorCircle
          key={color.id}
          src={color.images.swatch || exclamationIcon}
        />
        <OptionText withImage>
          <ColorName>{color.color}</ColorName>
          <ColorTitleDash>-</ColorTitleDash>
          <div>{color.colorCode}</div>
        </OptionText>
      </OptionButton>
    </OptionButtonContainer>
  ));

  const widthButtons = selectedColor && facetGroups.availableWidths.facets.map(possibleWidth => {
    const widthVariant = selectedColor.widthVariants && selectedColor.widthVariants.find(
      variant => possibleWidth.name === variant.width,
    );

    return (
      <OptionButtonContainer key={possibleWidth.name} center>
        <OptionButton
          disabled={!widthVariant}
          onClick={() => setSelectedWidthVariant(widthVariant || null)}
          selected={(selectedWidthVariant === widthVariant)}
          center
          padLeft
        >
          <OptionText withImage={false}>
            {possibleWidth.displayName}
          </OptionText>
        </OptionButton>
      </OptionButtonContainer>
    );
  });

  const selectColorButton = (
    <NavButton
      onClick={() => setShowWidths(false)}
      disabled={!selectedColor}
      type="button"
    >
      <ArrowContainer>
        <ArrowLeft16Icon />
      </ArrowContainer>
      <ButtonText>
        Select color
      </ButtonText>
    </NavButton>
  );

  const selectWidthButton = (
    <NavButton
      onClick={() => setShowWidths(true)}
      disabled={!selectedColor}
      type="button"
    >
      <ButtonText>
        Select width
      </ButtonText>
      <ArrowContainer>
        <ArrowRight16Icon />
      </ArrowContainer>
    </NavButton>
  );

  const selectLensesButton = (
    <NavButton
      color="quiet"
      disabled={!selectedWidthVariant}
      onClick={redirectToPOE}
      type="button"
    >
      <ButtonText>
        Select lenses
      </ButtonText>
      <ArrowContainer>
        <ArrowRight16Icon />
      </ArrowContainer>
    </NavButton>
  );

  return (
    <Page>
      <Header
        actionsLeft={[
          <HeaderIcon key="back" as={Link} to={`/frame-style-assistant${window.location.search}`}><ArrowLeft24Icon /></HeaderIcon>,
        ]}
      >
        <AssistantHeading>{`Configure ${name} ${getFrameKindLabel(frameKind)}`}</AssistantHeading>
      </Header>
      <Content>
        {/* use 'quality' and 'width' query string parameters to fetch lighter images */}
        {/* 700px is the closet round value for the biggest image height(340px) for wide screen */}
        {/* show the default image if image for selected frame isn't loaded */}
        <Image
          key="default-img"
          visible={!selectedColor || (selectedColor && !imageLoadingStatus[selectedColor.id])}
          src={`${defaultFrameImg}?quality=80&width=700`}
          isDefault
        />
        {frameFamily.map((color) => (
          <Image
            key={`${color.color}-img`}
            visible={selectedColor === color && imageLoadingStatus[color.id]}
            src={`${color.images.angle}?quality=80&width=700`}
            isDefault={false}
            onLoad={() => setImageLoadingStatus({ ...imageLoadingStatus, [color.id]: true })}
          />
        ))}
        <Subtext>{showWidths ? 'Select a width' : 'Select a color'}</Subtext>
        <ButtonListContainer>
          {!showWidths && colorButtons}
          {showWidths && widthButtons}
        </ButtonListContainer>
      </Content>
      <Footer>
        {showWidths && selectLensesButton}
        {!showWidths && selectWidthButton}
        {showWidths && selectColorButton}
      </Footer>
    </Page>
  );
};
