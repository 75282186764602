import React from 'react';
import { OptionContainer } from './styles';

interface FilterOptionProps {
  option: string;
  isSelected: boolean;
  onClick: (option: string) => void;
}

export const FilterOption: React.FC<FilterOptionProps> = ({ isSelected, onClick, option },
) => (
  <OptionContainer
    isSelected={isSelected}
    onClick={() => onClick(option)}
  >
    {option}
  </OptionContainer>
);
