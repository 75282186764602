import React, { Children, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReviewIcon, ShopIcon, CheckmarkIcon } from '@warbyparker/retail-design-system';
import { useHistory } from 'react-router-dom';
import * as Styled from './styles';

import { APPOINTMENT, CMS_PAGES } from '../../../constants';
import { requestAppointmentModificationAction } from '../../../middleware/eye-exams/actions';
import {
  CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG,
  MODIFY_EYE_EXAM_BOOKING_FEATURE_FLAG,
} from '../../../../feature-flags';
import { FOOTER_OFFSET, OVERFLOW_BUTTON_HEIGHT_PX } from './constants';
import { calculateMenuTopPosition } from './utils';
import OverflowPage, { OverflowPageRef } from '../../overflow-page';
import { useAppSelector } from '../../../hooks';

const AppointmentOverflowMenu = ({ disabled = false, handleNoShow, appointment }) => {
  const history = useHistory();

  const features = useAppSelector(state => state.auth?.me?.features);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [spaceBelow, setSpaceBelow] = useState<number>(0);
  const overflowPageRef = useRef<OverflowPageRef | null>(null);

  const isIOSApp = !!window.IS_HERMES;
  const baseUri = `${process.env.WARBY_BOOKING_URL}/${CMS_PAGES.MODIFY_APPOINTMENT_PAGE}`;
  const url = new URL(baseUri);
  url.searchParams.set('appointmentId', appointment && appointment.id);

  const dispatch = useDispatch();

  const openMenu = (e) => {
    const footerElement = document.getElementById('footer');
    const footerHeight = footerElement ? footerElement.offsetHeight : 0;
    setSpaceBelow(window.innerHeight
        - e.target.getBoundingClientRect().bottom
        - footerHeight
        - FOOTER_OFFSET
        + (OVERFLOW_BUTTON_HEIGHT_PX / 2));

    setShowMenu(true);
    e.stopPropagation();
  };

  const closeMenu = (e) => {
    setShowMenu(false);
    e.stopPropagation();
  };

  const onConfirmAppointment = () => {
    if (appointment) {
      history.push(`/confirm-appointment/${appointment.id}`);
    }
  };

  const onModifyAppointment = () => {
    if (isIOSApp) {
      dispatch(requestAppointmentModificationAction({ modifyAppointmentUrl: url.toString() }));
      return;
    }

    if (!overflowPageRef.current) return;
    overflowPageRef.current.openPage();
  };

  const onNoShow = (e) => {
    handleNoShow(appointment);
    closeMenu(e);
  };

  // We need to get amount of children, so we can't use here <FeatureFlagged>,
  // because we will get incorrect amount
  const menuChildren = useMemo(() => [
    appointment?.status === APPOINTMENT.STATUS.BOOKED
    && features?.includes(CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG) && (
      <Styled.MenuItem
        disabled={!appointment || !appointment.id}
        onClick={onConfirmAppointment}
        key="confirm"
      >
        <CheckmarkIcon />
        <Styled.MenuItemText>
          Confirm Appointment
        </Styled.MenuItemText>
      </Styled.MenuItem>
    ),
    features?.includes(MODIFY_EYE_EXAM_BOOKING_FEATURE_FLAG) && (
      <Styled.MenuItem
        disabled={!appointment || !appointment.id}
        onClick={onModifyAppointment}
        key="modify"
      >
        <ReviewIcon />
        <Styled.MenuItemText>
          Modify Appointment
        </Styled.MenuItemText>
      </Styled.MenuItem>
    ),
    <Styled.MenuItem key="no-show" onClick={onNoShow}>
      <ShopIcon />
      <Styled.MenuItemText>
        No show
      </Styled.MenuItemText>
    </Styled.MenuItem>,
  ].filter(Boolean), [appointment, onModifyAppointment, onNoShow]);

  const menuChildrenCount = Children.count(menuChildren);
  const menuTopPx = calculateMenuTopPosition(menuChildrenCount, spaceBelow);

  return (
    <Styled.OverflowContainer>
      <Styled.OverflowButton
        data-testid="overflow-button"
        disabled={disabled}
        onClick={openMenu}
      >
        &middot;&middot;&middot;
      </Styled.OverflowButton>
      {
        showMenu && (
          <>
            <Styled.Overlay onClick={closeMenu} />
            <Styled.Menu menuTopPx={menuTopPx}>
              {menuChildren}
            </Styled.Menu>
          </>
        )
      }
      <OverflowPage
        title="Modify Appointment"
        src={url.toString()}
        ref={overflowPageRef}
      />
    </Styled.OverflowContainer>
  );
};

export default AppointmentOverflowMenu;
