import React from 'react';
import { FiltersContainer } from '../styles';
import { FilterCategory } from '../filter-category';
import { FiltersSection } from './styles';

const TimeFilterOptions = ['Upcoming', 'Past'];
const ConfirmationStatusFilterOptions = ['Yes', 'No'];
const TypeFilterOptions = ['Adult', 'Kids', 'Glasses Only', 'In Person', 'Video Assited'];

export const TimeFilterDefaultOption = 'Upcoming';

interface FilterModalProps {
  selectedOptions: { [s: string]: string[] };
  filtersSectionRef: React.RefObject<HTMLDivElement>;
  handleSelectedOptions: (title: string, options: string[]) => void;
}

const FilterModal = ({
  selectedOptions,
  filtersSectionRef,
  handleSelectedOptions,
}: FilterModalProps) => (
  <FiltersSection ref={filtersSectionRef}>
    <FiltersContainer>
      <FilterCategory
        title="Time"
        options={TimeFilterOptions}
        // eslint-disable-next-line @typescript-eslint/dot-notation
        selectedOptions={selectedOptions['Time'] || [TimeFilterDefaultOption]}
        handleSelectedOptions={handleSelectedOptions}
        allowToggle
        allowMultiple={false}
      />
      <FilterCategory
        title="Confirmation Status"
        options={ConfirmationStatusFilterOptions}
        selectedOptions={selectedOptions['Confirmation Status'] || []}
        handleSelectedOptions={handleSelectedOptions}
        allowMultiple={false}
      />
      <FilterCategory
        title="Type"
        options={TypeFilterOptions}
        allowMultiple
        // eslint-disable-next-line @typescript-eslint/dot-notation
        selectedOptions={selectedOptions['Type'] || []}
        handleSelectedOptions={handleSelectedOptions}
      />
    </FiltersContainer>
  </FiltersSection>
);

export default FilterModal;
