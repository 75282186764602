import React from 'react';
import styled from '@emotion/styled';

import checkIcon from './icon-check.svg';
import { CustomerItemButton } from '../shared-components/buttons';

const Button = styled(CustomerItemButton)`
  ${props => (props.smsSent ? 'xbackground: #e1e5e6;' : '')}
  ${props => (props.smsSent ? 'color: #a1a4a6;' : '')}
`;

const CheckIconContainer = styled('span')`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const CheckIcon = styled('span')`
  background: url(${checkIcon}) no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 13px;
  margin-right: 6px;
  width: 13px;
`;

const handleSMSClick = ({ handleClick, id, smsSent }) => {
  if (smsSent) return;
  handleClick(id);
};

const SendSMSButton = ({ handleClick, id, smsSent, waitTimeFormatter }) => (
  <Button
    smsSent={smsSent}
    onClick={() => handleSMSClick({ handleClick, id, smsSent })}
    disabled={smsSent}
  >
    {(smsSent)
      ? (
        <CheckIconContainer>
          <CheckIcon />
          Sent
          {' '}
          {waitTimeFormatter(smsSent)}
          {' '}
          ago
        </CheckIconContainer>
      )
      : 'Send text'}
  </Button>
);

export default SendSMSButton;
