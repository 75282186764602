// Names of feature flags.

export const EYE_EXAM_TOOLING = 'shop_app_add_eye_exam_tooling_improvement';
export const RECOMMENDATIONS_FEATURE_FLAG = 'frame_style_assistant_recommender';
export const CUSTOMER_QR_CODES = 'shop_app_customer_qr_codes';
export const EYE_EXAM_TOOLING_INTAKE_FORM = 'shop_app_eye_exam_tooling_intake_form_feature';
export const AUTO_REFRESH_FEATURE_FLAG = 'waitlist_auto_refresh';
export const MODIFY_EYE_EXAM_BOOKING_FEATURE_FLAG = 'shop_app_modify_exam_booking_actions';
export const CONFIRM_EYE_EXAM_BOOKING_FEATURE_FLAG = 'shop_app_confirm_exam_booking';
export const NATIVE_EYE_EXAM_BOOKING_FEATURE_FLAG = 'shop_app_native_eye_exam_booking';
