import styled from '@emotion/styled';
import { Button } from '@warbyparker/retail-design-system';
import { MENU_ITEM_HEIGHT_PX, OVERFLOW_BUTTON_HEIGHT_PX } from './constants';
import { colors } from '../../../styles/colors';

export const OverflowContainer = styled('div')`
  width: 72px;
  height: ${OVERFLOW_BUTTON_HEIGHT_PX}px;
  margin: 0 0 0 6px;
`;

export const Overlay = styled('div')`
  width: 100%;
  height: 100%;
  background: rgba(64, 74, 85, .2);
  position: absolute;
  top: 0px;
  left: 0px;
  touch-action: none;
  z-index: 2;
`;

// Why is 'top' -257px or -25px?
//
// If the menu opens below,
// move it up 1/2 the height of the overflow icon.
//
// If the menu opens above, move it up:
// 1/2 the height of the overflow icon (24px)
// The height of the spacer (8px)
// The height of all menu items (45px x number of menu items)
// - (24px + 8px + 45px x 5) = -257px
type MenuProps = {
  menuTopPx: number;
};
type MenuItemsProps = {
  danger?: boolean;
};
export const Menu = styled('div')<MenuProps>`
  width: 250px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(30px);
  border-radius: 12px;
  position: relative;
  right: 225px;
  z-index: 3;
  top: ${props => `${props.menuTopPx}px`};
`;

export const MenuItem = styled('button')<MenuItemsProps>`
  color: ${props => (props.danger ? colors.red : colors.tundora)};
  background: none;
  font-size: 16px;
  font-weight: 600;
  line-height: ${MENU_ITEM_HEIGHT_PX}px;
  margin: 0;
  padding: 0 0 0 22px;
  border: 0;
  border-bottom: 1px solid ${colors.silver};
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  touch-action: none;

  &:disabled {
    background: ${colors.silk};
    color: ${colors.smoke};
  }

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const MenuItemText = styled('span')`
  margin-left: 10px;
`;

export const OverflowButton = styled(Button)`
  width: 62px;
  height: ${OVERFLOW_BUTTON_HEIGHT_PX}px;
  padding: 0;
  font-size: 26px;
`;
